import { useQuery } from "@apollo/client";
import { RootState } from "app/store";
import Button from "common/components/Button";
import { LMS_TYPE } from "common/components/constant";
import { GET_ORGANIZATION_CONFIG } from "components/dashboard/queries.graphql";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Spinner from "../Spinner";
import {
    AcademicCapIcon,
    ComputerDesktopIcon,
    GlobeAltIcon,
    BookOpenIcon
} from '@heroicons/react/24/outline';

interface OrganizationConfigurationProps {
    closeModal: () => void;
}

const LMS_ICONS = {
    [LMS_TYPE.BRIGHTSPACE]: GlobeAltIcon,
    [LMS_TYPE.CANVAS]: ComputerDesktopIcon,
    [LMS_TYPE.BLACKBOARD]: BookOpenIcon,
    [LMS_TYPE.MOODLE]: AcademicCapIcon,
};

export const OrganizationConfiguration: React.FC<OrganizationConfigurationProps> = ({ closeModal }) => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    const [openLMSConfiguration, setOpenLMSConfiguration] = useState(false);
    const [lmsType, setLmsType] = useState<string | null>(null);
    const [selectedLmsType, setSelectedLmsType] = useState<string | null>(null);

    const { data, error, loading } = useQuery(GET_ORGANIZATION_CONFIG, {
        variables: { organizationId: currentUser.organization_id }
    });

    useEffect(() => {
        if (data?.lms_organization.length) {
            setLmsType(data.lms_organization[0]?.lms_config?.type);
        }
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <p>Error: {error.message}</p>;

    const handleLmsIconClick = (type: string) => {
        setLmsType(type);
        setSelectedLmsType(type);
        setOpenLMSConfiguration(true);
    };

    return (
        <>
            {openLMSConfiguration && lmsType && <Navigate to={`configure/${lmsType.toLowerCase()}`} />}
            <div data-testId="organization-config" className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-4 rounded shadow-lg w-1/2">
                    <div className="flex justify-end">
                        <button data-testId="close-button" onClick={closeModal}>&times;</button>
                    </div>
                    <h3 className="text-lg font-bold mb-4">LMS Configuration</h3>
                    <p className="mb-4 font-bold text-sm">Select an LMS type to configure</p>

                    {lmsType ? (
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-md font-semibold">LMS Type: {lmsType}</span>
                            <Button
                                label="Configure"
                                className="bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center"
                                onClick={() => setOpenLMSConfiguration(true)}
                            />
                        </div>
                    ) : (
                        <div className="flex justify-around mb-4">
                            {Object.keys(LMS_ICONS).map((key) => {
                                const Icon = LMS_ICONS[key as keyof typeof LMS_ICONS];
                                const isSelected = key === selectedLmsType;

                                return (
                                    <div
                                        key={key}
                                        className={`flex flex-col items-center cursor-pointer p-2 transition-transform transform ${isSelected ? 'scale-110' : 'scale-100'} hover:scale-110 hover:text-emerald-900`}
                                        onClick={() => handleLmsIconClick(key)}
                                    >
                                        <Icon className="h-10 w-10" />
                                        <span>{key}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
