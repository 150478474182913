import {LMS_ATTENDEE_FILENAME, LMS_TYPE} from "../../../common/components/constant";
import {Attendee, Quiz} from "./types";

export async function attendeeCsvGenerator(quiz : Partial<Quiz>, attendees : Partial<Attendee>[]) : Promise<FormData>{
  const formData = new FormData();
  formData.append('relativePath', null);
  formData.append('name', 'attendees.csv');
  formData.append('type', 'text/csv');
  let csvData = 'user_first_name,user_last_name,user_email,user_external_id,external_id\n';
  switch (quiz?.quiz_organization?.lms_config?.type){
    case LMS_TYPE.MOODLE :{
      attendees.forEach((attendee) => {
        csvData += `${attendee.quiz_attendee_attendee.first_name},${attendee.quiz_attendee_attendee.last_name},${attendee.quiz_attendee_attendee.email},${attendee.quiz_attendee_attendee.external_id},${quiz.external_id}-${attendee.quiz_attendee_attendee.external_id}\n`;
      });
      break;}
    case LMS_TYPE.CANVAS:
    {
      if(quiz.quiz_organization.lms_config?.canvas?.is_browser_integration){
        attendees.forEach((attendee) => {
          csvData += `${attendee.quiz_attendee_attendee.first_name},${attendee.quiz_attendee_attendee.last_name},${attendee.quiz_attendee_attendee.email},${attendee.quiz_attendee_attendee.external_id},${quiz.course_id}-${quiz.external_id}-${attendee.quiz_attendee_attendee.external_id}\n`;
        });
      } else {
        attendees.forEach((attendee) => {
          csvData += `${attendee.quiz_attendee_attendee.first_name},${attendee.quiz_attendee_attendee.last_name},${attendee.quiz_attendee_attendee.email},${attendee.quiz_attendee_attendee.external_id},${quiz.course_id}-${attendee.quiz_attendee_attendee.external_id}-${quiz.external_id}-${quiz.course_module_item_id}\n`;
        });
      }
      break;
    }
    case LMS_TYPE.BLACKBOARD:
    {
      attendees.forEach((attendee) => {
        csvData += `${attendee.quiz_attendee_attendee.first_name},${attendee.quiz_attendee_attendee.last_name},${attendee.quiz_attendee_attendee.email},${attendee.quiz_attendee_attendee.email},${quiz.external_id}-${attendee.quiz_attendee_attendee.email}\n`;
      });
      break;
    }
    case LMS_TYPE.BRIGHTSPACE:
    {
      attendees.forEach((attendee) => {
        csvData += `${attendee.quiz_attendee_attendee.first_name},${attendee.quiz_attendee_attendee.last_name},${attendee.quiz_attendee_attendee.email},${attendee.quiz_attendee_attendee.external_id},${quiz.course_id}-${quiz.external_id}-${attendee.quiz_attendee_attendee.external_id}\n`;
      });
      break;
    }
    case LMS_TYPE.CUSTOM_AE:
    {
      attendees.forEach((attendee) => {
        csvData += `${attendee.quiz_attendee_attendee.first_name || ''},${attendee.quiz_attendee_attendee.last_name || ''},${attendee.quiz_attendee_attendee.email},${attendee.quiz_attendee_attendee.external_id},${quiz.external_id}-${attendee.quiz_attendee_attendee.external_id}\n`;
      });
      break;
    }
    default: return Promise.reject({ message: 'LMS type not found!' });
  }
  const blob = new Blob([csvData], {type: 'text/csv'});
  formData.append('file', blob, LMS_ATTENDEE_FILENAME);
  return formData;
}
