import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setPlaybackVideoSpeed } from 'common/slice/playbackVideoSlice';

const PlaybackSpeedSetting = ({ availableSpeeds }) => {
  const dispatch = useAppDispatch();
  const { playbackSpeed } = useAppSelector((state) => state.playbackVideo);
  const [isActive, setIsActive] = useState(false);

  const handlePlaybackSpeedChange = (speed: number) => {
    dispatch(setPlaybackVideoSpeed(speed));
    setIsActive(false);
  };

  return (
    <div className="w-10 flex justify-center relative">
      <button
        onClick={() => setIsActive(!isActive)}
        className="h-full px-2 py-1 bg-gray-600 rounded-md"
      >
        {playbackSpeed}x
      </button>
      {isActive && (
        <div className="absolute bottom-[120%] left-1/2 -translate-x-1/2 bg-gray-700 text-white rounded-md z-50 overflow-hidden">
          {availableSpeeds.map(
            (speed) =>
              speed !== playbackSpeed && (
                <button
                  key={speed}
                  className="block w-full px-4 py-2 text-left hover:bg-gray-600"
                  onClick={() => handlePlaybackSpeedChange(speed)}
                >
                  {speed}x
                </button>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default PlaybackSpeedSetting;
