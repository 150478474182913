import { useAppDispatch } from 'app/hooks';
import { updateAudioSetting } from 'common/slice/audioSettingSlice';
import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  currentAudioStatus: boolean;
  attendeeId: number;
}

const AudioSetting = ({ currentAudioStatus, attendeeId }: Props) => {
  const dispatch = useAppDispatch();

  const handleAudioSettingChange = (
    isMuted: boolean,
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    const payload = {
      attendeeId,
      isMuted,
    };
    dispatch(updateAudioSetting(payload));
  };

  return (
    <div className="flex flex-row absolute justify-center items-center h-6 w-6 rounded-2xl bg-neutral-500  text-cyan-50 bottom-2 right-2 cursor-pointer">
      {currentAudioStatus ? (
        <SpeakerXMarkIcon
          className="h-4 w-4"
          onClick={(event) => handleAudioSettingChange(!currentAudioStatus, event)}
          data-testid="speaker-x-mark-icon"
        />
      ) : (
        <SpeakerWaveIcon
          className="h-4 w-4"
          onClick={(event) => handleAudioSettingChange(!currentAudioStatus, event)}
          data-testid="speaker-wave-icon"
        />
      )}
    </div>
  );
};

export default AudioSetting;
