import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function convertUTCDateToLocalDate(date) {
  const utcDate = dayjs.utc(date);
  const localDate = dayjs(utcDate).local();
  const formattedDate = localDate.format('YYYY-MM-DDTHH:mm');
  return formattedDate;
}
