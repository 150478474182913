import React from 'react';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
};

const Input: React.FC<Props> = (props) => {
  const { label, id, ...inputProps } = props;
  const inputId = id || label;

  return (
    <div className="flex flex-col w-full text-sm space-y-0.5">
      <label htmlFor={inputId} className={`font-medium ${props.required ?"required":""}`} >{label}</label>
      <input {...inputProps} id={inputId} className="w-full rounded outline-none px-2 py-1 border border-slate-300" />
    </div>
  );
};

export default Input;
