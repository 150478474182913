import { createSlice } from '@reduxjs/toolkit';

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: [],
  reducers: {
    addBreadcrumb: (state, action) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach(item => {
          state.push({ label: item.label, url: item.url });
        });
      } else {
        if (state.length > 1) {
          state.pop();
        }
        state.push({ label: action.payload.label, url: action.payload.url });
      }
    },
    updateBreadcrumb: (state, action) => {
      return action.payload;
    },
    removeBreadcrumb: (state) => {
      if (state.length > 1) {
        state.pop();
      }
    },
  },
});

export const { addBreadcrumb, updateBreadcrumb, removeBreadcrumb } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
