import { RecordingTypes, SessionStatus } from 'globals/enums';
import { Session, SessionFiles } from 'globals/interfaces';

export const isSessionEnded = (session_status) => {
  return (
    session_status === SessionStatus.NoShow ||
    session_status === SessionStatus.Completed ||
    session_status === SessionStatus.Terminated
  );
};

export const getScreengrabAndCameraGrabs = (sessionFiles: SessionFiles) => {
  return sessionFiles ? Object.values(sessionFiles) : [];
};

export const getAvailableMediaSettings = (configuration) => {
  if (!configuration) return [];
  const { video_recording, secondary_camera, screen_recording } = configuration;
  return [
    video_recording && RecordingTypes.Primary,
    secondary_camera && RecordingTypes.Secondary,
    screen_recording && RecordingTypes.ScreenRecording,
  ].filter(Boolean);
};

export const getAvailableGlobalSettings = (
  selectedAttendees: number[],
  sessions: Session[],
): RecordingTypes[] => {
  if (!selectedAttendees.length) return [];
  const filteredSessions = sessions
    .filter(
      (session: Session) =>
        selectedAttendees.includes(session.attendee_id) && !isSessionEnded(session.session_status),
    )
    .map((session: Session) => getAvailableMediaSettings(session.configuration));

  if (filteredSessions.length) {
    const availableGlobalSettings = [
      RecordingTypes.Primary,
      RecordingTypes.Secondary,
      RecordingTypes.ScreenRecording,
    ]
      .map((type) => filteredSessions.every((session) => session.includes(type)) && type)
      .filter(Boolean);

    return availableGlobalSettings;
  } else {
    return [];
  }
};
