import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterBy } from 'globals/enums';

export const initialState = {
  filterBy: [FilterBy.Critical],
};

const alertFilterSlice = createSlice({
  name: 'alertFilter',
  initialState,
  reducers: {
    setFilterBy: (state, { payload }) => {
      if (payload === FilterBy.Critical) {
        state.filterBy = state.filterBy.some((item) => item === payload)
          ? [FilterBy.Public]
          : [payload];
      } else {
        if (state.filterBy.some((a) => a === payload)) {
          const newFilterBy = state.filterBy.filter((item) => item !== payload);
          state.filterBy = !newFilterBy.length ? [FilterBy.Critical] : newFilterBy;
        } else {
          state.filterBy = [
            ...state.filterBy.filter((item) => item !== FilterBy.Critical),
            payload,
          ];
        }
      }
    },
    resetFilterBy: (state, actions: PayloadAction<null>) => {
      state.filterBy = initialState.filterBy;
    },
  },
});

export const { setFilterBy, resetFilterBy } = alertFilterSlice.actions;

export default alertFilterSlice.reducer;
