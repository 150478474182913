import React from 'react';

type Props = {
 totalProctors: number;
 onlineProctors: number;
}

const DetailedHeader: React.FC<Props> = (props) => {
  const totalProctorsCount = props.totalProctors < 10 ? `0${props.totalProctors}` : props.totalProctors;
  const onlineProctorsCount = props.onlineProctors < 10 ? `0${props.onlineProctors}` : props.onlineProctors;
  const offlineProctorsCount = props.totalProctors - props.onlineProctors < 10 ? `0${props.totalProctors - props.onlineProctors}` : props.totalProctors - props.onlineProctors;
  return (
    <div className='flex items-center space-x-2'>
      <div className='text-4xl text-red-700'>{totalProctorsCount}</div>
      <div className='flex flex-col items-center'>
        <div className='text-sm font-semibold text-red-700 leading-none'> Proctors offline : {offlineProctorsCount}</div>
        <div className='text-sm font-semibold text-green-700 leading-none'>Proctors online : {onlineProctorsCount}</div>
      </div>
    </div>
  );
}

export default DetailedHeader;
