import LoaderImage from 'assets/loader.gif';

const Loader = () => {
  return (
    <div data-testid='loader' className="fixed top-0 left-0 flex items-center justify-center w-full h-full">
      <div className="loader">
        <img src={LoaderImage} alt="loader" height="150px" width="150px" />
      </div>
    </div>
  );
};

export default Loader;
