import React from 'react';
import moment from 'moment';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid';
import { USER_ROLES } from 'common/components/constant';

interface Message {
  role: string;
  read_at?: string;
  created_at: string;
  recieved_at?: string;
}

interface Props {
  message: { [key: string]: Message };
}

export const UnreadMessage: React.FC<Props> = (props) => {
  if (!props.message) return null;
  const keys = Object.keys(props.message);
  const lastMessage = props.message[keys[keys.length - 1]];
  let icon;
  let unreadCount = 0;
  if (lastMessage.role === USER_ROLES.CANDIDATE) {
    if (moment().diff(moment(lastMessage.created_at), 'minutes') < 1) {
      icon = <ChatBubbleLeftRightIcon className="h-5 w-5 fill-orange-500 stroke-white" />;
    } else if (moment().diff(moment(lastMessage.created_at), 'minutes') >= 1) {
      icon = <ChatBubbleLeftRightIcon className="h-5 w-5 fill-red-700 stroke-white" />;
    }
    unreadCount = Object.values(props.message).filter(
      (msg) => msg.role === USER_ROLES.CANDIDATE && !msg.read_at,
    ).length;
  } else if (lastMessage.role === USER_ROLES.PROCTOR) {
    icon = <ChatBubbleLeftRightIcon className="h-5 w-5 fill-emerald-700 stroke-white" />;
  }
  return (
    <div>
      <span className="relative">
        {icon}
        {unreadCount > 0 && (
          <span
            style={{ fontSize: '0.65rem' }}
            className={`absolute text-white font-bold grid place-items-center rounded-full -top-2 -right-1 w-4 h-4 bg-red-600`}
          >
            {unreadCount}
          </span>
        )}
      </span>
    </div>
  );
};
