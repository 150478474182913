export const validateReferenceLinks = (links: string) => {
    if (links === '') {
        return true;
    }
    const pattern = /^\[([^\]]+)\]\(([^)]+)\)$/i;
    const lines = links.split(/\r\n|\r|\n/);
    for (const line of lines) {
        if (!pattern.test(line)) {
            return false;
        }
        const matches = line.match(pattern);
        const url = matches[2];
        if (!new RegExp('^(http|https)://').test(url)) {
            return false;
        }
    }
    return true;
};

export const formatReferenceLinks = (links: string) => {
    if (links === '') {
        return [];
    }
    const pattern = /^\[([^\]]+)\]\(([^)]+)\)$/i;
    const lines = links.split(/\r\n|\r|\n/);
    const formattedLinks = [];
    for (const line of lines) {
        const matches = line.match(pattern);
        const url = matches[2];
        const caption = matches[1];
        formattedLinks.push({ url, caption });
    }
    return formattedLinks;
}
