import { useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ReactPlayer from 'react-player';
import HLSPlayer from './HLSPlayer';
import VideoOverlay from './VideoOverlay';
import VideoUnavailableIcon from 'assets/video-unavailable.svg';
import {
  setActiveVideoFeed,
  updateCurrentPlayingTime,
  updateVideoControl,
} from 'common/slice/playbackVideoSlice';
import {
  getGroupedAlertsBySessionJoined,
  getRecordingDurations,
  getSeekTimeDuration,
  getVideoFeedsByCurrentPlayingTime,
  isTimestampInRange,
} from 'helpers/playback';
import { RecordingTypes, StreamingProvider } from 'globals/enums';
interface VideoFeeds {
  primary: string;
  secondary: string;
  screenshare: string;
}
const VideoContainer = ({
  isRecordingAvailable,
  alerts,
  availableSettings,
  recordings,
  streaming_provider,
}) => {
  const dispatch = useAppDispatch();
  const [videoReadyStates, setVideoReadyStates] = useState({
    [RecordingTypes.Primary]: false,
    [RecordingTypes.Secondary]: false,
    [RecordingTypes.ScreenRecording]: false,
  });

  const [videoEndedStates, setVideoEndedStates] = useState({
    [RecordingTypes.Primary]: false,
    [RecordingTypes.Secondary]: false,
    [RecordingTypes.ScreenRecording]: false,
  });

  const videoPlayerRef = useRef(null);
  const primaryCameraRef = useRef(null);
  const secondaryCameraRef = useRef(null);
  const screenshareRef = useRef(null);

  const isPrimaryStartPlayRef = useRef(false);
  const isSecondaryStartPlayRef = useRef(false);
  const isScreenRecordingStartPlayRef = useRef(false);

  const {
    activeVideoFeed,
    volume,
    currentPlayingTime,
    requestedTimeStamp,
    timelineActive,
    playbackSpeed,
  } = useAppSelector((state) => state.playbackVideo);

  const groupedAlertsBySessionJoins = getGroupedAlertsBySessionJoined(alerts);
  const videoFeed: VideoFeeds | {} =
    recordings &&
    alerts &&
    getVideoFeedsByCurrentPlayingTime(recordings, alerts, currentPlayingTime, streaming_provider);
  const primaryCameraAlertRanges = getRecordingDurations(alerts, RecordingTypes.Primary);
  const isPrimaryVideoStarted = isTimestampInRange(primaryCameraAlertRanges, currentPlayingTime);

  const secondaryCameraAlerts = getRecordingDurations(alerts, RecordingTypes.Secondary);
  const isSecondaryVideoStarted = isTimestampInRange(secondaryCameraAlerts, currentPlayingTime);

  const screenRecordingAlerts = getRecordingDurations(alerts, RecordingTypes.ScreenRecording);
  const isScreenRecordingStarted = isTimestampInRange(screenRecordingAlerts, currentPlayingTime);

  const isVideoReadyToPlay =
    videoFeed &&
    Object.keys(videoFeed).length !== 0 &&
    Object.keys(videoFeed)
      .filter((feed) => availableSettings?.includes(feed))
      .every((feed) => videoReadyStates[feed]);

  // check the primary video stream loaded and ready to play and timelineActive
  const isPrimaryStartPlay = isPrimaryVideoStarted && isVideoReadyToPlay && timelineActive;

  // check the secondary camera video stream loaded and ready to play
  const isSecondaryStartPlay = isSecondaryVideoStarted && isVideoReadyToPlay && timelineActive;

  // check the screen recording camera video stream loaded and ready to play
  const isScreenRecordingStartPlay =
    isScreenRecordingStarted && isVideoReadyToPlay && timelineActive;

  const getVideoStyles = (type: string) => {
    if (!availableSettings?.includes(activeVideoFeed)) {
      return 'hidden';
    }
    return `${type === activeVideoFeed ? 'main-playback-video' : 'side-playback-video'}`;
  };

  const handleVideoChange = (type: string) => {
    dispatch(setActiveVideoFeed(type));
  };

  const updateVideoReadyState = (type, value) => {
    setVideoReadyStates((prevStates) => ({
      ...prevStates,
      [type]: value,
    }));
  };

  const updateVideoEndedState = (type, value) => {
    setVideoEndedStates((prevStates) => ({
      ...prevStates,
      [type]: value,
    }));
  };

  const handleOnBuffer = (type: string) => {
    updateVideoReadyState(type, false);
  };

  const handleOnBufferEnd = (type: string) => {
    updateVideoReadyState(type, true);
    updateVideoEndedState(type, false);
  };

  const handleOnReady = (type: string) => {
    updateVideoReadyState(type, true);
    updateVideoEndedState(type, false);
  };

  const handleOnPlay = (type: string) => {
    updateVideoReadyState(type, true);
    updateVideoEndedState(type, false);
  };

  const handleOnEnded = (type: string) => {
    updateVideoEndedState(type, true);
  };

  const handleFullScreenMode = () => {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    } else {
      try {
        videoPlayerRef.current.requestFullscreen();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateCurrentPlayTime = () => {
    const elapsedTime = 1000 * playbackSpeed;
    dispatch(
      updateCurrentPlayingTime({
        groupedAlerts: groupedAlertsBySessionJoins,
        elapsedTime,
      }),
    );
  };

  useEffect(() => {
    isPrimaryStartPlayRef.current = isPrimaryStartPlay;
  }, [isPrimaryStartPlay]);

  useEffect(() => {
    isScreenRecordingStartPlayRef.current = isScreenRecordingStartPlay;
  }, [isScreenRecordingStartPlay]);

  useEffect(() => {
    isSecondaryStartPlayRef.current = isSecondaryStartPlay;
  }, [isSecondaryStartPlay]);

  const handleOnProgress = (type: string) => {
    const isPrimaryStartPlayRe = isPrimaryStartPlayRef.current;
    const isScreenRecordingStartPlayRe = isScreenRecordingStartPlayRef.current;
    const isSecondaryStartPlayRe = isSecondaryStartPlayRef.current;
    if (isPrimaryStartPlayRe && type === RecordingTypes.Primary) {
      updateCurrentPlayTime();
    } else if (
      isSecondaryStartPlayRe &&
      !isPrimaryStartPlayRe &&
      type === RecordingTypes.Secondary
    ) {
      updateCurrentPlayTime();
    } else if (
      isScreenRecordingStartPlayRe &&
      !isPrimaryStartPlayRe &&
      !isSecondaryStartPlayRe &&
      type === RecordingTypes.ScreenRecording
    ) {
      updateCurrentPlayTime();
    }
  };

  const renderFeedLabel = (feed: string) => {
    let label = '';
    if (feed === RecordingTypes.Primary) {
      label = 'Primary Camera';
    } else if (feed === RecordingTypes.Secondary) {
      label = 'Secondary Camera';
    } else if (feed === RecordingTypes.ScreenRecording) {
      label = 'Screen Recording';
    }
    return activeVideoFeed !== feed && <p className="m-1 font-medium text-center">{label}</p>;
  };

  const isFeedAvailable = (feed: RecordingTypes) => {
    switch (feed) {
      case RecordingTypes.Primary:
        return isPrimaryVideoStarted;
      case RecordingTypes.Secondary:
        return isSecondaryVideoStarted;
      case RecordingTypes.ScreenRecording:
        return isScreenRecordingStarted;
    }
  };

  const getIsFeedPlaying = (feed: RecordingTypes) => {
    return feed === RecordingTypes.Primary
      ? isPrimaryStartPlay
      : feed === RecordingTypes.Secondary
      ? isSecondaryStartPlay
      : isScreenRecordingStartPlay;
  };

  const getVideoFeedRef = (feed: RecordingTypes) => {
    return feed === RecordingTypes.Primary
      ? primaryCameraRef
      : feed === RecordingTypes.Secondary
      ? secondaryCameraRef
      : screenshareRef;
  };

  useEffect(() => {
    if (
      (!isPrimaryVideoStarted ||
        (isPrimaryVideoStarted && videoEndedStates[RecordingTypes.Primary])) &&
      (!isSecondaryVideoStarted ||
        (isSecondaryVideoStarted && videoEndedStates[RecordingTypes.Secondary])) &&
      (!isScreenRecordingStarted ||
        (isScreenRecordingStarted && videoEndedStates[RecordingTypes.ScreenRecording]))
    ) {
      dispatch(updateVideoControl(false));
    } else {
      dispatch(updateVideoControl(true));
    }
  }, [isPrimaryVideoStarted, isSecondaryVideoStarted, isScreenRecordingStarted, videoEndedStates]);

  useEffect(() => {
    if (requestedTimeStamp && timelineActive) {
      const primaryVideoSeekTime = getSeekTimeDuration(
        currentPlayingTime,
        recordings,
        RecordingTypes.Primary,
      );
      if (primaryCameraRef.current) {
        primaryCameraRef.current.seekTo(primaryVideoSeekTime);
      }

      const secondaryVideoSeekTime = getSeekTimeDuration(
        currentPlayingTime,
        recordings,
        RecordingTypes.Secondary,
      );
      if (secondaryCameraRef.current) {
        secondaryCameraRef.current.seekTo(secondaryVideoSeekTime);
      }

      const screenshareVideoSeekTime = getSeekTimeDuration(
        currentPlayingTime,
        recordings,
        RecordingTypes.ScreenRecording,
      );
      if (screenshareRef.current) {
        screenshareRef.current.seekTo(screenshareVideoSeekTime);
      }
    }
  }, [requestedTimeStamp, timelineActive]);

  return isRecordingAvailable ? (
    <div
      ref={videoPlayerRef}
      className="flex-1 overflow-auto h-full relative pt-[70px] pb-44 bg-gray-800 text-white text-sm"
    >
      {availableSettings.map((feed) => {
        return (
          <div
            data-testid={`${feed}_player`}
            key={`${feed}_player`}
            className={getVideoStyles(feed)}
          >
            <div className="relative h-full w-full min-h-[100px]">
              {streaming_provider === StreamingProvider.Twilio ? (
                <ReactPlayer
                  ref={getVideoFeedRef(feed)}
                  url={videoFeed[feed]}
                  className="w-full h-full m-auto rounded-md"
                  width="100%"
                  height="100%"
                  controls={false}
                  volume={volume}
                  playbackRate={playbackSpeed}
                  muted={feed !== RecordingTypes.Primary}
                  playing={getIsFeedPlaying(feed)}
                  onClick={() => handleVideoChange(feed)}
                  onProgress={() => handleOnProgress(feed)}
                  onBuffer={() => handleOnBuffer(feed)}
                  onBufferEnd={() => handleOnBufferEnd(feed)}
                  onReady={() => handleOnReady(feed)}
                  onPlay={() => handleOnPlay(feed)}
                  onEnded={() => handleOnEnded(feed)}
                />
              ) : (
                <HLSPlayer
                  data-testid={`${feed}-video-element`}
                  elementRef={getVideoFeedRef(feed)}
                  url={videoFeed[feed]}
                  className="w-full h-full m-auto rounded-md"
                  width="100%"
                  height="100%"
                  kind={streaming_provider === StreamingProvider.Twilio ? 'mp4' : 'hls'}
                  volume={volume}
                  muted={feed !== RecordingTypes.Primary}
                  playbackRate={playbackSpeed}
                  playing={getIsFeedPlaying(feed)}
                  onClick={() => handleVideoChange(feed)}
                  onProgress={() => handleOnProgress(feed)}
                  onBuffer={() => handleOnBuffer(feed)}
                  onBufferEnd={() => handleOnBufferEnd(feed)}
                  onReady={() => handleOnReady(feed)}
                  onPlay={() => handleOnPlay(feed)}
                  onEnded={() => handleOnEnded(feed)}
                />
              )}
              {!isFeedAvailable(feed) && videoReadyStates[feed] && (
                <div className="absolute inset-0 flex h-full justify-center items-center bg-black">
                  <span className="w-8/12 text-center">Recording yet to start</span>
                </div>
              )}
              {!videoReadyStates[feed] && (
                <div
                  data-testid={`${feed}_loader`}
                  className="absolute inset-0 flex h-full justify-center items-center bg-black"
                >
                  <span className="flex items-center justify-center" role="spinbutton">
                    <span className="w-6 h-6 border-2 border-gray-400 rounded-full animate-spin border-r-transparent"></span>
                  </span>
                </div>
              )}
            </div>
            {renderFeedLabel(feed)}
          </div>
        );
      })}
      <VideoOverlay
        alerts={alerts}
        streamingProvider={streaming_provider}
        handleFullscreenMode={handleFullScreenMode}
      />
    </div>
  ) : (
    <div className="h-full max-h-[500px] text-sm flex flex-col justify-center items-center gap-1 bg-gray-800 text-white">
      <img src={VideoUnavailableIcon} className="h-14 w-14" alt="Video unavailable icon" />
      <p className="mt-3 font-medium">Video not available</p>
      <p>Reach out to your admin for support.</p>
    </div>
  );
};

export default VideoContainer;
