import { useEffect, useState } from 'react';

interface Props {
  startTimestamp: string;
}

const Duration = ({ startTimestamp }: Props) => {
  const elapsedTime = new Date(startTimestamp).getTime()
    ? Math.floor((new Date().getTime() - new Date(startTimestamp).getTime()) / 1000)
    : 0;

  const [time, setTime] = useState(elapsedTime);

  const formatTime = (time: number) =>
    `${String(Math.floor(time / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`;

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((t) => t + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  return <span>{formatTime(time)}</span>;
};

export default Duration;
