import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const {
      payload: { data, error },
    } = action;
    Sentry.captureMessage(data?.message || error);
  }

  return next(action);
};

export default rtkQueryErrorLogger;
