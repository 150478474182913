import { getSignedUrl } from 'helpers/prechecks';
import { useAppSelector } from 'app/hooks';

type Props = {
  baseImageUrl: string;
  signedUrl?: string;
  faceCaptureUrl: string;
  session_uuid: string;
  session_id: string;
};

const ImagePreview = ({
  baseImageUrl,
  faceCaptureUrl,
  session_uuid,
  session_id,
  signedUrl,
}: Props) => {
  const token = useAppSelector((state) => state.session.token)[session_id]?.value;

  const baseSignedUrl = signedUrl
    ? signedUrl
    : getSignedUrl(session_uuid, baseImageUrl, token);

  const faceCaptureSignedUrl = signedUrl
    ? faceCaptureUrl
    : getSignedUrl(session_uuid, faceCaptureUrl, token);

  
  return (
    (token || signedUrl) && (
      <div className="flex items-stretch gap-1 h-full max-h-96">
        {baseSignedUrl && (
          <div className="flex-1 bg-slate-200">
            <img
              src={baseSignedUrl}
              alt="Face ID"
              className="h-full block my-0 mx-auto object-contain"
            />
          </div>
        )}
        {faceCaptureSignedUrl && (
          <div className="flex-1">
            <img src={faceCaptureSignedUrl} alt="Face Verification" className="w-full h-full" />
          </div>
        )}
      </div>
    )
  );
};

export default ImagePreview;
