import { ProviewRating, StreamingProvider } from 'globals/enums';
import ActiveVideoSetting from './ActiveVideoSetting';
import VideoControls from './VideoControls';
import AlertToastContainer from './AlertToastContainer';
import SeekbarContainer from './SeekbarContainer';
import { Alert } from 'globals/interfaces';
import { useAppSelector } from 'app/hooks';
import { getVideoPlaybackAlerts } from 'helpers/playback';

interface Props {
  alerts: Alert[];
  handleFullscreenMode: () => void;
  streamingProvider: StreamingProvider
}

const VideoOverlay = ({ alerts, streamingProvider, handleFullscreenMode }: Props) => {
  const proviewRating = useAppSelector((state) => state.playbackVideo.proviewRating);
  const filterBy = useAppSelector((state) => state.alertFilter.filterBy);
  const videoPlaybackAlerts = getVideoPlaybackAlerts(alerts, filterBy);

  const getRatingStyle = () => {
    switch (proviewRating) {
      case ProviewRating.Low:
        return 'bg-red-200 text-red-800';
      case ProviewRating.Medium:
        return 'bg-amber-200 text-amber-800';
      case ProviewRating.High:
        return 'bg-green-200 text-green-800';
      default:
        return 'bg-white text-black';
    }
  };

  return (
    <div className="absolute top-0 flex flex-col w-full h-full">
      <div className="flex items-center justify-between mt-4">
        <ActiveVideoSetting />
        <div className="flex items-center">
          <div className="px-6 py-3 text-xs text-white bg-blue-600 rounded-l-full">
            Proview Index
          </div>
          <div className={`py-2.5 px-6 font-medium capitalize ${getRatingStyle()}`}>
            {proviewRating || '---'}
          </div>
        </div>
      </div>
      <div className="mt-auto">
        {videoPlaybackAlerts.length > 0 && (
          <>
            <AlertToastContainer alerts={videoPlaybackAlerts} />
            <div className="flex justify-between px-4 mb-5 text-white">
              <ul className="flex items-center self-end gap-2 text-sm">
                <li className="mr-2 text-base font-semibold">Alert Timeline</li>
                <li>
                  <span className="inline-block w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span> Low
                </li>
                <li>
                  <span className="inline-block w-2 h-2 mr-2 rounded-full bg-amber-500"></span>
                  Medium
                </li>
                <li>
                  <span className="inline-block w-2 h-2 mr-2 bg-red-500 rounded-full"></span>
                  High
                </li>
              </ul>
            </div>
            <SeekbarContainer alerts={videoPlaybackAlerts} />
            <VideoControls
              streamingProvider={streamingProvider}
              alerts={videoPlaybackAlerts}
              handleFullscreenMode={handleFullscreenMode}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default VideoOverlay;
