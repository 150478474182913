import React from 'react';

type Props = {
  type: 'short' | 'long';
  className?: string;
};

const DisplayLogo: React.FC<Props> = (props) => {
  return (
    <div className={`flex w-fit h-fit justify-center items-center ${props.className}`}>
      <img
        src="https://play-lh.googleusercontent.com/4Qe2uXtJEPKikO2AJ0lD-do6DgCzvdIl9swknNrGJh18csgAAfWhP0_-QtXObzW4brM"
        alt="talview-logo"
        className="max-h-14 sm:max-h-20 lg:max-h-28 object-scale-down"
      />
      {props.type === 'long' && (
        <div className="flex flex-col">
          <div className="text-base sm:text-2xl lg:text-4xl leading-none font-semibold text-emerald-800">
            Proctoring
          </div>
          <div className="text-sm sm:text-base lg:text-2xl font-normal text-slate-600">Admin</div>
        </div>
      )}
    </div>
  );
};

export default DisplayLogo;
