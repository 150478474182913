type InfoCardProps = {
  attendeeInfo: {
    attendee_external_id: number | string;
    attendee_first_name?: string;
    attendee_last_name?: string;
    attendee_email?: string;
  };
};

const InfoCard = ({
  attendeeInfo: { attendee_external_id, attendee_first_name, attendee_last_name, attendee_email },
}: InfoCardProps) => {
  return (
    <article className="bg-white border-gray-200 dark:text-gray-400 dark:border-gray-700 rounded p-4 shadow-sm">
      <h4 className="font-bold text-black">Attendee ID: {attendee_external_id}</h4>
      <div className="mt-4 grid gap-4 grid-cols-2">
        <div>
          <p className="text-black">First Name</p>
          <p>{attendee_first_name || '-'}</p>
        </div>
        <div>
          <p className="text-black">Last Name</p>
          <p>{attendee_last_name || '-'}</p>
        </div>
        <div className="col-span-2">
          <p className="text-black">Email</p>
          <p>{attendee_email || '-'}</p>
        </div>
      </div>
    </article>
  );
};

export default InfoCard;
