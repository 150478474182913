import {createApi} from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./base";
import {PROVIEW_API_URL, PROVIEW_EVENT_SCHEDULER_URL} from "../components/constant";


interface ScheduleEventRequest {
  name: string;
  external_event_id: string;
}

interface ScheduleEventResponse {
  id: number;
  status: string;
  name: string;
  type: string;
  organization_id: number;
  external_event_id: string;
  owner_id: number;
  display_timezone: string;
  links: {
    fetch: string;
    update: string;
    delete: string;
    schedule: string;
  };
}

const eventSchedulerApi = createApi({
  reducerPath: "eventSchedulerApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    scheduleEvent: builder.mutation<ScheduleEventResponse, ScheduleEventRequest>({
      query: (body) => ({
        url: `${PROVIEW_EVENT_SCHEDULER_URL}/v1/event`,
        method: 'POST',
        body,
        headers: {
          "Content-Type": "application/json",
          "is-universal-proview": "true"
        }
      }),
    }),
    createSlots: builder.mutation({
      query: (body) => ({
        url: `${PROVIEW_EVENT_SCHEDULER_URL}/v1/slot`,
        method: 'POST',
        body,
        headers: {
          "Content-Type": "application/json",
          "is-universal-proview": "true"
        }
      }),
    }),
    updateEvent: builder.mutation({
      query: (payload) => ({
        url: `${PROVIEW_API_URL}v1/proctor-event/${payload.eventId}`,
        method: 'PATCH',
        body: payload.body,
        headers: {
          "Content-Type": "application/json",
          "is-universal-proview": "true"
        }
      }),
    }),
    addCandidates: builder.mutation({
      query: (payload) => ({
        url: `${PROVIEW_API_URL}v1/attendee/bulk-upload?event_id=${payload.eventId}&slot_id=${payload.slotId}`,
        method: 'POST',
        body: payload.body,
        headers: {
          "is-universal-proview": "true"
        }
      }),
    }),
  }),
});

export const {
  useScheduleEventMutation,
  useCreateSlotsMutation,
  useUpdateEventMutation,
  useAddCandidatesMutation
} = eventSchedulerApi;
export default eventSchedulerApi;

