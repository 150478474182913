import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

interface AudioTokenResponse {
  token: string;
}

const audioCallTokenApi = createApi({
  reducerPath: 'audioCallTokenApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fetchTwilioToken: builder.query({
      query: () => 'v1/audio-call/proctor/token',
      transformResponse: (response: AudioTokenResponse) => {
        return response.token;
      },
    }),
  }),
});

export const { useFetchTwilioTokenQuery } = audioCallTokenApi;

export default audioCallTokenApi;
