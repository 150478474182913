import { CheckIcon, UserIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { PRECHECK_STATUS, PRECHECK_STEPS } from 'common/components/constant';

export enum Alert {
  SessionJoined = 3,
  CameraTestPassed = 26,
  NotificationPermissionGranted = 17,
  AudioDeviceDetected = 159,
  ScreenCaptureEnabled = 149,
  IdCardCaptureSuccess = 156,
  FaceCaptureSuccess = 154,
  TestEnvCaptureSuccess = 161,
  SecondaryCameraDisconnected = 190,
  SecondaryCameraConnected = 189,
  PrimaryCameraDisconnected = 197,
  PrimaryCameraConnected = 196,
  AudioCallEnded = 176,
  AudioCallProgress = 175,
  AudioCallInitiated = 173,
  AudioCallRequested = 172,
  AudioTestPassed = 34,
}

export const validAlerts = [
  Alert.SessionJoined,
  Alert.CameraTestPassed,
  Alert.NotificationPermissionGranted,
  Alert.AudioDeviceDetected,
  Alert.ScreenCaptureEnabled,
  Alert.IdCardCaptureSuccess,
  Alert.FaceCaptureSuccess,
  Alert.TestEnvCaptureSuccess,
  Alert.SecondaryCameraConnected,
  Alert.SecondaryCameraDisconnected,
  Alert.AudioCallEnded,
  Alert.AudioCallProgress,
  Alert.AudioCallInitiated,
  Alert.AudioCallRequested,
  Alert.AudioTestPassed,
];

export const subset = [
  Alert.SessionJoined,
  Alert.CameraTestPassed,
  Alert.NotificationPermissionGranted,
  Alert.AudioDeviceDetected,
  Alert.ScreenCaptureEnabled,
  Alert.IdCardCaptureSuccess,
  Alert.FaceCaptureSuccess,
  Alert.TestEnvCaptureSuccess,
  Alert.AudioTestPassed,
  Alert.SecondaryCameraConnected,
];

const assignPermissions = (data) => {
  const icon = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
  data.microphonePermission = icon;
  data.webcamPermission = icon;
  data.notificationPermission = icon;
};
const checkAll = (alerts, ...alertsToCheck) =>
  alertsToCheck.every((alert) => alerts.includes(alert));
const getStepStatus = (
  alerts: any,
  faceCaptureTimestamp: string,
  screenShareTimestamp: string,
  prechecks: any,
  lastOnlineAlertTimestamp: string,
  secondaryCameraEnabled: boolean,
) => {
  let iconColor = 'text-emerald-700';
  if (
    moment().diff(moment(lastOnlineAlertTimestamp), 'minutes') >= 1 &&
    moment().diff(moment(lastOnlineAlertTimestamp), 'minutes') < 2
  ) {
    iconColor = 'text-orange-400';
  } else if (moment().diff(moment(lastOnlineAlertTimestamp), 'minutes') >= 2) {
    iconColor = 'text-red-700';
  }
  let testEnvRejected,
    photoIdRejected,
    testEnvSubmitted,
    testEnvApproved = false;
  const alertSubset = [];
  alerts.forEach((alert) => {
    if (subset.includes(alert)) alertSubset.push(alert);
  });
  let responseData = {
    secondaryCamera: null,
    chat: null,
    verification: null,
    testEnv: null,
    idCard: null,
    photo: null,
    audioTest: null,
    screenShare: null,
    notificationPermission: null,
    webcamPermission: null,
    microphonePermission: null,
    secondaryCameraSetup: null,
    currentStep: null,
    lastOnlineAlertTimestamp: lastOnlineAlertTimestamp,
  };
  const latestAlert = alertSubset[0];
  if (prechecks) {
    const keys = Object.keys(prechecks);
    const precheckList = [];
    keys.forEach((key, i) => {
      precheckList.push(prechecks[key]);
    });
    const testEnvData = precheckList.filter(
      (d) =>
        moment(d.requested_timestamp) > moment(faceCaptureTimestamp) &&
        d.step === PRECHECK_STEPS.TEST_ENV,
    );
    const photoIdData = precheckList.filter(
      (d) =>
        moment(d.requested_timestamp) > moment(screenShareTimestamp) &&
        d.step === PRECHECK_STEPS.PHOTO_ID,
    );
    const res1 = {
      ...responseData,
      screenShare: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      photo: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      idCard: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      audioTest: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      microphonePermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      webcamPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      notificationPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
    };
    if (
      testEnvData &&
      testEnvData.length &&
      testEnvData[testEnvData.length - 1].status === PRECHECK_STATUS.APPROVED
    ) {
      testEnvApproved = true;
      responseData = {
        ...res1,
        testEnv: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
        verification: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
        currentStep: 8,
      };
    } else if (
      testEnvData &&
      testEnvData.length &&
      testEnvData[testEnvData.length - 1].status === PRECHECK_STATUS.SUBMITTED
    ) {
      testEnvSubmitted = true;
      responseData = {
        ...res1,
        testEnv: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
        verification: <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />,
        currentStep: 7,
      };
    } else if (
      testEnvData &&
      testEnvData.length &&
      testEnvData[testEnvData.length - 1].status === PRECHECK_STATUS.REJECTED
    ) {
      testEnvRejected = true;
      responseData = {
        ...res1,
        testEnv: <XCircleIcon className={`h-5 w-5 ${iconColor} stroke-2`} />,
        verification: <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />,
        currentStep: 7,
      };
    }
    if (
      photoIdData &&
      photoIdData.length &&
      photoIdData[photoIdData.length - 1].status === PRECHECK_STATUS.REJECTED
    ) {
      photoIdRejected = true;
      responseData = {
        ...responseData,
        idCard: <XCircleIcon className={`h-5 w-5 ${iconColor} stroke-2`} />,
        verification: <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />,
        currentStep: 5,
      };
    }
  }
  const actions = {
    [Alert.CameraTestPassed]: () => {
      if (
        checkAll(
          alerts,
          Alert.CameraTestPassed,
          Alert.NotificationPermissionGranted,
          Alert.AudioDeviceDetected,
        )
      ) {
        assignPermissions(responseData);
        responseData.screenShare = <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />;
        responseData.currentStep = 1;
      }
    },
    [Alert.NotificationPermissionGranted]: () => {
      if (
        checkAll(
          alerts,
          Alert.CameraTestPassed,
          Alert.NotificationPermissionGranted,
          Alert.AudioDeviceDetected,
        )
      ) {
        assignPermissions(responseData);
        responseData.screenShare = <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />;
        responseData.currentStep = 1;
      }
    },
    [Alert.AudioDeviceDetected]: () => {
      if (
        checkAll(
          alerts,
          Alert.CameraTestPassed,
          Alert.NotificationPermissionGranted,
          Alert.AudioDeviceDetected,
        )
      ) {
        assignPermissions(responseData);
        responseData.screenShare = <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />;
        responseData.currentStep = 1;
      }
    },
    [Alert.IdCardCaptureSuccess]: () => {
      assignPermissions(responseData);
      responseData.idCard = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      responseData.photo = testEnvRejected ? (
        <XCircleIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
      ) : (
        <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      );
      responseData.screenShare = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      responseData.secondaryCameraSetup = secondaryCameraEnabled ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : null;
      responseData.currentStep = 4;
    },
    [Alert.FaceCaptureSuccess]: () => {
      assignPermissions(responseData);
      responseData.idCard = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      responseData.photo = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      responseData.audioTest = testEnvRejected ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : testEnvSubmitted ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : testEnvApproved ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : (
        <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
      );
      responseData.screenShare = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      responseData.secondaryCameraSetup = secondaryCameraEnabled ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : null;
      responseData.currentStep = 5;
    },
    [Alert.AudioTestPassed]: () => {
      assignPermissions(responseData);
      responseData.secondaryCameraSetup = secondaryCameraEnabled ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : null;
      responseData.idCard = photoIdRejected ? (
        <XCircleIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
      ) : (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      );
      responseData.photo = photoIdRejected ? (
        <XCircleIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
      ) : (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      );
      responseData.audioTest = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      responseData.testEnv = testEnvRejected ? (
        <XCircleIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
      ) : testEnvSubmitted ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : testEnvApproved ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : (
        <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
      );
      responseData.screenShare = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      responseData.currentStep = 6;
    },
    [Alert.ScreenCaptureEnabled]: () => {
      assignPermissions(responseData);
      if (testEnvApproved) {
        responseData.testEnv = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.verification = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.currentStep = 8;
        responseData.photo = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.idCard = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.audioTest = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.screenShare = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.secondaryCameraSetup = secondaryCameraEnabled ? (
          <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
        ) : null;
      } else {
        responseData.screenShare = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.idCard = secondaryCameraEnabled ? null : (
          <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
        );
        responseData.secondaryCameraSetup = secondaryCameraEnabled ? (
          <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
        ) : null;
        responseData.currentStep = 2;
      }
    },
    [Alert.SecondaryCameraConnected]: () => {
      assignPermissions(responseData);
      if (testEnvApproved) {
        responseData.testEnv = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.verification = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.currentStep = 8;
        responseData.photo = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.idCard = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.audioTest = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.screenShare = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
      } else {
        responseData.secondaryCameraSetup = (
          <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
        );
        responseData.screenShare = <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />;
        responseData.idCard = testEnvRejected ? (
          <XCircleIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
        ) : testEnvSubmitted ? (
          <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
        ) : testEnvApproved ? (
          <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
        ) : (
          <UserIcon className={`h-5 w-5 ${iconColor} stroke-2`} />
        );
        responseData.currentStep = 3;
      }
    },
  };
  const action = actions[latestAlert];
  if (action) action();
  return responseData;
};
export default getStepStatus;
