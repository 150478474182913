import {
  FlagIcon,
  ChatBubbleLeftEllipsisIcon,
  ShieldExclamationIcon,
  PhoneIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { setActiveTab } from 'common/slice/activeTabSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { TABS } from './constant';
import { setActiveAttendee } from 'common/slice/activeAttendeeSlice';
import { addBreadcrumb } from 'common/slice/breadcrumbsSlice';
import { Tooltip } from 'react-tooltip';
import { getAudioCallStatus } from 'helpers/messages';
import { openModal } from 'common/slice/modalSlice';
import { SessionStatus } from 'globals/enums';
import { isSessionEnded } from 'helpers/session';
import { Attendee } from 'common/api/proctorType';
import * as Popover from '@radix-ui/react-popover';

interface Props {
  attendee: Attendee;
  isApprovalPending: boolean;
  isChatUnread: boolean;
  tooltipId: string;
  sessionId: string;
  sessionUuid: string;
  sessionStatus: string;
  isCriticalAlertUnread: boolean;
  sessionCompletedAt: string;
  sessionJoinedAt: string;
}

const AttendeePane = ({
  sessionJoinedAt,
  sessionCompletedAt,
  attendee,
  isApprovalPending,
  isChatUnread,
  tooltipId,
  sessionId,
  sessionUuid,
  sessionStatus,
  isCriticalAlertUnread,
}: Props) => {
  const dispatch = useAppDispatch();
  const audioCall = useAppSelector((state) => state.audioCall);
  const { isDetailView } = useAppSelector((state) => state.activeAttendee);
  const activeTab = useAppSelector((state) => state.activeTab.tab);
  const tooltip = tooltipId ? tooltipId : `tile_tooltip_${attendee?.attendee_id}`;
  const actionTooltipId = tooltipId
    ? `action_${tooltipId}`
    : `tile_tooltip_action_${attendee?.attendee_id}`;
  const audioCallStatus = getAudioCallStatus(sessionId, audioCall.audioCallStatuses);

  const handleChange = (value: string, event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setActiveTab(value));
    dispatch(setActiveAttendee({ ...attendee, isDetailView }));
    const payload = {
      label: attendee?.attendee_external_id,
      url: '',
    };
    if (isDetailView) {
      dispatch(addBreadcrumb(payload));
    }
  };

  const handleAction = (event: React.MouseEvent, type: string) => {
    event.stopPropagation();
    dispatch(
      openModal({
        attendeeId: attendee?.attendee_id,
        type,
        sessionUuid,
        sessionId,
        slotEndDate: attendee?.slot_ends_at,
        slotStartDate: attendee?.slot_starts_at,
        sessionJoinedAt,
        sessionCompletedAt,
      }),
    );
  };

  return (
    <div className="w-full pl-2 flex items-center justify-between gap-1 whitespace-nowrap overflow-hidden">
      <div
        className="min-w-24 text-sm cursor-pointer overflow-hidden text-ellipsis"
        onClick={(event) => handleChange(activeTab, event)}
        data-tooltip-id={tooltip}
      >
        {attendee?.attendee_external_id}
      </div>
      <Tooltip className="z-[1000]" id={tooltip} place="bottom">
        <div className="flex flex-col gap-1 text-xs">
          <div>
            Name: {attendee?.attendee_first_name ? attendee?.attendee_first_name : '--'}{' '}
            {attendee?.attendee_last_name ? attendee?.attendee_last_name : '--'}
          </div>
          <div>Email: {attendee?.attendee_email ? attendee?.attendee_email : '--'}</div>
          <div>
            Attendee ID: {attendee?.attendee_external_id ? attendee?.attendee_external_id : '--'}
          </div>
        </div>
      </Tooltip>
      <FlagIcon
        className={`h-4 w-4 cursor-pointer ${isCriticalAlertUnread ? 'text-red-500' : ''}`}
        onClick={(event) => handleChange(TABS.ALERT_LOGS, event)}
      />
      <ChatBubbleLeftEllipsisIcon
        className={`h-4 w-4 cursor-pointer ${isChatUnread ? 'text-red-500' : ''}`}
        onClick={(event) => handleChange(TABS.CHAT, event)}
      />
      <ShieldExclamationIcon
        className={`h-4 w-4 cursor-pointer ${isApprovalPending ? 'text-red-500' : ''}`}
        onClick={(event) => handleChange(TABS.APPROVALS, event)}
      />
      <PhoneIcon
        className={`h-4 w-4 cursor-pointer ${
          audioCallStatus === 'requested'
            ? 'text-yellow-500 fill-current'
            : audioCallStatus === 'connected'
            ? 'text-green-500 fill-current'
            : ''
        }`}
        onClick={(event) => handleChange(TABS.CHAT, event)}
      />
      <Popover.Root>
        <Popover.Trigger onClick={(event) => event.stopPropagation()}>
          <EllipsisVerticalIcon
            data-tooltip-id={actionTooltipId}
            className={`block h-4 w-4 cursor-pointer ${isChatUnread ? 'text-red-500' : ''}`}
          />
        </Popover.Trigger>
        {sessionStatus !== SessionStatus.NoShow && (
          <Popover.Portal>
            <Popover.Content>
              <div className="absolute z-10 gap-5 text-sm text-black bg-white popover-content bottom-5 -right-3">
                {!sessionId && (
                  <p
                    onClick={(event) => handleAction(event, SessionStatus.NoShow)}
                    className="p-2 cursor-pointer hover:text-blue-500 rounded-xl"
                  >
                    Mark as No-Show
                  </p>
                )}
                {sessionId && !isSessionEnded(sessionStatus) && (
                  <p
                    onClick={(event) => handleAction(event, SessionStatus.Terminated)}
                    className="p-2 cursor-pointer hover:text-blue-500 rounded-xl"
                  >
                    Terminate
                  </p>
                )}
                {sessionId && (
                  <p
                    onClick={(event) => handleAction(event, 'add-flag')}
                    className="p-2 cursor-pointer hover:text-blue-500 rounded-xl"
                  >
                    Add Flag
                  </p>
                )}
              </div>
            </Popover.Content>
          </Popover.Portal>
        )}
      </Popover.Root>
    </div>
  );
};

export default AttendeePane;
