import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import BrightSpaceConfigForm from './BrightSpaceConfigForm';
import { BrightSpaceOAuthModal } from './BrightSpaceOauthModal';
import { useBrightSpaceConfig } from './useBrightSpaceConfig';
import Button from 'common/components/Button';
import { cli } from 'webpack';


export const BrightSpaceLmsConfiguration = () => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    config,
    isLoading,
    handleUpdateConfiguration,
    setConfig,
    handleAuthCodeExchange
  } = useBrightSpaceConfig(currentUser.organization_id);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code && !isLoading) {
      handleAuthCodeExchange(code);
    }
  }, [window.location, isLoading]);

  return (
    <div className="p-4 bg-white border rounded shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold mb-4">BrightSpace Configuration</h2>
        <Button
          type="button"
          className="bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center mr-4"
          label="Generate Refresh Token"
          onClick={() => setIsModalOpen(true)}
        />
      </div>
      {isLoading ? (
        <p>Loading...</p>

      ) : (
        <BrightSpaceConfigForm
          config={{
            lmsUrl: config.lmsUrl,
            proviewAdminUsername: config.proviewAdminUsername,
            proviewAdminPassword: config.proviewAdminPassword,
            refreshToken: config.refreshToken,
            appId: config.appId,
          }}
          setConfig={setConfig}
          handleUpdateConfiguration={handleUpdateConfiguration}
        />
      )}
      <BrightSpaceOAuthModal
        clientId={config.clientId}
        clientSecret={config.clientSecret}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  );
};
