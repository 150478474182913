import React from 'react';
import { useAppSelector } from 'app/hooks';
import { CameraIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { VerificationTypes } from '../../globals/enums';

type Props = {
  type: string;
  children: React.ReactNode;
};

const MediaPreviewModal = ({ type, children }: Props) => {
  const { attendee_first_name, attendee_last_name, attendee_id } = useAppSelector(
    (state) => state.activeAttendee,
  );

  return (
    <div className="flex flex-col gap-4">
      <header className="flex items-center gap-4 mb-2">
        <span className="w-10 h-10 bg-green-200 text-green-900 rounded-full flex p-2">
          {type === VerificationTypes.TestEnv ? <VideoCameraIcon /> : <CameraIcon />}
        </span>
        <div>
          <p className="font-bold">{`
          ${attendee_first_name || '-'} ${attendee_last_name || '-'} | CN-${attendee_id}`}</p>
          <p>
            {type === VerificationTypes.TestEnv
              ? 'Environment Check - Video'
              : 'Identity Check - Photo'}
          </p>
        </div>
      </header>
      {children}
    </div>
  );
};

export default MediaPreviewModal;
