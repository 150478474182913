import React, { useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowDownOnSquareStackIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { GET_ATTENDEES } from '../queries.graphql';
import { getAttendeeColumns } from './Columns';
import { useQuery } from '@apollo/client';
import { useSyncAttendeesMutation } from '../../../common/api/lmsApi';
import Spinner from './Spinner';
import { attendeeCsvGenerator } from './attendeeCsvGenerator';
import Modal from '../../../common/components/Modal';

const AttendeeView = ({ quiz, setSelectedAttendees, selectedAttendees }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [attendeeSync, { isLoading }] = useSyncAttendeesMutation({});
  const noAttendeesSelected = !selectedAttendees.length;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { data, error, loading, refetch } = useQuery(GET_ATTENDEES, {
    variables: {
      quiz_id: quiz.id,
      search: `%${searchQuery}%`,
    },
  });

  const handleRowClick = (attendee) => {
    setSelectedAttendees((prevSelectedAttendees) => {
      if (prevSelectedAttendees.includes(attendee)) {
        return prevSelectedAttendees.filter((selected) => selected !== attendee);
      } else {
        return [...prevSelectedAttendees, attendee];
      }
    });
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectAll = () => {
    setSelectedAttendees(data?.lms_quiz_attendee || []);
  };

  const handleDeselectAll = () => {
    setSelectedAttendees([]);
  };

  const handleSyncAttendees = async () => {
    setSelectedAttendees([]);
    data?.lms_quiz_attendee_aggregate?.aggregate?.count > 0
      ? setShowConfirmationModal(true)
      : await syncAttendees();
  };

  const syncAttendees = async () => {
    const data = await attendeeSync({
      quizIds: [quiz.id],
    });
    if (data) {
      await refetch();
    }
  };

  const handleConfirmReSync = async () => {
    setShowConfirmationModal(false);
    await syncAttendees();
  };
  const handleCancelReSync = () => {
    setShowConfirmationModal(false);
  };

  const columns = getAttendeeColumns(
    handleSelectAll,
    handleDeselectAll,
    selectedAttendees,
    data?.lms_quiz_attendee || [],
    handleRowClick,
  );

  const handleDownloadCsv = async () => {
    const formData = await attendeeCsvGenerator(quiz, selectedAttendees);
    const url = URL.createObjectURL(formData.get('file') as Blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'attendees.csv';
    link.click();
    URL.revokeObjectURL(url);
  };

  const table = useReactTable({
    data: data?.lms_quiz_attendee || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="flex flex-col h-screen">
      <div className="flex justify-between mt-4 mb-2">
        <h3 className="text-md font-semibold ml-4">Attendees</h3>
        <h3 className="text-md font-semibold mr-4">
          Count: {data?.lms_quiz_attendee_aggregate?.aggregate?.count}
        </h3>
      </div>

      <div className="flex justify-between items-center mt-4 mb-3">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-400 rounded-lg p-1 ml-4"
            onChange={handleSearch}
          />
        </div>

        <div className="flex items-center">
          <button
            id="download-csv"
            disabled={noAttendeesSelected}
            onClick={handleDownloadCsv}
            className={`bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center mr-4 ${
              noAttendeesSelected ? 'opacity-50 cursor-not-allowed ' : ''
            }`}
          >
            <ArrowDownOnSquareStackIcon className="h-6 w-6" /> Download
          </button>

          <button
            onClick={handleSyncAttendees}
            className={`bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center mr-4`}
          >
            <ArrowPathIcon className="h-5 w-5 mr-2" />
            <span>
              {data?.lms_quiz_attendee_aggregate?.aggregate?.count > 0
                ? 'Re-Sync Attendees'
                : 'Sync Attendees'}
            </span>
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg border-2 overflow-auto ml-4 mr-4">
        <div className="w-full h-80">{isLoading ? <Spinner /> : <Table table={table} />}</div>
      </div>
      {showConfirmationModal && (
        <Modal show={showConfirmationModal} onCancel={handleCancelReSync}>
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Sync Attendees</h2>
            <div className="mb-4">
              <p className="text-sm font-medium mb-1">
                Are you sure you want to re-sync attendees for this quiz?
              </p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleConfirmReSync}
                className={`bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center mr-4`}
              >
                <ArrowPathIcon className="h-5 w-5 mr-2" />
                <span>Confirm</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

const Table = ({ table }) => (
  <table className=" text-sm w-full">
    <thead className="border-b border-slate-300">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id} className="text-start p-2 px-4">
              {!header.isPlaceholder &&
                flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody className="border-b border-slate-300 mt-3">
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id} className="hover:bg-gray-100 cursor-pointer">
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className="p-1.5 px-4 break-normal">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default AttendeeView;
