import { gql } from '@apollo/client';

export const GET_SLOTS = gql`
  query GetSlots($startsAt: timestamptz!, $endsAt: timestamptz!) {
    views_slot(
      where: {
        starts_at: { _gte: $startsAt }
        _and: { ends_at: { _lt: $endsAt }, _and: { status: { _in: ["scheduled", "live"] } } }
      }
    ) {
      id
      starts_at
      ends_at
      status
      event {
        id
        name
      }
      participant {
        id
        participant_group_id
        role
        user_id
      }
    }
  }
`;

export const GET_PARTICIPANT_DETAILS = gql`
  query GetParticipantDetails($proctorIds: [Int!]!, $attendeeIds: [Int!]!) {
    views_user(where: { id: { _in: $proctorIds } }) {
      id
      first_name
      last_name
      username
      email
    }
    attendee(where: { id: { _in: $attendeeIds } }) {
      id
      first_name
      last_name
      email
      external_attendee_id
    }
  }
`;

export const wrapperlinks = gql`
  query wrapper(
    $limit: Int!
    $offset: Int!
    $status: String_comparison_exp = {}
    $type: String_comparison_exp = {}
    $expiry: timestamptz_comparison_exp = {}
    $search: String
    $proview_token_id: Int_comparison_exp = {}
  ) {
    proctoring_wrapper_invite(
      limit: $limit
      offset: $offset
      where: {
        status: $status
        type: $type
        expiry: $expiry
        proview_token_id: $proview_token_id
        _or: [
          { attendee_external_id: { _ilike: $search } }
          { session_external_id: { _ilike: $search } }
        ]
      }
    ) {
      attendee_external_id
      session_external_id
      expiry
      organization_id
      proview_token_id
      url
      verification_code
      id
      deleted
      type
      status
      configurations
      updated_at
      created_at
      signed_url
      signed_short_url
      wrapper_invite_proview_token {
        name
      }
    }
    proctoring_wrapper_invite_aggregate(
      where: {
        status: $status
        type: $type
        expiry: $expiry
        proview_token_id: $proview_token_id
        _or: [
          { attendee_external_id: { _ilike: $search } }
          { session_external_id: { _ilike: $search } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_QUIZZES = gql`
  query GetQuizzes(
    $proview_organization_id: Int!
    $offset: Int!
    $limit: Int!
    $search: String!
    $timeclose: timestamp_comparison_exp = {}
    $timeopen: timestamp_comparison_exp = {}
  ) {
    lms_quiz_aggregate(
      where: {
        quiz_organization: { proview_organization_id: { _eq: $proview_organization_id } }
        _and: [{ timeclose: $timeclose }]
      }
    ) {
      aggregate {
        count
      }
    }
    lms_quiz(
      where: {
        quiz_organization: { proview_organization_id: { _eq: $proview_organization_id } }
        _or: [{ quiz_title: { _ilike: $search } }]
        _and: [{ timeclose: $timeclose }, { timeopen: $timeopen }]
      }
      limit: $limit
      offset: $offset
      order_by: { id: asc }
    ) {
      id
      external_id
      course_id
      course_module_id
      attempts
      is_deleted
      quiz_organization {
        proview_organization_id
        lms_config
      }
      proctoring_type
      proctoring_enabled
      quiz_title
      reference_links
      screen_protection
      timeclose
      timelimit
      timemodified
      timeopen
      tsb_enabled
      updated_at
      whitelisted_mac_softwares
      whitelisted_windows_softwares
      proview_token
      quiz_password
      proctor_instructions
      password_injection_enabled
      overduehandling
      organization_id
      minimize_permitted
      hash
      graceperiod
      file_sync_id
      external_course_id
      created_at
      course_name
      course_module_item_id
      candidate_instructions
      blacklisted_windows_softwares
      blacklisted_mac_softwares
      attendee_sync_status_updated_at
      attendee_sync_status
      proview_event_id
      quiz_attendee_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const UPDATE_PROVIEW_EVENT_ID = gql`
  mutation UpdateProviewEventId($eventId: Int!, $quizId: Int!) {
    update_lms_quiz(_set: { proview_event_id: $eventId }, where: { id: { _eq: $quizId } }) {
      affected_rows
    }
  }
`;

export const GET_ATTENDEES = gql`
  query MyQuery($quiz_id: Int = 10, $search: String) {
    lms_quiz_attendee_aggregate(
      where: {
        quiz_id: { _eq: $quiz_id }
        quiz_attendee_attendee: { email: { _ilike: $search } }
        is_deleted: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    lms_quiz_attendee(
      where: {
        quiz_id: { _eq: $quiz_id }
        quiz_attendee_attendee: { email: { _ilike: $search } }
        is_deleted: { _eq: false }
      }
    ) {
      quiz_attendee_attendee {
        first_name
        last_name
        email
        external_id
      }
      id
      quiz_attendee_quiz {
        external_id
      }
    }
  }
`;

export const GET_ORGANIZATION_CONFIG = gql`
  query GetOrganizationConfig($organizationId: Int!) {
    lms_organization(where: { proview_organization_id: { _eq: $organizationId } }) {
      id,
      lms_config
    }
  }
`;
