import React, {useState} from 'react';
import AttendeeView from './AttendeeView';
import Button from '../../../common/components/Button';
import QuizHeaderView from './QuizHeaderView';
import QuizBodyView from './QuizBodyView';

const QuizDetailView = ({quiz, onClose}) => {
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  return (
    <div>
      <div className="flex items-center mb-4">
        <Button
          className={'text-black hover:text-blue-400'}
          onClick={onClose}
          label={'All Quizzes'}
        />
        <span> &nbsp; &gt; </span>
        <h2 className="ml-2">Detailed View of Quiz</h2>
      </div>
      <div className="flex flex-col h-screen">
        <div className="bg-white rounded-lg shadow-md">
          <QuizHeaderView
            quiz={quiz}
            isScheduleButtonDisabled={
              !selectedAttendees.length || quiz.proctoring_type !== 'live_proctor'
            }
            selectedAttendees={selectedAttendees}
          />
          <QuizBodyView quiz={quiz}/>
        </div>
        <div className="bg-white rounded-lg shadow-md mt-2">
          <AttendeeView
            quiz={quiz}
            setSelectedAttendees={setSelectedAttendees}
            selectedAttendees={selectedAttendees}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizDetailView;
