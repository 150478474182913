import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useGetRecomputedProviewIndexMutation } from 'common/api/post-session';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { setUpdateproviewRatingStale } from 'common/slice/playbackVideoSlice';
import { SessionScope } from 'globals/enums';

interface Props {
  sessionType: string;
}

const RecalculateIndex = ({ sessionType }: Props) => {
  const dispatch = useAppDispatch();
  const [getRecomputedIndex, { isLoading }] = useGetRecomputedProviewIndexMutation({});
  const proviewRatingStale = useAppSelector((state) => state.playbackVideo.proviewRatingStale);
  const { session_uuid } = useParams();

  const handleRecalculateIndex = async () => {
    await getRecomputedIndex({
      session_uuid,
    });
    dispatch(setUpdateproviewRatingStale(false));
  };

  return (
    session_uuid && (
      <div
        className={`mt-auto ${
          sessionType === SessionScope.RecordAndReview ? 'px-2 py-4 w-fit' : 'p-4 w-full'
        }  flex justify-center`}
      >
        <button
          disabled={isLoading || !proviewRatingStale}
          onClick={handleRecalculateIndex}
          className={`flex items-center justify-center ${
            sessionType === SessionScope.RecordAndReview ? 'text-xs' : 'text-sm'
          }  w-full text-blue-700 rounded p-2 disabled:cursor-not-allowed disabled:opacity-70 border-[1.5px] border-blue-500`}
        >
          {isLoading ? (
            <div className="flex justify-center items-center gap-2">
              <ArrowPathIcon className="animate-spin h-4 w-4" />
              <span>Recalculating...</span>
            </div>
          ) : (
            'Recalculate Proview Index'
          )}
        </button>
      </div>
    )
  );
};

export default RecalculateIndex;
