import { processAlerts } from "../attendeeStepTable/processAttendeeStepData";
import { Alert } from "../attendeeStepTable/getStepStatus";

export const getDisconnectedCount = (sessions) => {
  let disconnectedPrimaryCamCount = 0;
  let disconnectedSecondaryCamCount = 0;

  sessions.forEach(session => {
    const alertCounts = countAlerts(processAlerts(session));

    if (isDisconnected(alertCounts[Alert.PrimaryCameraDisconnected], alertCounts[Alert.PrimaryCameraConnected])) {
      disconnectedPrimaryCamCount++;
    }

    if (isDisconnected(alertCounts[Alert.SecondaryCameraDisconnected], alertCounts[Alert.SecondaryCameraConnected])) {
      disconnectedSecondaryCamCount++;
    }
  });

  return { disconnectedPrimaryCamCount, disconnectedSecondaryCamCount };
};

export const countAlerts = (alerts) => {
  return alerts.reduce((counts, alert) => {
    counts[alert.alert_type_id]++;
    return counts;
  }, {
    [Alert.PrimaryCameraDisconnected]: 0,
    [Alert.SecondaryCameraDisconnected]: 0,
    [Alert.PrimaryCameraConnected]: 0,
    [Alert.SecondaryCameraConnected]: 0
  });
};

const isDisconnected = (disconnectedCount, connectedCount) => {
  return disconnectedCount >= connectedCount;
};
