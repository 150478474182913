import Button from 'common/components/Button';

type Props = {
  onApproval: () => void;
  onNotify: () => void;
  isLoading: boolean;
};

const PrecheckActions = ({ onApproval, onNotify, isLoading }: Props) => {
  return (
    <div className="flex items-center gap-1.5">
      <Button
        className="secondary-btn"
        isLoading={isLoading}
        onClick={() => onNotify()}
        label="Notify"
      />
      <Button
        className="primary-btn"
        onClick={() => onApproval()}
        label="Approve"
        isLoading={isLoading}
      />
    </div>
  );
};

export default PrecheckActions;
