import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { useUpdateStatusMutation } from 'common/api/session';
import { closeModal } from 'common/slice/modalSlice';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { SessionStatus } from 'globals/enums';

interface Props {
  attendeeId: number;
}

const NoShowModal = ({ attendeeId }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [updateStatus] = useUpdateStatusMutation();

  const updateSessionStatus = async () => {
    dispatch(closeModal({}));
    await updateStatus({
      proctor_event_id: searchParams.get('event_id'),
      attendee_id: attendeeId,
      slot_id: +searchParams.get('slot_id'),
      status: SessionStatus.NoShow,
    });
  };

  return (
    <div className="flex gap-2">
      <span className="w-10 h-10 bg-red-200 text-red-600 rounded-full flex p-2">
        <NoSymbolIcon />
      </span>
      <div className="flex flex-col mt-1.5">
        <p className="font-bold text-lg">Mark Candidate as No-Show?</p>
        <p className="mb-4 text-zinc-500">
          You are marking this candidate as No-Show for this session
        </p>
        <div className="ml-auto flex gap-1 text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button className="primary-btn" onClick={updateSessionStatus}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoShowModal;
