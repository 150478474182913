import React from 'react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import Button from './Button';

interface Props {
  title: string | React.ReactNode;
  content: any;
  isOpen?: boolean;
  onClick: () => void;
}

const Accordion = ({ title, content, isOpen, onClick }: Props) => {
  return (
    <div className="text-sm mb-2">
      <div
        className="cursor-pointer flex items-center justify-between bg-slate-300 p-2"
        onClick={onClick}
      >
        <p>{title}</p>
        <Button
          className="text-slate-500"
          icon={
            isOpen ? (
              <MinusCircleIcon className="h-5 w-5" />
            ) : (
              <PlusCircleIcon className="h-5 w-5" />
            )
          }
        />
      </div>
      <div className={!isOpen ? 'hidden' : 'w-full'}>{content}</div>
    </div>
  );
};

export default Accordion;
