import React, { useContext } from 'react';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import ProgressBar from 'common/components/ProgressBar';
import {getDisconnectedCount} from "./getDisconnectedCount";

const NotConnected: React.FC = () => {
  const {sessions} = useContext<DashboardContextType>(DashboardContext);
  const filteredSessions = sessions.filter((session) => !['completed', 'terminated'].includes(session.session_status));
  const {disconnectedPrimaryCamCount,disconnectedSecondaryCamCount}=getDisconnectedCount(filteredSessions);
  const disconnectedPrimaryCamPercentage = Math.round(
    (disconnectedPrimaryCamCount / filteredSessions.length) * 100,
  );
  const disconnectedSecondaryCamPercentage = Math.round(
    (disconnectedSecondaryCamCount / filteredSessions.length) * 100,
  );
  const progressBarData = [
    {
      label: 'Primary Device',
      color: 'red',
      value: `${disconnectedPrimaryCamCount || 0}`,
      percentage: disconnectedPrimaryCamPercentage,
    },
    {
      label: 'Secondary Device',
      color: 'red',
      value: `${disconnectedSecondaryCamCount || 0}`,
      percentage: disconnectedSecondaryCamPercentage,
    },
  ];
  return (
    <div className="flex flex-col space-y-6 p-2">
      {progressBarData.map(({ label, color, value, percentage }) => (
        <ProgressBar key={label} label={label} color={color} value={value} percentage={percentage} />
      ))}
    </div>
  );
};

export default NotConnected;
