import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import ChatMessages from '../chat/ChatMessages';
import { useGetChatHistoryQuery } from 'common/api/post-session-embed';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';

const ChatTab = ({ session_uuid }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedSearchIndex, setSelectedSearchIndex] = useState<number>(0);
  const { data, isLoading, isSuccess } = useGetChatHistoryQuery({
    session_uuid: session_uuid,
  });

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setSelectedSearchIndex(0);
  };
  const escapeRegExp = (str) => {
    return str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const filteredMessages = useMemo(() => {
    const history = get(data, 'history');
    if (!history || !searchQuery) return [];

    const searchQueryLower = searchQuery.toLowerCase();

    return history.reduce((filtered, message) => {
      const messageText = get(message, 'text', '').replace(/<[^>]*>/g, '');
      const messageTextLower = messageText.toLowerCase();

      if (messageTextLower.includes(searchQueryLower)) {
        const occurrences = (
          messageTextLower.match(new RegExp(escapeRegExp(searchQueryLower), 'g')) || []
        ).length;
        filtered.push({ ...message, occurrences });
      }

      return filtered;
    }, []);
  }, [data?.history, searchQuery]);

  const selectedIndexArray = useMemo(() => {
    if (!filteredMessages || !Array.isArray(filteredMessages)) return [];

    const occurrencesArray = [];
    let index = 0;
    filteredMessages?.forEach((message) => {
      const occurrences = get(message, 'occurrences', 0);
      for (let i = 0; i < occurrences; i++) {
        occurrencesArray.push(index);
      }
      index++;
    });
    return occurrencesArray;
  }, [filteredMessages]);

  const clearSearch = () => {
    setSelectedSearchIndex(0);
    setSearchQuery('');
  };

  const handleIndexChange = (increment) => {
    setSelectedSearchIndex((prevIndex) => {
      const newIndex = prevIndex + increment;
      if (newIndex < 0) {
        return selectedIndexArray?.length - 1;
      } else if (newIndex >= selectedIndexArray?.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
  };

  return (
    <>
      {!isLoading && isSuccess && (
        <div className="relative flex flex-col w-full h-full overflow-auto">
          <div className="flex items-stretch gap-2 p-3">
            <div className="relative items-center flex-1">
              <input
                type="text"
                name="search"
                id="search"
                value={searchQuery}
                onChange={handleSearchInputChange}
                className="block w-full py-2 pl-2 text-gray-900 border-0 rounded-md shadow-sm pr-14 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              />
              {isEmpty(searchQuery) ? (
                <div className="absolute inset-y-0 right-0 pr-3 top-2">
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </div>
              ) : filteredMessages?.length > 0 ? (
                <>
                  <div className="absolute inset-y-0 right-0 bottom-auto flex items-center pr-3 top-1">
                    <div className="text-xs">
                      {selectedSearchIndex + 1}/{selectedIndexArray?.length}
                    </div>
                    <div className="flex p-2">
                      <ChevronUpIcon
                        data-testid="chevron-up"
                        className="w-4 h-4 cursor-pointer"
                        onClick={() => handleIndexChange(-1)}
                      />
                      <div className="pl-2">
                        <ChevronDownIcon
                          data-testid="chevron-down"
                          className="w-4 h-4 cursor-pointer"
                          onClick={() => handleIndexChange(1)}
                        />
                      </div>
                    </div>
                    <XMarkIcon
                      data-testid="x-mark-icon"
                      onClick={clearSearch}
                      className="w-6 h-6 cursor-pointer"
                      color="#CBD5E1"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="absolute inset-y-0 right-0 bottom-auto flex items-center pr-3 top-2">
                    <XMarkIcon
                      data-testid="x-mark-icon2"
                      onClick={clearSearch}
                      className="w-6 h-6 cursor-pointer"
                      color="#CBD5E1"
                    />
                  </div>
                  <div className="px-1 text-xs text-gray-400"> No results found</div>
                </>
              )}
            </div>
          </div>
          <div className="overflow-auto">
            <ChatMessages
              chatMessages={data?.history}
              searchQuery={searchQuery}
              searchedMessages={filteredMessages}
              selectedMessageIndex={selectedIndexArray?.[selectedSearchIndex] ?? null}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatTab;
