import React from 'react';
import { ProctoringWrapperInvite, reference_link } from './types';
import { generateCardData } from './assessmentDetailHelpers';
import {
  InformationCircleIcon,
  GlobeAltIcon,
  DocumentTextIcon,
  ShieldCheckIcon,
  EyeIcon,
} from '@heroicons/react/24/solid';
import Card from './Card';

interface AssessmentDetailViewProps {
  assessments: ProctoringWrapperInvite;
  onClose: () => void;
}

const AssessmentDetailView: React.FC<AssessmentDetailViewProps> = ({ assessments, onClose }) => {
  const [copiedField, setCopiedField] = React.useState<string | null>(null);

  const formatValue = (value: any): React.ReactNode => {
    if (value === undefined || value === null || value === '') {
      return '--';
    }
    if (typeof value === 'boolean') {
      return formatTag(value);
    }
    return value;
  };

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopiedField(value);
    });
  };

  const renderUrl = (url: string | undefined) => {
    return url ? (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:underline"
      >
        {url}
      </a>
    ) : (
      '--'
    );
  };

  const isValidReferenceLinks = (reference_links: reference_link[]) => {
    return (
      reference_links?.length &&
      reference_links?.every((link) => {
        return typeof link.url === 'string' && typeof link.caption === 'string';
      })
    );
  };

  const formatTag = (isEnabled: boolean | undefined) => {
    if (isEnabled === undefined) return '--';
    return isEnabled ? (
      <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">
        Enabled
      </span>
    ) : (
      <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">
        Disabled
      </span>
    );
  };

  const cardData = generateCardData(assessments);

  const cards = [
    {
      title: assessments?.configurations?.session_title || 'Quiz Details',
      icon: <InformationCircleIcon className="h-6 w-6 mr-2 text-blue-600" />,
      id: 'basic-info',
    },
    {
      title: 'Proctoring Information',
      icon: <EyeIcon className="h-6 w-6 mr-2 text-blue-600" />,
      id: 'proctoring-info',
    },
    {
      title: 'Access Information',
      icon: <GlobeAltIcon className="h-6 w-6 mr-2 text-blue-600" />,
      id: 'access-info',
      handleCopy,
    },
  ];

  return (
    <div className="bg-gray-50 p-6 w-full">
      <div className="max-w-5xl mx-auto">
        <button
          onClick={onClose}
          className="flex items-center text-blue-600 hover:text-blue-800 mb-6"
        >
          <span className="h-5 w-5 mr-2">←</span>
          Back to All Links
        </button>

        {cards.map((card) => (
          <Card
            key={card.id}
            title={card.title}
            icon={card.icon}
            fields={cardData.find((c) => c.id === card.id)?.fields || []}
            formatValue={formatValue}
            handleCopy={card.handleCopy}
          />
        ))}

        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h3 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
            <ShieldCheckIcon className="h-6 w-6 mr-2 text-blue-600" />
            Secure Browser Configurations
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { label: 'Secure Browser', value: assessments?.configurations?.is_secure_browser },
              {
                label: 'Screen Minimize',
                value: assessments?.configurations?.secure_browser?.is_minimize,
              },
              {
                label: 'Screen Record',
                value: assessments?.configurations?.secure_browser?.is_record_screen,
              },
            ].map((item) => (
              <div key={item.label} className="flex">
                <div className="text-sm font-medium text-gray-800">{item.label}:</div>
                <div className="ml-2 text-sm text-gray-800">{formatTag(item.value)}</div>
              </div>
            ))}
          </div>
          <div className="p-2 mt-6">
            <div className="text-sm font-medium text-gray-600">Blacklisted Softwares</div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              {['Mac', 'Windows'].map((os) => (
                <div key={os}>
                  <h4 className="font-semibold text-gray-700 mb-2">{os}:</h4>
                  <ul className="list-disc list-inside ml-2 text-gray-700">
                    {assessments?.configurations?.secure_browser?.[
                      `blacklisted_softwares_${os.toLowerCase()}`
                    ]?.map((software: string) => <li key={software}>{software}</li>) || <li>--</li>}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h3 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
            <DocumentTextIcon className="h-6 w-6 mr-2 text-blue-600" />
            Additional Information
          </h3>
          <div className="mb-4">
            <h4 className="font-semibold text-gray-700 mb-2">Reference Links:</h4>
            <div>
              {isValidReferenceLinks(assessments?.configurations?.reference_links) ? (
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  {assessments?.configurations?.reference_links?.map((link: reference_link) => (
                    <li key={link.caption}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {link.caption}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                '--'
              )}
            </div>
          </div>
          <div>
            <h4 className="font-semibold text-gray-800 mb-2">Additional Instructions:</h4>
            <p className="text-gray-700 whitespace-pre-wrap">
              {formatValue(assessments?.configurations?.additional_instruction)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentDetailView;
