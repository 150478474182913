import React from 'react';
import Checkbox from 'common/components/Checkbox';
import EmptyState from 'common/components/EmptyState';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import DetailedHeader from 'common/headers/DetailedHeader';
import classNames from 'classnames';
import { Proctor } from "../../../../hooks/useDashboardParticipants";
import { Slot } from "../../../../hooks/useDashboardSlots";
import { useOnlineProctors } from "../../../../hooks/useOnlineProctors";
export const handleProctorSelection = ( currentProctors, proctors, userId, setCurrentProctors ) => {
  const selected = currentProctors.find((proctor) => proctor.id === userId);
  if (selected) {
    const newData = currentProctors.filter((proctor) => proctor.id !== userId);
    setCurrentProctors(newData);
  } else {
    setCurrentProctors([...currentProctors, proctors.find((proctor) => proctor.id === userId)]);
  }
};

export const calculateOnlineProctorsCount = ( onlineProctors ) => {
  let onlineProctorsCount = 0;
  for (const key in onlineProctors) {
    if (onlineProctors?.[key] === true) {
      onlineProctorsCount++;
    }
  }
  return onlineProctorsCount;
};

export const statusIndicatorClass = (proctorId, onlineProctors) => {
  return onlineProctors[proctorId] ? 'bg-green-500' : 'bg-red-500';
};

type ProctorListProps = {
  proctors: Proctor[];
  currentProctors: Proctor[];
  setCurrentProctors: React.Dispatch<React.SetStateAction<Proctor[]>>;
  currentSlot: Slot;
};

const ProctorList: React.FC<ProctorListProps> = (props) => {
  const { proctors, currentProctors, setCurrentProctors, currentSlot } = props;
  const onlineProctors = useOnlineProctors(currentSlot.id);
  if (!proctors || !proctors.length) {
    return <EmptyState description="No data found" icon={<UserCircleIcon className="h-5 w-5" />} />;
  }

  const onlineProctorsCount = calculateOnlineProctorsCount(onlineProctors);

  return (
    <div className="flex flex-col">
      <DetailedHeader totalProctors={ proctors.length } onlineProctors={ onlineProctorsCount } />
      {proctors.map((proctor) => (
        <div key={proctor.id} className="flex flex-row justify-between items-center w-full p-1 hover:cursor-pointer">
          <div className={classNames('p-1 flex flex-col rounded-full mr-2', statusIndicatorClass(proctor.id, onlineProctors))}></div>
          <div className="flex flex-col w-full truncate">
            <div title={proctor.email} className="text-[12px] font-semibold">{proctor.email}</div>
          </div>
          <div className="flex flex-col items-end">
            <Checkbox
              id={proctor.id}
              name={proctor.username}
              size="sm"
              onChange={(event) => handleProctorSelection(currentProctors, proctors, proctor.id, setCurrentProctors)}
              checked={currentProctors.filter((cp) => cp.id === proctor.id).length > 0}
              disabled={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProctorList;
