import { createSlice } from '@reduxjs/toolkit';
import { TABS } from 'common/components/constant';

export const initialState = {
  tab: Object.values(TABS)[0],
};

const activeTabSlice = createSlice({
  name: 'activeAttendee',
  initialState,
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.tab = payload;
    },
  },
});

export const { setActiveTab } = activeTabSlice.actions;

export default activeTabSlice.reducer;
