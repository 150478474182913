import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Session } from 'globals/interfaces';

export const initialState = {
  sessions: [],
  isSuccess: false,
};

const sessionListSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    startSession: (state, { payload }) => {
      return state;
    },
    updateSession: (state, { payload }: PayloadAction<Session[]>) => {
      state.sessions = payload;
      state.isSuccess = true;
      return state;
    },
  },
});

export const { startSession, updateSession } = sessionListSlice.actions;

export const selectSessionById = (state, sessionId) => {
  return state.sessions.find((session) => session.session_id === sessionId);
};

export const selectConfigurationBySessionId = (state, sessionId) =>{
  return selectSessionById(state, sessionId)?.configuration;
}

export default sessionListSlice.reducer;
