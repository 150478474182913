import { scaleTime } from 'd3-scale';
import AlertFlag from './AlertFlag';
import SliderIcon from 'assets/slider-icon.svg';
import { Alert } from 'globals/interfaces';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setCurrentPlayingTimestamp } from 'common/slice/playbackVideoSlice';
import dayjs from 'dayjs';
import { useRef } from 'react';

const Seekbar = ({ alerts, startTimestamp, endTimestamp, width, currentPlayingTime }) => {
  const dispatch = useAppDispatch();
  const seekbarSliceRef = useRef(null);

  const scale = scaleTime()
    .domain([new Date(startTimestamp), new Date(endTimestamp)])
    .range([0, width]);

  const handleSeekbarClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const { clientX } = e;
    const { left: sliceRectLeft } = seekbarSliceRef.current.getBoundingClientRect();
    const timestamp = scale.invert(clientX - sliceRectLeft);
    dispatch(
      setCurrentPlayingTimestamp({
        requestedTimestamp: timestamp
      }),
    );
  };

  const isSliderAvailable = dayjs(currentPlayingTime).isBetween(
    startTimestamp,
    endTimestamp,
    'second',
    '[]',
  );
  return (
    <div
      ref={seekbarSliceRef}
      style={{
        width: `${width}px`,
      }}
      data-testid="seekbar"
      onClick={handleSeekbarClick}
      className="relative h-full"
    >
      {alerts.map((alert: Alert) => (
        <AlertFlag
          key={alert.id}
          alert_type_id={alert.alert_type_id}
          position={`${scale(new Date(alert.timestamp || alert.updated_at))}px`}
          severity={alert.severity}
          timestamp={alert.timestamp || alert.updated_at}
        />
      ))}
      {isSliderAvailable && (
        <img
          src={SliderIcon}
          style={{
            left: `${scale(new Date(currentPlayingTime))}px`,
          }}
          alt="slider icon"
          className={`absolute top-1/2 -translate-y-2/4 z-50 left-[0px] transition-all duration-500 min-w-[14px]`}
        />
      )}
    </div>
  );
};

export default Seekbar;
