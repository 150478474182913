import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { useGetPostSessionQuery } from 'common/api/post-session-embed';
import VideoContainer from './VideoContainer';
import Header from 'common/components/Header';
import ActionModal from 'common/components/ActionModal';
import TabPane from './TabPane';
import MediagrabReel from 'common/components/MediagrabReel';
import { setIsEmbeddedView } from 'common/slice/playbackVideoSlice';
import { PLAYBACKTABS } from 'common/components/constant';
import { isUndefined } from 'lodash';
import { updateBreadcrumb } from 'common/slice/breadcrumbsSlice';

const PlaybackEmbed = () => {
  const dispatch = useAppDispatch();
  const { session_uuid } = useParams();
  const { data, isLoading } = useGetPostSessionQuery(
    { session_uuid },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
      dispatch(setIsEmbeddedView({ isEmbeddedView: true }));
  }, [dispatch]);

  useEffect(() => {
    if(!isUndefined(data)){
      const payload = [];
      payload.push({
        label: data?.attendee?.external_attendee_id,
        url: '',
      });
      dispatch(updateBreadcrumb(payload));
    }
  }, [data, dispatch])

  return (
    <div className="flex flex-col h-screen">
      <Header />
      {isLoading && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full">
          <span className="flex items-center justify-center" role="spinbutton">
            <span className="w-6 h-6 border-2 border-gray-400 rounded-full animate-spin border-r-transparent"></span>
          </span>
        </div>
      )}
      {data && (
        <div className="flex flex-grow h-full overflow-hidden bg-black-50">
          <div className="flex flex-col w-9/12 h-full gap-2 px-4 pt-4 pb-2 overflow-auto">
            <VideoContainer
              isRecordingAvailable={data?.isRecordingAvailable}
              alerts={data?.alerts}
              recordings={data?.recordings}
              streaming_provider={data?.streaming_provider}
              availableSettings={data?.availableSettings}
            />
            <MediagrabReel sessionFiles={data?.session_files} />
          </div>
          <div className="flex flex-col flex-grow w-3/12 border-l-2 bg-slate-100">
            <TabPane
              tabs={Object.values(PLAYBACKTABS)}
              sessions={Array(data)}
              attendeeId={data?.attendee_id}
            />
          </div>
          <ActionModal />
        </div>
      )}
    </div>
  );
};

export default PlaybackEmbed;
