// ProtectedRoute.tsx

import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import jwtDecode from 'jwt-decode';
import { setCurrentUser } from 'common/slice/currentUserSlice';
import { getDatabase, ref, set, onDisconnect } from "firebase/database";
import {useFirebaseApp} from "reactfire";

interface User {
  user: {
    id: number;
    email: string;
    external_id?: string;
    organization_id: number;
    username: string;
  };
  'https://hasura.io/jwt/claims'?: {
    'x-hasura-allowed-roles'?: string[];
  };
}

const ProtectedRoute = () => {
  const app = useFirebaseApp();
  const urlParams = new URLSearchParams(window.location.search);
  const slot_id = urlParams.get('slot_id');
  const dispatch = useAppDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const stored = localStorage.getItem('live-proctor-session');
    const { access_token } = stored ? JSON.parse(stored) : { access_token: null };
    if (access_token) {
      const decodedToken: User = jwtDecode(access_token);
      const currentUser = {
        id: decodedToken.user.id,
        email: decodedToken.user.email,
        external_id: decodedToken.user.external_id,
        organization_id: decodedToken.user.organization_id,
        username: decodedToken.user.username,
        roles: decodedToken['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles'] || [],
      };
      if(slot_id) {
        const reference = ref(getDatabase(app), `proctor_presence/${slot_id}/${decodedToken.user.id}`);
        set(reference, true);
        onDisconnect(reference).set(false);
      }
      setIsAuthenticated(true);
      dispatch(setCurrentUser(currentUser));
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return isAuthenticated !== null && (isAuthenticated ? <Outlet /> : <Navigate to="auth-error" />);
};

export default ProtectedRoute;
