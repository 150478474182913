import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionStatus } from 'globals/enums';
import postSessionApi from 'common/api/post-session';
import sessionApi from 'common/api/session';
import postSessionEmbedApi from 'common/api/post-session-embed';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { RecordingTypes } from 'globals/enums';
import { Alert } from 'globals/interfaces';
import {
  getIsTimestampWithinAlerts,
  getNextGroupedAlertsByTimestamp,
  getSortedAlertsByTimestamp,
  getVideoPlaybackAlerts,
} from 'helpers/playback';
dayjs.extend(isBetween);

interface PlaybackVideoState {
  timelineActive: boolean;
  videoControl: boolean;
  volume: number;
  activeVideoFeed: string;
  currentPlayingTime: string;
  attendeeId: number | null;
  sessionId: string | null;
  proviewRating: string;
  isEmbeddedView: boolean;
  startTimestamp: string;
  endTimestamp: string;
  requestedTimeStamp: string;
  proviewRatingStale: boolean;
  isReviewed: boolean;
  playbackSpeed: number;
}

export const initialState: PlaybackVideoState = {
  timelineActive: false,
  videoControl: false,
  volume: 0,
  activeVideoFeed: RecordingTypes.Primary,
  currentPlayingTime: '',
  attendeeId: null,
  sessionId: '',
  proviewRating: '',
  isEmbeddedView: false,
  startTimestamp: '',
  endTimestamp: '',
  requestedTimeStamp: '',
  proviewRatingStale: false,
  isReviewed: false,
  playbackSpeed: 1,
};

const playbackVideo = createSlice({
  name: 'playbackVideo',
  initialState,
  reducers: {
    setActiveVideoFeed: (state, { payload }: PayloadAction<string>) => {
      state.activeVideoFeed = payload;
    },
    setActiveVideoVolume: (state, { payload }: PayloadAction<number>) => {
      state.volume = payload;
    },
    toggleTimelineActive: (state, { payload }: PayloadAction<null>) => {
      state.timelineActive = !state.timelineActive;
      state.requestedTimeStamp = state.currentPlayingTime;
    },
    updateVideoControl: (state, { payload }: PayloadAction<boolean>) => {
      state.videoControl = payload;
    },
    updateCurrentPlayingTime: (
      state,
      { payload: { groupedAlerts, elapsedTime } }: PayloadAction<any>,
    ) => {
      if(!state.currentPlayingTime) return;
      const newPlayingTime = dayjs(state.currentPlayingTime).add(elapsedTime, 'millisecond');
      const isTimestampWithinAlerts = getIsTimestampWithinAlerts(groupedAlerts, newPlayingTime);

      if (isTimestampWithinAlerts) {
        state.currentPlayingTime = newPlayingTime.toISOString();
      } else {
        const nextAlertGroup = getNextGroupedAlertsByTimestamp(groupedAlerts, newPlayingTime);
        if (nextAlertGroup?.length) {
          state.currentPlayingTime = nextAlertGroup[0].timestamp || nextAlertGroup[0].updated_at;
        } else {
          state.currentPlayingTime = groupedAlerts.flat()[0].timestamp;
          state.timelineActive = false;
        }
      }
    },
    setCurrentPlayingTimestamp: (
      state,
      {
        payload,
      }: PayloadAction<{
        requestedTimestamp: string;
      }>,
    ) => {
      state.requestedTimeStamp = new Date(payload.requestedTimestamp).toISOString();
      state.currentPlayingTime = new Date(payload.requestedTimestamp).toISOString();
    },
    setPlaybackVideoSpeed: (state, { payload }: PayloadAction<number>) => {
      state.playbackSpeed = payload;
    },
    setIsEmbeddedView: (state, { payload }) => {
      state.isEmbeddedView = payload.isEmbeddedView;
    },
    setUpdateproviewRatingStale: (state, { payload }) => {
      state.proviewRatingStale = payload;
    },
    resetPlaybackState: (state, { payload }: PayloadAction<null>) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      postSessionEmbedApi.endpoints.getPostSession.matchFulfilled,
      (state, action) => {
        const sortedAlerts: Alert[] = getSortedAlertsByTimestamp(action.payload.alerts)
        const updatedSession = { ...action.payload, alerts: sortedAlerts };
        state.timelineActive = false;
        state.sessionId = updatedSession.session_id;
        state.attendeeId = updatedSession.attendee.id;
        state.proviewRating = updatedSession.final_rating || '';
        state.currentPlayingTime = sortedAlerts.length ? sortedAlerts[0]?.timestamp : '';
        state.proviewRatingStale = updatedSession.proview_rating_stale;
        state.isReviewed = updatedSession.session_status === SessionStatus.Reviewed;
      },
    );
    builder.addMatcher(
      postSessionEmbedApi.endpoints.updateProviewIndex.matchFulfilled,
      (state, action) => {
        state.proviewRating = action.meta.arg.originalArgs.newRating;
      },
    );
    builder.addMatcher(
      postSessionApi.endpoints.getRecomputedProviewIndex.matchFulfilled,
      (state, { payload }) => {
        state.proviewRating = payload?.rating;
      },
    );
    builder.addMatcher(sessionApi.endpoints.updateStatus.matchFulfilled, (state, { meta }) => {
      if (meta.arg.originalArgs.status === SessionStatus.Reviewed) {
        state.isReviewed = true;
      }
    });
  },
});

export const {
  setActiveVideoFeed,
  setActiveVideoVolume,
  toggleTimelineActive,
  updateVideoControl,
  updateCurrentPlayingTime,
  setCurrentPlayingTimestamp,
  setPlaybackVideoSpeed,
  setIsEmbeddedView,
  setUpdateproviewRatingStale,
  resetPlaybackState,
} = playbackVideo.actions;

export default playbackVideo.reducer;
