import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import AttendeeCell from './AttendeeCell';
import { Tooltip } from 'react-tooltip';
import {
  BellIcon,
  CameraIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  CogIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  EyeIcon,
  IdentificationIcon,
  MicrophoneIcon,
  PhoneIcon,
  SpeakerWaveIcon,
  UserCircleIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';

export type Data = {
  id: string;
  attendee: string;
  proctor: string;
  proctorUserId: number;
  status: string;
  attendeeId: number;
  participantGroupId: number;
  primaryCamera: React.ReactNode;
  secondaryCamera: React.ReactNode;
  chat: React.ReactNode;
  call: React.ReactNode;
  verification: React.ReactNode;
  testEnv: React.ReactNode;
  idCard: React.ReactNode;
  photo: React.ReactNode;
  audioTest: React.ReactNode;
  screenShare: React.ReactNode;
  notificationPermission: React.ReactNode;
  webcamPermission: React.ReactNode;
  microphonePermission: React.ReactNode;
  slotId: number;
  eventId: number;
  attempts?: number;
  secondaryCameraSetup?: React.ReactNode;
  currentStep?: number;
  lastOnlineAlertTimestamp?: string;
};

const columnHelper = createColumnHelper<Data>();

export const columns = [
  columnHelper.accessor((row) => row.attendee, {
    id: 'attendee',
    cell: (info) => (
      <AttendeeCell
        details={info.row.original}
        status={info.row.original.status}
        name={info.getValue()}
      />
    ),
    header: () => <div className="truncate text-xs font-semibold w-40">Attendees</div>,
  }),
  columnHelper.accessor((row) => row.proctor, {
    id: 'proctor',
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <div className="truncate text-xs font-semibold w-40">Proctor</div>,
  }),
  columnHelper.accessor((row) => row.microphonePermission, {
    id: 'microphonePermission',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <MicrophoneIcon className="h-5 w-5" data-tooltip-id="microphonePermission" />
        <Tooltip className="z-[1000]" id="microphonePermission" place="top">
          Microphone Permission
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.webcamPermission, {
    id: 'webcamPermission',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <CameraIcon className="h-5 w-5" data-tooltip-id="webcamPermission" />
        <Tooltip className="z-[1000]" id="webcamPermission" place="top">
          Webcam Permission
        </Tooltip>
      </>
    ),
  }),

  columnHelper.accessor((row) => row.notificationPermission, {
    id: 'notificationPermission',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <BellIcon className="h-5 w-5" data-tooltip-id="notificationPermission" />
        <Tooltip className="z-[1000]" id="notificationPermission" place="top">
          Notification Permission
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.screenShare, {
    id: 'screenShare',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <ComputerDesktopIcon className="h-5 w-5" data-tooltip-id="screenShare" />
        <Tooltip className="z-[1000]" id="screenShare" place="top">
          Screen Share
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.secondaryCameraSetup, {
    id: 'secondaryCameraSetup',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <span className="relative" data-tooltip-id="secondaryCameraSetup">
          <DevicePhoneMobileIcon className="h-5 w-5" />
          <CogIcon className="h-4 w-4 absolute -top-2 -right-2 " />
        </span>
        <Tooltip className="z-[1000]" id="secondaryCameraSetup" place="top">
          Secondary Camera Setup
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.idCard, {
    id: 'idCard',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <IdentificationIcon className="h-5 w-5" data-tooltip-id="idCard" />
        <Tooltip className="z-[1000]" id="idCard" place="top">
          ID Card Capture
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.photo, {
    id: 'photo',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <UserCircleIcon className="h-5 w-5" data-tooltip-id="photo" />
        <Tooltip className="z-[1000]" id="photo" place="top">
          Face Capture
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.audioTest, {
    id: 'audioTest',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <SpeakerWaveIcon className="h-5 w-5" data-tooltip-id="audioTest" />
        <Tooltip className="z-[1000]" id="audioTest" place="top">
          Audio Test
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.testEnv, {
    id: 'testEnv',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <EyeIcon className="h-5 w-5" data-tooltip-id="testEnv" />
        <Tooltip className="z-[1000]" id="testEnv" place="top">
          Test Environment Submission
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.verification, {
    id: 'verification',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <CheckBadgeIcon className="h-5 w-5" data-tooltip-id="verification" />
        <Tooltip className="z-[1000]" id="verification" place="top">
          Verification
        </Tooltip>
      </>
    ),
  }),

  columnHelper.accessor((row) => row.chat, {
    id: 'chat',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <ChatBubbleLeftRightIcon className="h-5 w-5" data-tooltip-id="chat" />
        <Tooltip className="z-[1000]" id="chat" place="top">
          Chat
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.call, {
    id: 'call',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <PhoneIcon className="h-5 w-5" data-tooltip-id="call" />
        <Tooltip className="z-[1000]" id="call" place="top">
          Call
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.primaryCamera, {
    id: 'primaryCamera',
    cell: (info) => info.getValue(),
    header: () => (
      <div className="min-w-10">
        <VideoCameraIcon className="h-5 w-5" data-tooltip-id="primaryCamera" />
        <Tooltip className="z-[1000]" id="primaryCamera" place="top">
          Primary Camera
        </Tooltip>
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.secondaryCamera, {
    id: 'secondaryCamera',
    cell: (info) => info.getValue(),
    header: () => (
      <>
        <DevicePhoneMobileIcon className="h-5 w-5" data-tooltip-id="secondaryCamera" />
        <Tooltip className="z-[1000]" id="secondaryCamera" place="top">
          Secondary Camera
        </Tooltip>
      </>
    ),
  }),
  columnHelper.accessor((row) => row.attempts, {
    id: 'attempts',
    cell: (info) => info.getValue(),
    header: () => <div className="truncate text-xs font-bold w-40">Attempts</div>,
  }),
];
