import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

interface SASResponse {
  sas_token: string;
  sas_expiries_in: string;
  container_name: string;
}

const sasTokenApi = createApi({
  reducerPath: 'signedUrlApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createSASToken: builder.mutation({
      query: (payload) => ({
        url: `/v1/container-access/sas-token`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: SASResponse) => {
        return response.sas_token;
      },
    }),
  }),
});

export const { useCreateSASTokenMutation } = sasTokenApi;

export default sasTokenApi;
