import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlertList from './AlertList';
import RecalculateIndex from 'common/components/RecalculateIndex';
import { openModal } from 'common/slice/modalSlice';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Alerts } from 'globals/interfaces';
import { FilterBy, SessionScope, SessionStatus } from 'globals/enums';
import MarkReview from 'common/components/MarkReview';
import { get } from 'lodash';
import { getSessionCompletedTimestamp } from 'helpers/playback';
import { resetFilterBy, setFilterBy } from 'common/slice/alertFilterSlice';

interface Props {
  alerts: Alerts;
  attendeeId: number;
  sessionUuid: string;
  sessionId: string;
  sessionStatus: string;
  slotEndDate: string;
  slotStartDate: string;
  sessionType?: string;
  sessionJoinedAt?: string;
  sessionCompletedAt?: string;
}

const AlertTab = memo(
  ({
    alerts,
    attendeeId,
    sessionUuid,
    sessionId,
    sessionStatus,
    slotStartDate,
    slotEndDate,
    sessionType,
  }: Props) => {
    const dispatch = useAppDispatch();
    const [inputText, setInputText] = useState('');
    const [viewBy, setViewBy] = useState('timeline');
    const isEmbeddedView = useAppSelector((state) => state.playbackVideo.isEmbeddedView);
    const filterBy = useAppSelector((state) => state.alertFilter.filterBy);
    const sessionCompletedAt = alerts && getSessionCompletedTimestamp(Object.values(alerts));
    const sessionJoinedAt = alerts && get(Object.values(alerts), '[0].timestamp');

    const handleAddFlag = () => {
      dispatch(
        openModal({
          attendeeId,
          type: 'add-flag',
          sessionUuid,
          sessionId,
          slotStartDate,
          slotEndDate,
          sessionJoinedAt,
          sessionCompletedAt,
        }),
      );
    };

    const handleFilterByClick = (newValue: string) => {
      dispatch(setFilterBy(newValue));
    };

    useEffect(() => {
      if (!filterBy.includes(FilterBy.Critical)) {
        dispatch(resetFilterBy());
      }
    }, [attendeeId]);

    return (
      sessionId &&
      sessionStatus !== SessionStatus.NoShow && (
        <>
          <div className="relative flex flex-col w-full overflow-auto">
            <div className="flex items-stretch gap-2 p-3">
              <div className="relative flex items-center flex-1">
                <input
                  type="text"
                  name="search alert"
                  id="searchAlert"
                  value={inputText}
                  className="w-full py-2 pl-2 text-gray-900 border-0 rounded-md shadow-sm pr-14 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                  onChange={(e) => setInputText(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </div>
              </div>
              {!isEmbeddedView && (
                <div>
                  <button
                    type="button"
                    className="rounded-md h-full px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    style={{ backgroundColor: ' #2563EB' }}
                    onClick={() => handleAddFlag()}
                  >
                    Add Flag
                  </button>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between flex-wrap p-3 pt-0 align-middle">
              <div className="flex items-center gap-x-2 flex-wrap">
                <div className="flex items-center">View by</div>
                <div className="flex items-center">
                  <div className="flex items-center">
                    <input
                      id="timeline"
                      name="alert-group"
                      type="radio"
                      checked={viewBy === 'timeline'}
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                      onChange={() => setViewBy('timeline')}
                    />
                    <label className="block ml-1 text-sm font-medium leading-6 text-gray-900">
                      Timeline
                    </label>
                  </div>
                  <div className="flex items-center ml-2">
                    <input
                      id="type"
                      name="alert-group"
                      type="radio"
                      checked={viewBy === 'type'}
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                      onChange={() => setViewBy('type')}
                    />
                    <label className="block ml-1 text-sm font-medium leading-6 text-gray-900">
                      Type
                    </label>
                  </div>
                  <div className="flex items-center ml-3">
                    <input
                      id="severity"
                      name="alert-group"
                      type="radio"
                      checked={viewBy === 'severity'}
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                      onChange={() => setViewBy('severity')}
                    />
                    <label className="block ml-1 text-sm font-medium leading-6 text-gray-900">
                      Severity
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[2px] bg-slate-300 mx-3"></div>
            <div className="flex items-center justify-between p-3 align-middle">
              <div className="flex items-center gap-x-2 flex-wrap">
                <div className="flex items-center">Filter by</div>
                <div className="flex items-center">
                  <div className="flex items-center">
                    <input
                      id="critical"
                      name="alert-filter-group"
                      type="checkbox"
                      checked={filterBy.some((a) => a === FilterBy.Critical)}
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                      onChange={() => handleFilterByClick(FilterBy.Critical)}
                    />
                    <label
                      htmlFor="critical"
                      className="block ml-1 text-sm font-medium leading-6 text-gray-900"
                    >
                      Critical
                    </label>
                  </div>
                  <div className="flex items-center ml-2">
                    <input
                      id="warning"
                      name="alert-filter-group"
                      type="checkbox"
                      checked={filterBy.some((a) => a === FilterBy.Public)}
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                      onChange={() => handleFilterByClick(FilterBy.Public)}
                    />
                    <label
                      htmlFor="warning"
                      className="block ml-1 text-sm font-medium leading-6 text-gray-900"
                    >
                      Public
                    </label>
                  </div>
                  <div className="flex items-center ml-2">
                    <input
                      id="debug"
                      name="alert-filter-group"
                      type="checkbox"
                      checked={filterBy.some((a) => a === FilterBy.Debug)}
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                      onChange={() => handleFilterByClick(FilterBy.Debug)}
                    />
                    <label
                      htmlFor="debug"
                      className="block ml-1 text-sm font-medium leading-6 text-gray-900"
                    >
                      Debug
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <AlertList
              viewBy={viewBy}
              alerts={alerts}
              attendeeId={attendeeId}
              sessionUuid={sessionUuid}
              sessionId={sessionId}
              searchTerm={inputText ? inputText.toLowerCase().trim() : ''}
              clearSearch={() => setInputText('')}
              filterBy={filterBy}
            />
          </div>
          {!isEmbeddedView && (
            <div data-testid="recalculate-index-container" className="mt-auto">
              <div className="flex w-full bg-white justify-evenly">
                <RecalculateIndex sessionType={sessionType} />
                {sessionType === SessionScope.RecordAndReview && (
                  <MarkReview sessionId={sessionId} />
                )}
              </div>
            </div>
          )}
        </>
      )
    );
  },
);

export default AlertTab;
