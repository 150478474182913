import { Action, configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/query';
import proctorApi from 'common/api/proctor';
import authApi from 'common/api/auth';
import precheckApi from 'common/api/precheck';
import sasTokenApi from 'common/api/sasToken';
import firebaseCustomTokenApi from '../common/api/firebase-custom-token';
import alertApi from 'common/api/alert';
import audioCallTokenApi from 'common/api/audioCallToken';
import sessionApi from 'common/api/session';
import activeAttendee from 'common/slice/activeAttendeeSlice';
import activeTab from 'common/slice/activeTabSlice';
import mediaSettings from 'common/slice/mediaSettingSlice';
import audioSettings from 'common/slice/audioSettingSlice';
import session from 'common/slice/SessionSlice';
import breadcrumbs from 'common/slice/breadcrumbsSlice';
import audioCall from 'common/slice/audioCallSlice';
import currentUser from 'common/slice/currentUserSlice';
import modal from 'common/slice/modalSlice';
import playbackVideo from 'common/slice/playbackVideoSlice';
import alertFilter from 'common/slice/alertFilterSlice';
import audioCallListener from 'common/listener/audioCallListener';
import rtkQueryErrorLogger from 'common/middlewares/rtkQueryErrorLogger';
import mediaSettingListener from 'common/listener/mediaSettingsListener';
import sessionList from 'common/slice/sessionListSlice';
import sessionCallListener from 'common/listener/sessionCallListener';
import postSessionApi from 'common/api/post-session';
import postSessionEmbedApi from 'common/api/post-session-embed';
import playbackListener from 'common/listener/playbackListener';
import eventSchedulerApi from '../common/api/eventScheduler';
import lmsApi from '../common/api/lmsApi';
import lmsWrapperCreateApi from 'common/api/wrapper-create';
import proviewTokenApi from 'common/api/proviewTokenQuery';
import brighstpaceOauthApi from 'common/api/external/brighspaceOauth'

const middlewares = [
  proctorApi.middleware,
  authApi.middleware,
  precheckApi.middleware,
  sasTokenApi.middleware,
  firebaseCustomTokenApi.middleware,
  alertApi.middleware,
  audioCallTokenApi.middleware,
  audioCallListener.middleware,
  mediaSettingListener.middleware,
  sessionApi.middleware,
  postSessionApi.middleware,
  postSessionEmbedApi.middleware,
  sessionCallListener.middleware,
  playbackListener.middleware,
  lmsApi.middleware,
  lmsWrapperCreateApi.middleware,
  proviewTokenApi.middleware,
  eventSchedulerApi.middleware,
  brighstpaceOauthApi.middleware,
  rtkQueryErrorLogger,
];

const store = configureStore({
  reducer: {
    sessionList,
    activeAttendee,
    activeTab,
    mediaSettings,
    audioSettings,
    breadcrumbs,
    session,
    audioCall,
    currentUser,
    modal,
    playbackVideo,
    alertFilter,
    [lmsApi.reducerPath]: lmsApi.reducer,
    [proctorApi.reducerPath]: proctorApi.reducer,
    [alertApi.reducerPath]: alertApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [precheckApi.reducerPath]: precheckApi.reducer,
    [sasTokenApi.reducerPath]: sasTokenApi.reducer,
    [firebaseCustomTokenApi.reducerPath]: firebaseCustomTokenApi.reducer,
    [audioCallTokenApi.reducerPath]: audioCallTokenApi.reducer,
    [sessionApi.reducerPath]: sessionApi.reducer,
    [postSessionApi.reducerPath]: postSessionApi.reducer,
    [postSessionEmbedApi.reducerPath]: postSessionEmbedApi.reducer,
    [eventSchedulerApi.reducerPath]: eventSchedulerApi.reducer,
    [lmsWrapperCreateApi.reducerPath]: lmsWrapperCreateApi.reducer,
    [proviewTokenApi.reducerPath]: proviewTokenApi.reducer,
    [brighstpaceOauthApi.reducerPath]: brighstpaceOauthApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
