import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';
import { RecordingTypes } from 'globals/enums';

const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    updateStatus: builder.mutation({
      query: (payload) => ({
        url: `/v1/session/status`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    updateStatusReason: builder.mutation({
      query: (payload) => ({
        url: `/v1/session/${payload.session_id}`,
        method: 'PATCH',
        body: payload.data,
      }),
    }),
  }),
});

export const { useUpdateStatusMutation, useUpdateStatusReasonMutation } =
  sessionApi;

export default sessionApi;
