enum LocalStorageKeys {
  AccessToken = 'live-proctor-session',
}

export class LocalStorage {
  static setLoginResponse(data: string) {
    localStorage.setItem(LocalStorageKeys.AccessToken, data);
  }

  static getAccessToken(): string {
    return JSON.parse(localStorage.getItem('live-proctor-session') || '{}').access_token;
  }
}
