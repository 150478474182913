import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setActiveVideoFeed } from 'common/slice/playbackVideoSlice';
import { playbackSettingIcons } from 'common/components/constant';
import { useParams } from 'react-router-dom';
import { useGetPostSessionQuery } from 'common/api/post-session-embed';

const ActiveVideoSetting = () => {
  const dispatch = useAppDispatch();
  const { activeVideoFeed } = useAppSelector((state) => state.playbackVideo);
  const { session_uuid } = useParams();
  const { data } = useGetPostSessionQuery({
    session_uuid,
  });

  return (
    <div className="ml-4 flex items-center gap-4 w-8 h-7 p-2 rounded-2xl cursor-pointer z-10 overflow-hidden hover:w-fit">
      {data?.availableSettings.map((feed: string) => {
        const Icon = playbackSettingIcons[feed].Icon;
        return (
          <Icon
            data-testid="activeVideoSetting"
            key={feed}
            title={playbackSettingIcons[feed].title}
            className={`mx-auto h-4 w-4 min-w-max hover:stroke-white ${
              activeVideoFeed === feed
                ? 'stroke-white order-1 pointer-events-none'
                : 'stroke-slate-500 order-3'
            }`}
            onClick={() => dispatch(setActiveVideoFeed(feed))}
          />
        );
      })}
    </div>
  );
};

export default ActiveVideoSetting;
