import { useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { AlertSeverity } from 'globals/enums';
import { Alert } from 'globals/interfaces';

interface Props {
  alerts: Alert[];
}

const AlertToastContainer = ({ alerts }: Props) => {
  const currentPlayingTime = useAppSelector((state) => state.playbackVideo.currentPlayingTime);

  const renderToast = (alert: Alert) => {
    let styles = '';
    switch (alert.severity) {
      case AlertSeverity.Critical:
        styles = 'border-red-500';
        break;
      case AlertSeverity.High:
        styles = 'border-amber-500';
        break;
      case AlertSeverity.Medium:
      case AlertSeverity.Low:
      case AlertSeverity.None:
        styles = 'border-slate-500';
        break;
    }
    return (
      <div
        key={`${alert.id}_toast`}
        className={`${styles} animate-slide-in border-l-8 rounded bg-gray-800 w-fit p-2 mt-2 transition-all`}
      >
        {alert.name}
      </div>
    );
  };

  return (
    <div className="absolute bottom-[190px] left-4">
      {alerts.map((alert: Alert) => {
        if (
          dayjs(currentPlayingTime).isBetween(
            dayjs(alert.timestamp || alert.updated_at),
            dayjs(alert.timestamp || alert.updated_at).add(4, 'second'),
            'second',
            '[]',
          )
        ) {
          return renderToast(alert);
        }
      })}
    </div>
  );
};

export default AlertToastContainer;
