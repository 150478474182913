import React, { useRef } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

type Props = {
  id: string | number;
  name: string | number;
  size: 'sm' | 'md' | 'lg' | 'xs';
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => void;
  checked: boolean;
  disabled: boolean;
  label?: string | number;
};

const getClassName = (disabled, checked) => {
  if (disabled) return 'border-gray-300 bg-white';
  if (checked) return 'border-blue-300';
  return 'border-gray-300 bg-white';
};
const Checkbox: React.FC<Props> = (props) => {
  const { id, name, size, label, disabled, checked, onChange } = props;
  const checkboxRef = useRef(null);
  const sizeMap = {
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
  };
  const textMap = {
    xs: 'text-xs leading-0',
    sm: 'text-sm leading-1',
  };
  return (
    <div
      className={classNames(
        'flex justify-end align-middle items-center px-1.5 py-1 rounded w-full',
        getClassName(disabled, checked),
      )}
      role="presentation"
    >
      <input
        data-testid={`checkbox-${id}`}
        id={`checkbox-${id}`}
        name={`checkbox-${name}`}
        type="checkbox"
        className={classNames(
          'rounded-sm border',
          get(sizeMap, size, 'h3 w-3'),
          disabled
            ? 'text-gray-400 border-gray-400'
            : 'text-blue-500 focus:ring-0 checked:outline-0	 border-gray-500 cursor-pointer',
        )}
        disabled={disabled}
        checked={checked}
        ref={checkboxRef}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, id)}
      />
      {label && (
        <label
          htmlFor={`checkbox-${id}`}
          className={classNames(
            'ml-2 w-full',
            get(textMap, size, 'text-base'),
            checked && disabled ? 'text-gray-900' : checked && 'text-blue-700',
            !disabled && 'cursor-pointer',
            'text-gray-900',
          )}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
