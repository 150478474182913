import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { Device } from '@twilio/voice-sdk';
import { equalTo, getDatabase, onValue, orderByChild, query, ref, update } from 'firebase/database';
import store from 'app/store';
import audioCallTokenApi from 'common/api/audioCallToken';
import {
  TwilioAudioState,
  endAudioCall,
  startAudioCall,
  updateCallData,
} from 'common/slice/audioCallSlice';
import { TwilioCallEvents, TwilioCallStatus } from 'globals/enums';
import { handleError } from 'helpers/sentry';

let device: Device;
const audioCallListener = createListenerMiddleware();

audioCallListener.startListening({
  matcher: audioCallTokenApi.endpoints.fetchTwilioToken.matchFulfilled,
  effect: (action, listenerApi: any) => {
    if (action.payload) {
      try {
        device = new Device(action.payload, { tokenRefreshMs: 10000 });
        device.register();

        device.on('tokenWillExpire', async () => {
          const { data: updatedToken } = await store.dispatch(
            audioCallTokenApi.endpoints.fetchTwilioToken.initiate({}, { forceRefetch: true }),
          );
          if (updatedToken) device.updateToken(updatedToken);
        });

        device.on('error', (error) => {
          handleError(error);
        });

        const audioCallQuery = query(
          ref(getDatabase(), 'audio_call'),
          orderByChild('participant_group_id'),
          equalTo(listenerApi.getState().activeAttendee.participant_group_id),
        );

        onValue(audioCallQuery, (records) => {
          const data: TwilioAudioState = records.val();
          store.dispatch(updateCallData(data));
        });
      } catch (error) {
        handleError(error);
      }
      listenerApi.unsubscribe();
    }
  },
});

audioCallListener.startListening({
  matcher: isAnyOf(startAudioCall, endAudioCall),
  effect: async (action, listenerApi: any) => {
    const { activeAttendee, currentUser } = listenerApi.getState();
    if (startAudioCall.match(action)) {
      try {
        const attendee_id = activeAttendee.attendee_id;
        const participant_group_id = activeAttendee.participant_group_id;
        const session_uuid = action.payload.session_uuid;
        const session_id = action.payload.session_id;
        const proctor_id = currentUser.id;

        const connection = device.connect({
          params: {
            attendee_id,
            session_uuid,
            session_type: 'live_proctor',
          },
        });

        connection.then((conn) => {
          conn.on(TwilioCallEvents.Ringing, async () => {
            await upsertAudioCallStatus(session_id, {
              status: TwilioCallStatus.Initiated,
              attendee_id,
              proctor_id,
              participant_group_id,
              initiated_timestamp: new Date().toISOString(),
            });
          });

          conn.on(TwilioCallEvents.Accept, async () => {
            await upsertAudioCallStatus(session_id, {
              status: TwilioCallStatus.Connected,
              connected_timestamp: new Date().toISOString(),
            });
          });

          conn.on(TwilioCallEvents.Disconnect, async () => {
            console.log('Disconnecting voice call ', session_id);
            await upsertAudioCallStatus(session_id, {
              status: TwilioCallStatus.Disconnected,
              disconnected_timestamp: new Date().toISOString(),
            });
            // listenerApi.dispatch(
            //   setAudioCallStatus({ attendeeId: null, isOngoing: false, requested_timestamp: '' }),
            // );
          });

          conn.on(TwilioCallEvents.Error, async (error) => {
            await upsertAudioCallStatus(session_id, {
              status: TwilioCallStatus.Disconnected,
              disconnected_timestamp: new Date().toISOString(),
            });
            handleError(error);
          });
        });
      } catch (error) {
        handleError(error);
      }
    } else if (endAudioCall.match(action)) {
      console.log('Disconnecting voice call');
      device.disconnectAll();
    }
  },
});

const upsertAudioCallStatus = async (
  sessionId: number,
  payload: {
    attendee_id?: number;
    proctor_id?: number;
    participant_group_id?: number;
    status: string;
    requested_timestamp?: string;
    initiated_timestamp?: string;
    connected_timestamp?: string;
    disconnected_timestamp?: string;
  },
) => {
  try {
    await update(ref(getDatabase(), `audio_call/${sessionId}`), {
      ...payload,
    });
  } catch (error) {
    handleError(error);
  }
};

export default audioCallListener;
