import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

interface FirebaseCustomTokenResponse{
    customToken:string;
}

const firebaseCustomTokenApi=createApi({
    reducerPath:'firebaseCustomTokenApi',
    baseQuery: baseQueryWithReauth,
    endpoints:(builder)=>({
        firebaseCustomToken:builder.query({
            query:()=>({
                url:`/v1/custom-firebase-token/proctor`,
                method:'POST',
            }),
            transformResponse: (response: FirebaseCustomTokenResponse) => {
                return response.customToken;
            }
        }),
    }),
});

export const { useFirebaseCustomTokenQuery } = firebaseCustomTokenApi;

export default firebaseCustomTokenApi;