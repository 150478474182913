import React, { useContext } from 'react';
import { ReactFireOptions, useDatabase, useDatabaseListData } from 'reactfire';
import { equalTo, orderByChild, query, ref } from 'firebase/database';
import ProgressBar from 'common/components/ProgressBar';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import { countBy } from 'lodash';
import Loading from 'common/components/Loading';
import moment from 'moment';
import { Alert, Precheck } from 'globals/interfaces';
import {
  ALERT_TYPE_IDS,
  FIREBASE_RELATIONS,
  PRECHECK_STATUS,
  PRECHECK_STEPS,
  SESSION_STATUS,
} from '../../../../common/components/constant';

type Props = {
  invited: number;
};

const AttendeeStatus: React.FC<Props> = (props) => {
  let preFlightPassed = 0;
  const database = useDatabase();
  const [attendeeStatus, setAttendeeStatus] = React.useState<Record<string, number>>({});
  const { currentSlot, setSessions } = useContext<DashboardContextType>(DashboardContext);

  const options: ReactFireOptions = {
    idField: 'session_id',
  };
  const deviceQuery = query(
    ref(database, FIREBASE_RELATIONS.SESSION_V2),
    orderByChild('slot_id'),
    equalTo(currentSlot.id),
  );
  const { data, error, status } = useDatabaseListData(deviceQuery, options);
  React.useEffect(() => {
    if (data) {
      setSessions(data);
      const groupByStatus = countBy(data, 'session_status');
      setAttendeeStatus(groupByStatus);
    }
  }, [data]);
  data?.map((session: any) => {
    if (
      session?.session_status === SESSION_STATUS.ONLINE &&
      session?.alerts &&
      session?.pre_check
    ) {
      const sessionJoinedAlerts: Alert[] = (Object.values(session?.alerts) as Alert[])
        .filter((alert: Alert) => alert.alert_type_id === ALERT_TYPE_IDS.SESSION_JOINED)
        .sort((sessionJoinedAlert1: Alert, sessionJoinedAlert2: Alert) => {
          return (
            new Date(sessionJoinedAlert2.timestamp).getTime() -
            new Date(sessionJoinedAlert1.timestamp).getTime()
          );
        });
      const filteredPrechecks: Precheck[] = (Object.values(session.pre_check) as Precheck[])
        .filter(
          (precheck: Precheck) =>
            precheck.step === PRECHECK_STEPS.TEST_ENV &&
            precheck.status === PRECHECK_STATUS.APPROVED,
        )
        .sort((filteredPrecheck1: Precheck, filteredPrecheck2: Precheck) => {
          return (
            new Date(filteredPrecheck2.reviewed_timestamp).getTime() -
            new Date(filteredPrecheck1.reviewed_timestamp).getTime()
          );
        });
      if (sessionJoinedAlerts.length && filteredPrechecks.length) {
        const latestPrecheckTimestamp = moment(filteredPrechecks[0].requested_timestamp);
        const latestSessionJoinedAlertTimestamp = moment(sessionJoinedAlerts[0].timestamp);
        if (latestPrecheckTimestamp.isAfter(latestSessionJoinedAlertTimestamp)) {
          preFlightPassed++;
        }
      }
    }
  });
  const startedTest =
    (attendeeStatus.offline || 0) + (attendeeStatus.online || 0) + (attendeeStatus.created || 0);
  const completed = attendeeStatus.completed || 0;
  const terminated = attendeeStatus.terminated || 0;
  const noShow = attendeeStatus.no_show || 0;
  const notJoined = props.invited - startedTest - completed - terminated - noShow;

  const getPercentage = (value: number) => {
    return (value / props.invited) * 100;
  };
  const progressBarData = [
    { label: 'Not Joined', color: 'red', value: notJoined || 0 },
    { label: 'Pre - Test', color: 'blue', value: startedTest - preFlightPassed || 0 },
    { label: 'Started Test', color: 'blue', value: preFlightPassed || 0 },
    { label: 'Finished Test', color: 'blue', value: attendeeStatus.completed || 0 },
    { label: 'No Show', color: 'red', value: attendeeStatus.no_show || 0 },
    { label: 'Terminated', color: 'red', value: attendeeStatus.terminated || 0 },
  ];
  if (status === 'loading') return <Loading />;
  return (
    <div className="flex flex-col space-y-6 p-2">
      {progressBarData.map(({ label, color, value }) => (
        <ProgressBar
          key={label}
          label={label}
          color={color}
          value={value}
          percentage={getPercentage(value)}
        />
      ))}
    </div>
  );
};
export default AttendeeStatus;
