import { Precheck } from 'globals/interfaces';
import { ApprovalStatus, SessionScope, VerificationTypes } from '../globals/enums';
import { PHOTO_REJECTED_REASONS, TEST_ENV_REJECT_REASONS } from 'common/components/constant';

export type ArrangedPrechecks = Record<'idChecks' | 'envChecks', Precheck[] | []>;

export const getSortedPrechecks = (
  prechecks: Precheck[],
  isPhotoIDEnabled: boolean,
  isFaceCaptureEnabled: boolean,
): ArrangedPrechecks => {
  const isVerificationEnabled = isPhotoIDEnabled && isFaceCaptureEnabled;

  const filteredPrechecks = prechecks
    .filter((precheck: Precheck) => precheck.status !== ApprovalStatus.Stale && precheck.file_path)
    .sort(
      (a: Precheck, b: Precheck) =>
        new Date(b.requested_timestamp).getTime() - new Date(a.requested_timestamp).getTime(),
    );

  const idChecks = filteredPrechecks
    .filter((precheck: Precheck) => precheck.step !== VerificationTypes.TestEnv)
    .reduce((acc, precheck: Precheck) => {
      if (isVerificationEnabled) {
        if (precheck.step === VerificationTypes.PhotoId) {
          const matchingFaceCapture = prechecks.find(
            (face_capture: Precheck) =>
              face_capture.step === VerificationTypes.FaceCapture &&
              face_capture.uuid === precheck.reference_step_uuid,
          );
          matchingFaceCapture &&
            acc.push({ ...precheck, faceCaptureUrl: matchingFaceCapture.file_path || '' });
        }
      } else {
        acc.push(precheck);
      }
      return acc;
    }, []);

  const envChecks = filteredPrechecks.filter(
    (precheck: Precheck) => precheck.step === VerificationTypes.TestEnv,
  );

  return {
    idChecks,
    envChecks,
  };
};

export const getNotifyReasons = (type: string) => {
  switch (type) {
    case VerificationTypes.PhotoId:
      return PHOTO_REJECTED_REASONS;
    case VerificationTypes.TestEnv:
      return TEST_ENV_REJECT_REASONS;
    default:
      break;
  }
};

export const getSignedUrl = (session_uuid: string, file_path: string, token: string) => {
  if (file_path && session_uuid && token) {
    return `${process.env.AZURE_BLOB_URL}/${session_uuid}/${file_path}?${token}`;
  }
};

export const isApprovalRequired = (prechecks: Precheck[], configuration) => {
  const hasItem = (step: string) =>
    prechecks?.some((item) => item.status === ApprovalStatus.Submitted && item.step === step);

  const isVerificationEnabled = configuration?.id_verification_check && configuration?.face_capture;

  const photoIdIsPresent = hasItem(VerificationTypes.PhotoId);
  const faceCaptureIsPresent = hasItem(VerificationTypes.FaceCapture);
  const environmentCheckPresent = hasItem(VerificationTypes.TestEnv);

  const IdentityCheckPresent = isVerificationEnabled
    ? photoIdIsPresent && faceCaptureIsPresent
    : photoIdIsPresent;

  return IdentityCheckPresent || environmentCheckPresent;
};

export const getIsApprovalRequired = (precheck: Precheck, session_type: SessionScope): boolean =>
  precheck.step !== VerificationTypes.FaceCapture && session_type === SessionScope.LiveProctor;
