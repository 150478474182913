import React, { useState, useEffect } from 'react';
import {
  PlusIcon,
  BackwardIcon,
  ForwardIcon,
  XMarkIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/components/Button';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import AssessmentDetailView from './AssessmentDetailView';
import { getWrapperColumns } from './WrapperColumns';
import { useQuery } from '@apollo/client';
import Spinner from '../lms-dashboard/Spinner';
import { wrapperlinks } from '../queries.graphql';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useGetTokensQuery } from 'common/api/proviewTokenQuery';

export const Wrapper = () => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 12 });
  const [selectedAssessments, setSelectedAssessments] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [proviewToken, setProviewToken] = useState('');
  const [proctoringType, setProctoringType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('');

  const [filterVariables, setFilterVariables] = useState({
    proviewToken: '',
    proctoringType: '',
    startDate: null,
    endDate: null,
    status: '',
  });

  const expiry = (startDate, endDate) => ({
    ...(startDate && { _gte: startDate }),
    ...(endDate && { _lte: endDate }),
  });
  const { data: tokenData, error: tokenError, isLoading: isTokenLoading } = useGetTokensQuery();

  const { data, error, loading, refetch } = useQuery(wrapperlinks, {
    variables: {
      offset: pagination.pageIndex * pagination.pageSize,
      limit: pagination.pageSize,
      search: `%${searchQuery}%`,
      expiry: expiry(filterVariables.startDate, filterVariables.endDate),
      type: filterVariables.proctoringType ? { _eq: filterVariables.proctoringType } : {},
      status: filterVariables.status ? { _eq: filterVariables.status } : {},
      proview_token_id: filterVariables.proviewToken ? { _eq: filterVariables.proviewToken.split('|$|')[0] } : {}
    },
  });

  useEffect(() => {
    refetch();
  }, [filterVariables, pagination, searchQuery]);

  const isFilterActive = filterVariables.proviewToken || filterVariables.proctoringType || filterVariables.startDate || filterVariables.endDate || filterVariables.status;

  const handleButtonClick = () => {
    navigate('form');
  };

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
  };

  const handleApplyFilter = () => {
    setFilterVariables((prev) => ({
      ...prev,
      proviewToken,
      proctoringType,
      startDate,
      endDate,
      status,
    }));
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setShowFilter(false);
  };

  const handleClearFilter = () => {
    setShowFilter(false)
    setProviewToken('');
    setProctoringType('');
    setStartDate(null);
    setEndDate(null);
    setStatus('');
    setFilterVariables({
      proviewToken: '',
      proctoringType: '',
      startDate: null,
      endDate: null,
      status: '',
    });
    setPagination({ pageIndex: 0, pageSize: 12 });
  };

  let currentPage = pagination.pageIndex + 1;

  const handleNextPage = () => {
    const newPageIndex = pagination.pageIndex + 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPageIndex,
    }));
  };

  const handlePreviousPage = () => {
    const newPageIndex = pagination.pageIndex - 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPageIndex,
    }));
  };

  const handleRowClick = (assessment) => {
    setSelectedAssessments(assessment);
  };

  const handleCloseWrapperDetailView = () => {
    setSearchQuery('');
    setSelectedAssessments(null);
    setPagination({ pageIndex: 0, pageSize: 12 });
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toString());
    setPagination({ pageIndex: 0, pageSize: 12 });
  };

  const totalRecordCount = data?.proctoring_wrapper_invite_aggregate?.aggregate?.count;

  const columns = getWrapperColumns();

  const table = useReactTable({
    data: data?.proctoring_wrapper_invite || [],
    columns,
    onPaginationChange: setPagination,
    pageCount: -1,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const getTokenNameByToken = (token: string) => {
    const item = tokenData.find(entry => entry.token === token);
    return item ? item.name : '--';
  }

  if (selectedAssessments) {
    return (
      <AssessmentDetailView
        assessments={selectedAssessments}
        onClose={handleCloseWrapperDetailView}
      />
    );
  }

  return (
    <div className="flex flex-col w-full h-full space-y-3 text-gray relative mt-10">
      <div className="flex justify-between items-center mr-8">
        <div className="flex-grow mr-4">
          <input
            type="text"
            placeholder="Search by Attendee ID or Session ID"
            className="border border-gray-400 rounded-lg p-2 w-80 "
            onChange={handleSearch}
          />
        </div>
        <Button
          label="Create Link"
          className="bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center mr-10"
          icon={<PlusIcon className="h-5 w-5 mr-2" />}
          onClick={handleButtonClick}
        />
        <Button
          label={'Filter'}
          className={` bg-emerald-900 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded-full inline-flex items-center`}
          icon={<FunnelIcon className="h-5 w-5 mr-2" />}
          onClick={handleFilterToggle}
        />
      </div>

      {isFilterActive && (
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
          <p className="font-bold">Filters applied:</p>
          <ul className="list-disc list-inside">
            {filterVariables.proviewToken && (<li>Proview Token: {getTokenNameByToken(filterVariables.proviewToken.split('|$|')[1])}</li>)}
            {filterVariables.proctoringType && <li>Proctoring Type: {filterVariables.proctoringType}</li>}
            {filterVariables.startDate && <li>Start Date: {filterVariables.startDate.toDateString()}</li>}
            {filterVariables.endDate && <li>End Date: {filterVariables.endDate.toDateString()}</li>}
            {filterVariables.status && <li>Status: {filterVariables.status}</li>}
          </ul>
        </div>
      )}

      {showFilter && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-120 max-w-xl">
            <div className="flex justify-between">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Filter Options</h2>
              <button onClick={() => setShowFilter(false)}>
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="proctoringType"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Proctoring Type
                </label>
                <select
                  id="proctoringType"
                  className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  value={proctoringType}
                  onChange={(e) => setProctoringType(e.target.value)}
                >
                  <option value="" disabled>
                    Select Proctoring Type
                  </option>
                  <option value="ai_proctor">AI Proctoring</option>
                  <option value="record_and_review">Record and Review</option>
                  <option value="live_proctor">Live Proctoring</option>
                  <option value="offline_proctor">Offline Proctoring</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                <select
                  className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value="used">Used</option>
                  <option value="generated">Generated</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Link Expiry</label>
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Start Date"
                    />
                  </div>
                  <div className="flex-1">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      dateFormat="yyyy-MM-dd"
                      placeholderText="End Date"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Proctoring Token
                </label>
                <select
                  className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  value={proviewToken}
                  onChange={(e) => setProviewToken(e.target.value)}
                >
                  <option value="" disabled>
                    Select Token
                  </option>
                  {tokenData?.map((token) => (
                    <option key={token.id} value={`${token.id}|$|${token.token}`}>
                      {token.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-8 flex justify-end space-x-4">
              <button
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleClearFilter}
              >
                Clear
              </button>
              <button
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleApplyFilter}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col w-full h-full space-y-3 text-gray">
        <div className="flex flex-col bg-white rounded-lg shadow-md overflow-scroll">
          {loading && !error ? <Spinner /> : <Table table={table} onRowClick={handleRowClick} />}
          {error && <div>Error Fetching Data</div>}
          <div className="flex justify-between mb-4">
            <Button
              className={'flex items-center px-4 py-2 disabled:opacity-50'}
              onClick={handlePreviousPage}
              icon={<BackwardIcon className="h-5 w-5 mr-2" />}
              disabled={currentPage === 1}
              testId="previousPageButton"
            ></Button>
            <span data-testid="currentPageIndicator">
              Page {pagination.pageIndex + 1} of{' '}
              {totalRecordCount ? Math.ceil(totalRecordCount / pagination.pageSize) : 1}
            </span>
            <Button
              className={'flex items-center px-4 py-2 disabled:opacity-50'}
              onClick={handleNextPage}
              icon={<ForwardIcon className="h-5 w-5 mr-2" />}
              disabled={totalRecordCount <= (pagination.pageIndex + 1) * pagination.pageSize}
              testId="nextPageButton"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Table = ({ table, onRowClick }) => (
  <table className="min-w-max text-sm w-full">
    <thead className="border-b border-slate-300">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => (
            <th key={header.id} className="text-start p-2 px-4">
              {header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody className="border-b border-slate-300 mt-3">
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id} className="hover:bg-gray-100">
          {row.getVisibleCells().map((cell, index) => (
            <td
              key={cell.id}
              className={`p-2 px-4 break-normal max-w-xs truncate ${index >= 0 ? 'cursor-pointer' : ''
                }`}
              onClick={() => onRowClick(row.original)}
            >
              {(() => {
                const content = flexRender(cell.column.columnDef.cell, cell.getContext());
                return content ? content : 'No';
              })()}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default Wrapper;

