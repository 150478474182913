import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useState, useMemo } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { GET_QUIZZES } from '../queries.graphql';
import { AdjustmentsHorizontalIcon, BackwardIcon, ForwardIcon } from '@heroicons/react/24/outline';
import Button from '../../../common/components/Button';
import QuizDetailView from './QuizDetailView';
import { getQuizColumns } from './Columns';
import { useQuery } from '@apollo/client';
import Spinner from './Spinner';
import { ArrowDownOnSquareStackIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { useSyncAttendeesMutation } from 'common/api/lmsApi';
import { useFetchTwilioTokenQuery } from 'common/api/audioCallToken';
import { OrganizationConfiguration } from './configuration/OrganizationConfiguration';
import { Quiz } from './types';

export const LmsDashboard = () => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [checkboxSelectedQuizzes, setCheckboxSelectedQuizzes] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [attendeeSync, { isLoading }] = useSyncAttendeesMutation({});
  const [timeopen, setTimeopen] = useState<string | null>(null);
  const [timeclose, setTimeclose] = useState<string | null>(null);
  const [lmsconfigurationModalOpen, setLmsconfigurationModalOpen] = useState(false);

  const quizId = useMemo(() => {
    return checkboxSelectedQuizzes ? checkboxSelectedQuizzes.map((quiz: Quiz) => quiz.id) : [];
  }, [checkboxSelectedQuizzes]);

  const currentPage = pagination.pageIndex + 1;
  const { data, error, loading, refetch } = useQuery(GET_QUIZZES, {
    variables: {
      proview_organization_id: currentUser.organization_id,
      offset: pagination.pageIndex * pagination.pageSize,
      limit: pagination.pageSize,
      search: `%${searchQuery}%`,
      timeopen: timeopen ? { _gte: timeopen } : {},
      timeclose: timeclose ? { _lte: timeclose } : {},
    },
  });

  const handleNextPage = () => {
    handleDeselectAll();
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: prevPagination.pageIndex + 1,
    }));
  };

  const handlePreviousPage = () => {
    handleDeselectAll();
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: prevPagination.pageIndex - 1,
    }));
  };

  const handleRowClick = (quiz) => {
    setSelectedQuiz(quiz);
  };

  const handleSelectAll = () => {
    setCheckboxSelectedQuizzes(data?.lms_quiz || []);
  };

  const handleDeselectAll = () => {
    setCheckboxSelectedQuizzes([]);
  };

  const handleToggleSelect = (quiz) => {
    if (checkboxSelectedQuizzes.includes(quiz)) {
      setCheckboxSelectedQuizzes(checkboxSelectedQuizzes.filter((item) => item !== quiz));
    } else {
      setCheckboxSelectedQuizzes([...checkboxSelectedQuizzes, quiz]);
    }
  };

  const handleCloseQuizDetailView = () => {
    setSelectedQuiz(null);
    setCheckboxSelectedQuizzes([]);
    setPagination({ pageIndex: 0, pageSize: 15 });
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toString());
  };
  const totalQuizzesCount = data?.lms_quiz.length || 0;

  const columns = getQuizColumns({
    pagination,
    handleSelectAll,
    handleDeselectAll,
    checkboxSelectedQuizzes,
    handleToggleSelect,
    totalQuizzesCount,
    timeopen,
    setTimeopen,
    timeclose,
    setTimeclose,
  });
  const table = useReactTable({
    data: data?.lms_quiz || [],
    columns,
    onPaginationChange: setPagination,
    pageCount: -1,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  if (selectedQuiz) {
    return <QuizDetailView quiz={selectedQuiz} onClose={handleCloseQuizDetailView} />;
  }

  const syncAttendees = async () => {
    const data = await attendeeSync({
      quizIds: quizId,
    });
    if (data) {
      await refetch();
    }
  };

  return (
    <div>
      <div className="flex flex-col w-full h-full space-y-3 text-gray">
        <div className="flex justify-end mr-6">
          <button
            className={'flex items-center text-white px-4 py-2 bg-gray-400 hover:bg-gray-200 rounded-full inline-flex items-center'}
            onClick={() => setLmsconfigurationModalOpen(true)}
          >
            < AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" />
            <span>LMS Configuration</span>
          </button>
        </div>
        <div className="flex justify-between mt-4 mb-2 mr-8">
          <h3 className="text-md font-semibold">Assessments</h3>
          <h3 className="text-md font-semibold">
            Count: {data?.lms_quiz_aggregate?.aggregate?.count}
          </h3>
        </div>
        <div className="flex justify-between mt-4 mb-3">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-400 rounded-lg p-2"
            onChange={handleSearch}
          />
          <div className="flex items-center space-x-2">
            <button
              onClick={syncAttendees}
              className={`bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center mr-4 ${checkboxSelectedQuizzes?.length > 0 ? '' : 'opacity-50 cursor-not-allowed'
                }`}
            >
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              <span>Sync Attendees</span>
            </button>
          </div>
        </div>
        <div className="flex flex-col bg-white rounded-lg shadow-md overflow-scroll">
          {loading || isLoading ? <Spinner /> : <Table table={table} onRowClick={handleRowClick} />}
          {error && <div>Error fetching quizzes</div>}
          <div className="flex justify-between mb-4">
            <Button
              className={'flex items-center px-4 py-2'}
              onClick={handlePreviousPage}
              icon={<BackwardIcon className="h-5 w-5 mr-2" />}
              disabled={currentPage == 1}
              testId="previousPageButton"
            ></Button>
            <span data-testid="currentPageIndicator">{pagination.pageIndex + 1}</span>
            <Button
              className={'flex items-center px-4 py-2'}
              onClick={handleNextPage}
              icon={<ForwardIcon className="h-5 w-5 mr-2" />}
              disabled={data?.lms_quiz_aggregate?.aggregate?.count < pagination.pageSize}
              testId="nextPageButton"
            ></Button>
          </div>
        </div>
        {lmsconfigurationModalOpen && <OrganizationConfiguration closeModal={() => setLmsconfigurationModalOpen(false)} />}
      </div>
    </div>
  );
};

const Table = ({ table, onRowClick }) => (
  <table className="min-w-max text-sm w-full">
    <thead className="border-b border-slate-300">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => (
            <th key={header.id} className="text-start p-2 px-4">
              {header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody className="border-b border-slate-300 mt-3">
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id} className="hover:bg-gray-100">
          {row.getVisibleCells().map((cell, index) => (
            <td
              key={cell.id}
              className={`p-2 px-4 break-normal ${index > 0 ? 'cursor-pointer' : ''}`}
              onClick={index > 0 ? () => onRowClick(row.original) : null}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
