import React from 'react';
import { convertUTCDateToLocalDate } from './dateTimehelper';

const QuizBodyView = ({ quiz }) => {
  const keyColor = 'text-slate-500';
  const parseTimestamp = (timestamp) => {
    return new Date(timestamp);
  };
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  return (
    <div className="p-4 flex justify-between">
      <div className="flex flex-col space-y-2">
        <p className="text-sm">
          <span className={`${keyColor}`}>Course ID:</span> {quiz?.course_id}
        </p>
        <p className="text-sm">
          <span className={`${keyColor}`}>Course Module ID:</span> {quiz?.course_module_id}
        </p>
        <p className="text-sm">
          <span className={`${keyColor}`}>Proctoring Type:</span> {quiz?.proctoring_type}
        </p>
        <p className="text-sm">
          <span className={`${keyColor}`}>Course Module Item Id:</span>{' '}
          {quiz?.course_module_item_id ? quiz.course_module_item_id : 'Not Available'}
        </p>
        <p className="text-sm">
          <span className={`${keyColor}`}>TSB:</span> {quiz?.tsb_enabled ? 'Enabled' : 'Disabled'}
        </p>
      </div>
      <div className="flex flex-col space-y-2">
        <p className="text-sm">
          <span className={`${keyColor}`}>Created At:</span>{' '}
          {formatDate(parseTimestamp(convertUTCDateToLocalDate(quiz.created_at)))}
        </p>
        <p className="text-sm">
          <span className={`${keyColor}`}>Updated At:</span>{' '}
          {formatDate(parseTimestamp(convertUTCDateToLocalDate(quiz.updated_at)))}
        </p>
        <p className="text-sm">
          <span className={`${keyColor}`}>Time Open:</span>{' '}
          {formatDate(parseTimestamp(convertUTCDateToLocalDate(quiz.timeopen)))}
        </p>
        <p className="text-sm">
          <span className={`${keyColor}`}>Time Close:</span>{' '}
          {formatDate(parseTimestamp(convertUTCDateToLocalDate(quiz.timeclose)))}
        </p>
      </div>
    </div>
  );
};
export default QuizBodyView;
