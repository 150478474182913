import React from 'react';

type Props = {
  description: string;
  icon: React.ReactNode;
};

const EmptyState: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center space-y-4">
      {props.icon}
      <div className="text-sm">{props.description}</div>
    </div>
  );
};

export default EmptyState;
