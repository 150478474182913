import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

const precheckApi = createApi({
  reducerPath: 'precheckApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    updatePrecheck: builder.mutation({
      query: (payload) => ({
        url: `v1/attendee/${payload.attendee_id}/pre-check?slot_id=${payload.slot_id}`,
        method: 'PATCH',
        body: payload.data,
      }),
    }),
  }),
});

export const { useUpdatePrecheckMutation } = precheckApi;

export default precheckApi;
