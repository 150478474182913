import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useFetchOrganizationConfigQuery, useUpdateOrganizationConfigMutation, useCreateOrganizationConfigMutation } from '../../../../common/api/lmsApi';
import { useExchangeAuthCodeForTokenMutation } from '../../../../common/api/external/brighspaceOauth';
import { BrightSpaceJWTDecoded } from '../types';


export const useBrightSpaceConfig = (organizationId: string) => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [config, setConfig] = useState({
        clientId: '',
        clientSecret: '',
        lmsUrl: '',
        proviewAdminUsername: '',
        proviewAdminPassword: '',
        refreshToken: '',
        appId: '',
        cronTiming: '0 0 1 1 * *',
        isAutoSyncEnabled: false,
    });

    const { data, error, isLoading } = useFetchOrganizationConfigQuery(organizationId);
    const [updateOrganizationConfig] = useUpdateOrganizationConfigMutation();
    const [createOrganizationConfig] = useCreateOrganizationConfigMutation();
    const [exchangeAuthCodeForToken] = useExchangeAuthCodeForTokenMutation();

    useEffect(() => {
        if (data) {
            setIsUpdate(true);
            setConfig({
                clientId: data?.lms_config?.brightspace.secrets.api_key || '',
                clientSecret: data?.lms_config?.brightspace.secrets.api_secret || '',
                lmsUrl: data?.lms_config?.brightspace.url || '',
                proviewAdminUsername: data?.lms_config?.brightspace.secrets.proview_admin_username || '',
                proviewAdminPassword: data?.lms_config?.brightspace.secrets.proview_admin_password || '',
                refreshToken: data?.lms_config?.brightspace.secrets.token || '',
                appId: data?.app_id || '',
                cronTiming: data?.cron_timing || '',
                isAutoSyncEnabled: data?.is_auto_sync || false,
            });
        }
    }, [data]);

    const handleAuthCodeExchange = async (code: string) => {
        const clientId = sessionStorage.getItem('client_id') || '';
        const clientSecret = sessionStorage.getItem('client_secret') || '';
        exchangeAuthCodeForToken({
            code,
            redirectUri: `${window.location.origin}/dashboard/lms/configure/brightspace`,
            clientId,
            clientSecret,
            scope: 'enrollment:orgunit:read quizzing:attempts:read'
        })
            .unwrap()
            .then((res) => {
                const decoded: BrightSpaceJWTDecoded = jwtDecode(res.access_token);
                setConfig(prev => ({ ...prev, refreshToken: res.refresh_token, appId: decoded.tenantid }));
            })
            .catch((error) => console.error('Error exchanging auth code for token:', error));
        window.history.replaceState({}, document.title, window.location.pathname);
    };

    const handleUpdateConfiguration = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            lms_config: {
                type: 'BRIGHTSPACE',
                brightspace: {
                    secrets: {
                        api_key: config.clientId || sessionStorage.getItem('client_id'),
                        api_secret: config.clientSecret || sessionStorage.getItem('client_secret'),
                        proview_admin_username: config.proviewAdminUsername,
                        proview_admin_password: config.proviewAdminPassword,
                        token: config.refreshToken,
                    },
                    url: config.lmsUrl,
                },
            },
            app_id: config.appId,
            is_auto_sync: config.isAutoSyncEnabled,
            cron_timing: config.cronTiming,
            proview_organization_id: organizationId,
            name: 'BrightSpace',
        };

        if (isUpdate) {
            updateOrganizationConfig({ organizationId, data })
                .then(() => alert('Configuration updated successfully.'))
                .catch((error) => console.error('Error updating configuration:', error));
        } else {
            createOrganizationConfig(data)
                .then(() => alert('Configuration created successfully.'))
                .catch((error) => console.error('Error creating configuration:', error));
        }
    };

    return {
        config,
        setConfig,
        isUpdate,
        isLoading,
        handleAuthCodeExchange,
        handleUpdateConfiguration,
    };
};
