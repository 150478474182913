import { scaleLinear } from 'd3-scale';
import { getGroupedAlertsBySessionJoined, getTotalSessionDuration } from 'helpers/playback';
import Seekbar from './Seekbar';
import useMeasure from 'hooks/useMeasure';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import usePlaybackTimer from 'hooks/usePlaybackTimer';

const SeekbarContainer = ({ alerts }) => {
  const groupedAlertsBySessionJoins = getGroupedAlertsBySessionJoined(alerts);
  const totalDuration = getTotalSessionDuration(groupedAlertsBySessionJoins);
  const currentPlayingTime = useAppSelector((state) => state.playbackVideo.currentPlayingTime);
  const [ref, { width }] = useMeasure();
  const offset = 30;

  const scale = scaleLinear()
    .domain([0, totalDuration])
    .range([0, width - offset]);

  usePlaybackTimer(groupedAlertsBySessionJoins);

  return (
    <div className="flex items-center gap-1 mx-4">
      <div ref={ref} className="flex-1 flex items-center h-2 bg-white rounded-full">
        {groupedAlertsBySessionJoins.map((alerts) => {
          const startTimestamp: string = alerts[0]?.timestamp || alerts[0]?.updated_at;
          const endTimestamp: string =
            alerts[alerts.length - 1]?.timestamp || alerts[alerts.length - 1]?.updated_at;
          const duration = dayjs(endTimestamp).diff(startTimestamp, 'second');
          const alertsId = alerts[0].id;
          return (
            <Seekbar
              currentPlayingTime={currentPlayingTime}
              key={alertsId}
              alerts={alerts}
              width={scale(duration)}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
            />
          );
        })}
      </div>
      <div className="w-20 text-right text-[13px]">
        {dayjs(currentPlayingTime).format('HH:mm:ss A')}
      </div>
    </div>
  );
};

export default SeekbarContainer;
