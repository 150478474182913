import React from 'react';
import Button from './Button';
import { XMarkIcon } from '@heroicons/react/24/outline';

type Props = {
  show: boolean;
  onCancel: () => void;
  children: React.ReactNode;
  size?: string;
};

const Modal = ({ show, children, onCancel, size = 'sm' }: Props) => {
  const sizeMap = {
    sm: 'w-2/6',
    md: 'w-3/6',
    lg: 'w-4/6',
    xl: 'w-5/6',
  };

  return (
    show && (
      <div data-testid="modal" className="fixed inset-0 z-50 bg-black/60">
        <div
          className={`${sizeMap[size]} fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded p-2`}
        >
          <div className="p-5">
            <Button
              data-testid="modal-close-button"
              className="absolute top-4 right-4 text-slate-400"
              icon={<XMarkIcon className="w-6 h-6" />}
              onClick={() => onCancel()}
            />
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
