import React from 'react';
import ApprovalTab from 'components/approvals/ApprovalTab';
import AlertTab from 'components/alerts/AlertTab';
import { TABS } from 'common/components/constant';
import { setActiveTab } from 'common/slice/activeTabSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ChatTab from 'components/chat/ChatTab';
import SummaryTab from 'components/summary/SummaryTab';

const TabPane = ({ sessions, attendeeId, tabs }) => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.activeTab.tab);

  const handleTabSelection = (tabName: string) => {
    dispatch(setActiveTab(tabName));
  };

  const getTabButtonStyles = (tabLabel: string) => {
    const isActive = activeTab === tabLabel;
    const baseStyles = 'inline-block cursor-pointer pb-3';

    const activeStyles = `${baseStyles} text-blue-600 border-b-4 border-blue-600 dark:text-blue-500 dark:border-blue-500`;
    const inactiveStyles = `${baseStyles} text-slate-400 hover:text-blue-400`;

    return isActive ? activeStyles : inactiveStyles;
  };

  const getActiveTabComponent = (sessions): React.ReactNode => {
    const session = sessions?.find((item) => item?.attendee_id === attendeeId);
    switch (activeTab) {
      case TABS.ALERT_LOGS:
        return (
          <AlertTab
            alerts={session?.alerts}
            sessionUuid={session?.session_uuid}
            sessionId={session?.session_id}
            attendeeId={session?.attendee_id}
            sessionStatus={session?.session_status}
            slotStartDate={session?.session_start_date}
            slotEndDate={session?.session_end_date}
          />
        );
      case TABS.CHAT:
        return <ChatTab session={session} />;
      case TABS.APPROVALS:
        return (
          <ApprovalTab
            session_uuid={session?.session_uuid}
            prechecks={session?.pre_check}
            session_id={session?.session_id}
            session_type={session?.session_type}
          />
        );
      default:
        return (
          session?.session_id && (
            <SummaryTab
              session_uuid={session?.session_uuid}
              event_id={session?.event_id}
              slot_id={session?.slot_id}
            />
          )
        );
    }
  };

  return (
    <>
      <div className="flex justify-between gap-2 mx-6 mt-6 text-sm font-semibold border-b">
        {tabs?.map((tabLabel: string) => (
          <div
            key={tabLabel}
            onClick={() => handleTabSelection(tabLabel)}
            className={getTabButtonStyles(tabLabel)}
          >
            {tabLabel}
          </div>
        ))}
      </div>
      {getActiveTabComponent(sessions)}
    </>
  );
};

export default TabPane;
