import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  id: null,
  email: '',
  external_id: '',
  organization_id: null,
  username: '',
  roles: [],
};

const currentUser = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, {payload}) => {
      return {...state, ...payload};
    },
  },
});

export const {setCurrentUser} = currentUser.actions;

export default currentUser.reducer;
