import { useAppSelector } from 'app/hooks';
import InfoCard from 'common/components/InfoCard';
import { getIsApprovalRequired, getSortedPrechecks } from '../../helpers/prechecks';
import { Prechecks } from 'globals/interfaces';
import Accordion from 'common/components/Accordion';
import ApprovalItem from './ApprovalItem';
import { useState } from 'react';
import { selectConfigurationBySessionId } from 'common/slice/sessionListSlice';
import { SessionScope } from 'globals/enums';

interface Props {
  prechecks: Prechecks;
  session_id: string;
  session_uuid: string;
  session_type: SessionScope;
}

const ApprovalTab = ({ prechecks, session_id, session_uuid, session_type }: Props) => {
  const [openAccordion, setOpenAccordion] = useState('');
  const configuration = useAppSelector((state) =>
    selectConfigurationBySessionId(state.sessionList, session_id),
  );
  const attendee = useAppSelector((state) => state.activeAttendee);

  const sortedPrechecks =
    prechecks && configuration
      ? getSortedPrechecks(
          Object.values(prechecks),
          configuration?.id_verification_check,
          configuration?.face_capture,
        )
      : {
          idChecks: [],
          envChecks: [],
        };

  const handleAccordionClick = (value: string) => {
    setOpenAccordion((prev) => (prev === value ? '' : value));
  };

  return (
    <aside className="px-4 py-6 text-sm flex flex-col gap-4 overflow-auto">
      <InfoCard attendeeInfo={attendee} />
      <div>
        {sortedPrechecks.idChecks?.length > 0 && (
          <Accordion
            onClick={() => handleAccordionClick('identity_check')}
            isOpen={openAccordion === 'identity_check'}
            title="Identity Check"
            content={sortedPrechecks.idChecks.map((precheck) => (
              <ApprovalItem
                session_id={session_id}
                session_uuid={session_uuid}
                key={precheck.uuid}
                type={precheck.step}
                resourceUrl={precheck.file_path}
                timestamp={precheck.requested_timestamp}
                status={precheck.status}
                reason={precheck.reason}
                faceCaptureUrl={precheck.faceCaptureUrl}
                isApprovalRequired={getIsApprovalRequired(precheck, session_type)}
              />
            ))}
          />
        )}

        {sortedPrechecks.envChecks?.length > 0 && (
          <Accordion
            onClick={() => handleAccordionClick('environment_check')}
            isOpen={openAccordion === 'environment_check'}
            title="Environment Check"
            content={sortedPrechecks.envChecks.map((precheck) => (
              <ApprovalItem
                session_id={session_id}
                session_uuid={session_uuid}
                key={precheck.uuid}
                type={precheck.step}
                resourceUrl={precheck.file_path}
                timestamp={precheck.requested_timestamp}
                status={precheck.status}
                reason={precheck.reason}
                isApprovalRequired={getIsApprovalRequired(precheck, session_type)}
              />
            ))}
          />
        )}
      </div>
    </aside>
  );
};

export default ApprovalTab;
