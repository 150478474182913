import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Link } from 'react-router-dom';
import { removeBreadcrumb } from 'common/slice/breadcrumbsSlice';
import { setDetailView } from 'common/slice/activeAttendeeSlice';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';

const Header = () => {
  const dispatch = useAppDispatch();
  const breadcrumbs = useAppSelector((state) => state.breadcrumbs);
  const isEmbeddedView = useAppSelector((state) => state.playbackVideo.isEmbeddedView); 
  const isWithinIframe = window.parent !== window;

  const handleOnClick = () => {
    dispatch(setDetailView({ isDetailView: false }));
    dispatch(removeBreadcrumb());
  };

  return (
    <nav className="flex flex-row h-10 justify-between items-center p-2 bg-white border-b-2 border-gray-200">
      <ol className="list-reset flex text-gray-700">
        {breadcrumbs.map((crumb, index) => (
          <li key={index}>
            {crumb.url ? (
              <Link
                to={crumb.url}
                onClick={handleOnClick}
                className="underline"
                data-testid={`breadcrumb-link-${index}`}
              >
                {crumb.label}
              </Link>
            ) : (
              <span>{crumb.label}</span>
            )}
            {index < breadcrumbs.length - 1 && <span className="mx-2">/</span>}
          </li>
        ))}
      </ol>
      {isEmbeddedView && isWithinIframe && (
        <a data-testid="new-page-link" href={window.location.href} target="_blank" rel="noreferrer">
          <ArrowTopRightOnSquareIcon className="h-4 w-4 font-semibold" />
        </a>
      )}
    </nav>
  );
};

export default Header;
