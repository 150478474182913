import jwtDecode from "jwt-decode";
import mixpanel from "mixpanel-browser";
interface User {
  user: {
    id: number;
    email: string;
    external_id?: string;
    organization_id: number;
    username: string;
  };
  'https://hasura.io/jwt/claims'?: {
    'x-hasura-allowed-roles'?: string[];
  };
}
let isMixpanelInitialized = false;

const mixPanelActions = {
  init: () => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });
      isMixpanelInitialized = true;
    }
  },
  track: (alert) => {
    if (isMixpanelInitialized) {
      let props={};
      const stored = localStorage.getItem('live-proctor-session');
      const { access_token } = stored ? JSON.parse(stored) : { access_token: null };
      if(access_token) {
        const decodedToken: User = jwtDecode(access_token);
        const roles = decodedToken['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles'] || [];
        const role = roles.includes("test-admin") ? "admin" : "proctor";
        props = {
          user_id: decodedToken.user.id,
          org_id: decodedToken.user.organization_id,
          user_role: role
        };
        mixpanel.identify(decodedToken.user.id);
      }
      const trackProps = {
        platform_name: "Proview",
        version: "v7plus_proctoring",
        module: "ProctorNG",
        ...props
      };
      mixpanel.track(alert, trackProps);
    }
  },
  identify: (userId) => {
    if (isMixpanelInitialized) {
      mixpanel.identify(userId);
    }
  },
  people: {
    set: (props) => {
      if (isMixpanelInitialized) {
        mixpanel.people.set(props);
      }
    }
  }
};

export const Mixpanel = mixPanelActions;