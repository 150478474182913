import { get } from "lodash";
import { getSessionCompletedTimestamp } from "./playback";

export const getSessionCompletedAt = (alerts: any[]): string | undefined => {
  return Array.isArray(alerts) ? getSessionCompletedTimestamp(alerts) : undefined;
};

export const getSessionJoinedAt = (alerts: any[]): string | undefined => {
  return Array.isArray(alerts) ? get(alerts, '[0].timestamp') : undefined;
};
  