import { useAppDispatch, useAppSelector } from 'app/hooks';
import { updateCurrentPlayingTime } from 'common/slice/playbackVideoSlice';
import { useEffect, useRef } from 'react';

const usePlaybackTimer = (groupedAlertsBySessionJoins) => {
  const interval = useRef(null);
  const dispatch = useAppDispatch();
  const { timelineActive, videoControl, playbackSpeed } = useAppSelector((state) => state.playbackVideo);
  const timeInterval = 1000 / playbackSpeed;

  useEffect(() => {
    if (timelineActive && !videoControl) {
      interval.current = setInterval(() => {
        dispatch(
          updateCurrentPlayingTime({
            groupedAlerts: groupedAlertsBySessionJoins,
            elapsedTime: 1000,
          }),
        );
      }, timeInterval);
    } else {
      if (interval.current) clearInterval(interval.current);
    }
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [timelineActive, videoControl, playbackSpeed]);
};

export default usePlaybackTimer;
