import { Navigate } from 'react-router-dom';

import { useEffect } from 'react';
import { useAuthMutation } from 'common/api/auth';
import Loader from 'common/components/Loader';

interface AuthTokenResponse {
  access_token: string;
  refresh_token: string;
}

const Splash = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const refresh_token = urlParams.get('token');
  const event_id = urlParams.get('event_id');
  const slot_id = urlParams.get('slot_id');
  const session_uuid = urlParams.get('session_uuid');

  const [auth, { data, isLoading, isSuccess, isError, error }] = useAuthMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenResponse = await auth({
          refresh_token,
        }).unwrap();
        const token: AuthTokenResponse = {
          access_token: tokenResponse.access_token,
          refresh_token: tokenResponse.refresh_token,
        };
        // dispatch(success(token));
      } catch (error) {
        // dispatch(failure(error));
      }
    };
    fetchData();
  }, []);

  const render = () => {
    if (event_id && slot_id) {
      return <Navigate to={`/slot?event_id=${event_id}&slot_id=${slot_id}`} replace={true} />;
    } else if (session_uuid) {
      return <Navigate to={`/session/${session_uuid}`} replace={true} />;
    }
  };

  return isSuccess ? render() : isLoading && <Loader />;
};

export default Splash;
