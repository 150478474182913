import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import authApi from './auth';
import { Mutex } from 'async-mutex';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.PROVIEW_API_URL,
  prepareHeaders: (headers: Headers) => {
    headers.set('app-id', process.env.REACT_APP_ID as string);
    const token = JSON.parse(localStorage.getItem('live-proctor-session'))?.access_token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
  },
});

const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const stored = localStorage.getItem('live-proctor-session');
    const { refresh_token } = stored ? JSON.parse(stored) : { refresh_token: null };
    if (refresh_token) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          await api.dispatch(authApi.endpoints.auth.initiate({ refresh_token }));
          result = await baseQuery(args, api, extraOptions);
        } catch (error) {
          console.error(error);
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }
  return result;
};

export default baseQueryWithReauth;
