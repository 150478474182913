// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tox.tox-tinymce {
  height: 150px !important;
  width: -webkit-fill-available;
  position: sticky;
  margin-top: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/editor/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,6BAA6B;EAC7B,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".tox.tox-tinymce {\n  height: 150px !important;\n  width: -webkit-fill-available;\n  position: sticky;\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
