import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

interface Stake {
  id: number;
  name: string;
  status: string;
  version: number;
}

interface TokenInfo {
  id: number;
  token: string;
  type: string;
  name: string;
  stake: Stake;
}

const proviewTokenApi = createApi({
  reducerPath: 'proviewTokenApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getTokens: builder.query<TokenInfo[], void>({
      query: () => ({
        url: `/v1/proview/token`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTokensQuery } = proviewTokenApi;

export default proviewTokenApi;