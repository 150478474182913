import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

const lmsWrapperCreateApi = createApi({
  reducerPath: 'lmsWrapperCreateApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createWrapper: builder.mutation({
      query: (body) => ({
        url: `/v1/wrapper/create`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useCreateWrapperMutation } = lmsWrapperCreateApi;

export default lmsWrapperCreateApi;
