import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/24/solid';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setActiveVideoVolume } from 'common/slice/playbackVideoSlice';

const VolumeBar = () => {
  const dispatch = useAppDispatch();
  const volume = useAppSelector((state) => state.playbackVideo.volume);

  const handleVolumeChange = (value: number) => {
    dispatch(setActiveVideoVolume(value));
  };

  return (
    <div className="flex items-stretch gap-2">
      {volume ? (
        <SpeakerWaveIcon
          onClick={() => handleVolumeChange(0)}
          data-testid="unmute-btn"
          className="cursor-pointer w-5 h-5"
        />
      ) : (
        <SpeakerXMarkIcon
          onClick={() => handleVolumeChange(1)}
          data-testid="mute-btn"
          className="cursor-pointer w-5 h-5"
        />
      )}
      <input
        className="accent-slate-400"
        value={volume}
        type="range"
        min={0}
        max={1}
        step={0.01}
        onChange={(e) => handleVolumeChange(Number(e.target.value))}
      />
    </div>
  );
};

export default VolumeBar;
