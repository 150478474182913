import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';

const AuthError = () => {
  return (
    <div className="fixed inset-0">
      <div className="flex flex-col justify-center gap-3 items-center h-full">
        <h1 className="text-4xl font-bold text-gray-800">
          <ExclamationTriangleIcon className="w-20 h-20 text-center mx-auto my-2 text-red-600"/>
          Authentication Error
        </h1>
        <p className="text-gray-600">You are not authorized to view this page.</p>
        <a href="/login" className="text-blue-500 hover:underline">Click here to login</a>
      </div>
    </div>
  );
};

export default AuthError;
