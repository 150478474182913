import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  id?: string;
  initialValue: Date;
  selectedValue: Date;
  endDate?: Date;
  handleDateChange: (value: Date) => void;
  required?: boolean;
  className?: string;
}

const DateTimePicker = ({ selectedValue, initialValue, handleDateChange, endDate, id, required = false, className }: Props) => {
  return (
    <DatePicker
      id={id}
      className={`${className ? className : "w-64"}`}
      showIcon
      selected={selectedValue ? selectedValue : initialValue}
      onChange={handleDateChange}
      showTimeSelect
      minDate={initialValue ? initialValue : new Date()}
      maxDate={endDate}
      dateFormat="MMMM d, yyyy h:mm:ss aa"
      placeholderText="Select date and time"
      required={required}
    />
  );
};

export default DateTimePicker;
