import { useUpdateStatusMutation } from 'common/api/session';
import { SessionStatus } from 'globals/enums';
import { useAppSelector } from 'app/hooks';

interface Props {
  sessionId: string;
}

const MarkReview = ({ sessionId }: Props) => {
  const { proviewRatingStale, isReviewed } = useAppSelector((state) => state.playbackVideo);
  const [updateStatus] = useUpdateStatusMutation();

  const handleSubmitReview = () => {
    updateStatus({
      session_id: sessionId,
      status: SessionStatus.Reviewed,
    });
  };

  return (
    <div className="mt-auto px-2 py-4 w-fit flex justify-center">
      <button
        disabled={proviewRatingStale || isReviewed}
        onClick={handleSubmitReview}
        className={`flex items-center justify-center  text-xs bg-blue-600 w-full text-white rounded ${
          isReviewed ? 'px-8' : 'px-2'
        } py-2 enabled:hover:opacity-80 disabled:cursor-not-allowed disabled:opacity-70 
        `}
      >
        {isReviewed ? 'Reviewed' : 'Marking as Reviewed'}
      </button>
    </div>
  );
};

export default MarkReview;
