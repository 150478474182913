import { ApolloClient, ApolloLink, concat, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { LocalStorage } from './LocalStorage';
import store from 'app/store';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`GraphQL Error`, message);
    });
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = LocalStorage.getAccessToken();
  if (token) {
    const state = store.getState();
    const currentUserRoles = state.currentUser.roles;
    const currentRole = currentUserRoles[0];

    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
        'x-hasura-role': currentRole,
      },
    });
  }
  return forward(operation);
});

const link = from([
  errorLink,
  new HttpLink({
    uri: process.env.GRAPH_API,
  }),
]);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, link),
});
