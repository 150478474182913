import React from 'react';

type Props = {
  header: string;
}

const Header: React.FC<Props> = (props) => {
  return (
    <div className={`text-xs font-semibold`}>{props.header}</div>
  );
}

export default Header;
