interface DateTimeFormatOptions {
  date: Intl.DateTimeFormatOptions;
  time: Intl.DateTimeFormatOptions;
}

const dateTimeFormatOptions: DateTimeFormatOptions = {
  date: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  time: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
};

const formatDateTime = (timeStamp: string | number | Date, options: Intl.DateTimeFormatOptions) => {
  try {
    const date = new Date(timeStamp);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date);
  } catch (error) {
    return '';
  }
};

export const formattedDateTime = (timeStamp: string | number | Date) => {
  return formatDateTime(timeStamp, dateTimeFormatOptions.date);
};

export const formattedTime = (timeStamp: string | number | Date) => {
  return formatDateTime(timeStamp, dateTimeFormatOptions.time);
};
