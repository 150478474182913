import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { OAuthTokenResponse, ExchangeAuthCodeRequest } from 'components/dashboard/lms-dashboard/types';

export const OauthBaseQuery = fetchBaseQuery({
  baseUrl: process.env.BRIGHTSPACE_AUTH_URL,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

const brighstpaceOauthApi = createApi({
  reducerPath: 'brighstpaceOauthApi',
  baseQuery: OauthBaseQuery,
  endpoints: (builder) => ({
    exchangeAuthCodeForToken: builder.mutation<OAuthTokenResponse, ExchangeAuthCodeRequest>({
      query: ({ code, redirectUri, clientId, clientSecret, scope }) => ({
        url: `/core/connect/token`,
        method: 'POST',
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code,
          redirect_uri: redirectUri,
          client_id: clientId,
          client_secret: clientSecret,
          scope,
        }),
      }),
    }),
  }),
});

export const { useExchangeAuthCodeForTokenMutation } = brighstpaceOauthApi;

export default brighstpaceOauthApi;
