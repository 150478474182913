import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

interface RecomputeProviewIndexPayload {
  session_uuid: string;
}

interface RecomputeProviewIndexResponse {
  rating: string;
  session_uuid: string;
  session_id: number;
}

const postSessionApi = createApi({
  reducerPath: 'post-session',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getRecomputedProviewIndex: builder.mutation<
      RecomputeProviewIndexResponse,
      RecomputeProviewIndexPayload
    >({
      query: (payload) => ({
        url: `v1/proctor-rating`,
        params: {
          session_uuid: payload.session_uuid,
        },
        method: 'PATCH',
      }),
    }),
  }),
});

export const { useGetRecomputedProviewIndexMutation } =
  postSessionApi;

export default postSessionApi;
