import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setActiveTab } from 'common/slice/activeTabSlice';
import Duration from './Duration';
import {
  ArrowTopRightOnSquareIcon,
  PhoneXMarkIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import { endAudioCall } from 'common/slice/audioCallSlice';
import { TABS } from 'common/components/constant';
import { getActiveCallDetails } from 'helpers/messages';
import { Attendee } from 'common/api/proctorType';

interface Props {
  attendees: Attendee[];
}

const AudioCallWidget = ({ attendees }: Props) => {
  const audioCall = useAppSelector((state) => state.audioCall);

  const dispatch = useAppDispatch();

  const activeCallData = getActiveCallDetails(audioCall.audioCallStatuses);

  const handleDisconnect = () => {
    dispatch(endAudioCall({}));
  };

  const handleTabChange = () => {
    dispatch(setActiveTab(TABS.CHAT));
  };

  return (
    activeCallData?.status === 'connected' && (
      <div className="bg-slate-800 rounded-b-2xl fixed top-0">
        <div className="flex justify-start h-10 text-sm text-gray-50 gap-5 items-center p-2">
          <div>
            <button
              onClick={handleDisconnect}
              className={`w-8 h-8 rounded-full grid place-items-center p-2 ${
                !audioCall.isCallActive ? 'bg-slate-500' : 'bg-red-600'
              }`}
              disabled={!audioCall.isCallActive}
            >
              <PhoneXMarkIcon className="h-4 w-4" />
            </button>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-5 mt-1">
              {attendees ? (
                <p>
                  Attendee ID -{' '}
                  {
                    attendees.find((attende) => attende.attendee_id === activeCallData.attendee_id)
                      .attendee_external_id
                  }
                </p>
              ) : (
                '---'
              )}
              {activeCallData.connected_timestamp ? (
                <p className="text-slate-400 flex items-center gap-0.5">
                  <SpeakerWaveIcon className="h-4 w-4" /> Ongoing
                  <Duration startTimestamp={activeCallData.connected_timestamp} />
                </p>
              ) : (
                '---'
              )}
              <p className="underline" onClick={handleTabChange}>
                <button className="flex items-center gap-1">
                  <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  view chat
                </button>
              </p>
            </div>
            {!audioCall.isCallActive && (
              <div className="flex justify-center items-center text-yellow-500 pb-2">
                Please use primary window to disconnect the call
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default AudioCallWidget;
