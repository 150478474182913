import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorage } from 'common/utils/LocalStorage';
import { loginErrorHandler } from './errorHandler';

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  grant_type: string;
  expires_in: number;
}

export interface AuthPayload {
  username: string;
  password: string;
  grant_type: string;
  expires_in: number;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export const authBaseQuery = fetchBaseQuery({
  baseUrl: process.env.AUTH_API_URL,
  prepareHeaders: (headers: Headers) => {
    headers.set('app-id', process.env.REACT_APP_ID as string);
  },
});

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authBaseQuery,
  endpoints: (builder) => ({
    auth: builder.mutation({
      query: (body) => ({
        url: `/v1/user/token`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: LoginResponse): LoginResponse => {
        LocalStorage.setLoginResponse(JSON.stringify(response));
        return response;
      },
    }),
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: '/v1/user/login',
        method: 'POST',
        body,
      }),
      transformErrorResponse(e) {
        return loginErrorHandler(e);
      },
      transformResponse(response: LoginResponse) {
        LocalStorage.setLoginResponse(JSON.stringify(response));
        return response;
      },
    }),
  }),
});

export const { useAuthMutation, useLoginMutation } = authApi;

export default authApi;
