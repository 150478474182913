import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { equalTo, getDatabase, onValue, orderByChild, query, ref } from 'firebase/database';
import store from 'app/store';
import { startSession, updateSession } from 'common/slice/sessionListSlice';
import { Session } from 'globals/interfaces';
import { RecordingTypes } from 'globals/enums';

const sessionListener = createListenerMiddleware();

sessionListener.startListening({
  matcher: isAnyOf(startSession),
  effect: async (action, listenerApi: any) => {
    if (startSession.match(action)) {
      const participantGroupId = action.payload;
      const sessionsQuery = query(
        ref(getDatabase(), 'session_v2'),
        orderByChild('participant_group_id'),
        equalTo(participantGroupId),
      );

      onValue(sessionsQuery, (records) => {
        const data = records.val();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            data[key]['session_id'] = key;
          }
        }
        const sessionData: Session[] = data ? Object.values(data) : [];
        store.dispatch(updateSession(sessionData));
      });
    }
  },
});

export default sessionListener;
