import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import alertApi from 'common/api/alert';
import postSessionApi from 'common/api/post-session-embed';
import store from 'app/store';

const playbackListener = createListenerMiddleware();

playbackListener.startListening({
  matcher: isAnyOf(
    alertApi.endpoints.dismissAlert.matchFulfilled,
    alertApi.endpoints.addAlert.matchFulfilled,
  ),
  effect: (action, listenerApi: any) => {
    const pathArray = window.location.pathname.split('/');
    const session_uuid = pathArray[pathArray.length - 1];
    store.dispatch(
      postSessionApi.endpoints.getPostSession.initiate({ session_uuid }, { forceRefetch: true }),
    );
  },
});

export default playbackListener;
