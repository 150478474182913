import React, { useState, useEffect } from 'react';

import Input from 'common/components/Input';
import Button from 'common/components/Button';
import Checkbox from 'common/components/Checkbox';
import DateTimePicker from 'common/components/DateTimePicker';
import { useCreateWrapperMutation } from 'common/api/wrapper-create';
import { useGetTokensQuery } from 'common/api/proviewTokenQuery';
import { formatReferenceLinks, validateReferenceLinks } from './utils';

type FormData = {
  title: string;
  sessionExternalId: string;
  attendeeExternalId: string;
  expiry: Date | null;
  enableProctoring: boolean;
  enableSecureBrowser: boolean;
  additionalInstructions: string;
  referenceLinks: string;
  isIframeSupported: boolean;
  showEndProctoring: boolean;
  blacklistedSoftwareWindows: string;
  blacklistedSoftwareMac: string;
  proctoring_type: string;
  proview_token: string;
  allowScreenMinimize: boolean;
  allowRecordScreen: boolean;
  redirectUrl?: string;
};

type BodyType = {
  session_title: string;
  session_external_id: string;
  attendee_external_id: string;
  expiry: string;
  proview_token?: string;
  type?: string;
  is_iframe_supported?: boolean;
  redirect_url?: string;
  show_end_proctoring?: boolean;
  additional_instruction?: string[];
  reference_links?: string[];
  is_secure_browser?: boolean;
  secure_browser?: {
    blacklisted_softwares_windows?: string[];
    blacklisted_softwares_mac?: string[];
    is_minimize?: boolean;
    is_record_screen?: boolean;
  };
};

const FormComponent = () => {
  const [createWrapper, { isLoading, error, data }] = useCreateWrapperMutation();
  const [isValidReferenceLinks, setIsValidReferenceLinks] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    sessionExternalId: '',
    attendeeExternalId: '',
    expiry: null,
    enableProctoring: false,
    enableSecureBrowser: false,
    additionalInstructions: '',
    referenceLinks: '',
    isIframeSupported: false,
    showEndProctoring: true,
    blacklistedSoftwareWindows: '',
    blacklistedSoftwareMac: '',
    proctoring_type: '',
    proview_token: '',
    allowScreenMinimize: true,
    allowRecordScreen: true,
    redirectUrl: '',
  });

  const {
    data: tokenData,
    error: tokenError,
    isLoading: isTokenLoading,
  } = useGetTokensQuery();

  const [hasProviewTokens, setHasProviewTokens] = useState(false);

  useEffect(() => {
    if (tokenData && tokenData.length > 0) {
      setHasProviewTokens(true);
    } else {
      setHasProviewTokens(false);
      setFormData(prevData => ({
        ...prevData,
        enableProctoring: false
      }));
    }
  }, [tokenData]);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const clearSubmissionState = () => {
    setHasSubmitted(false);
  };

  useEffect(() => {
    const isValid = validateReferenceLinks(formData.referenceLinks);
    setIsValidReferenceLinks(isValid);
  }, [formData.referenceLinks]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    clearSubmissionState();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const name = id.replace('checkbox-', '');
    setFormData((prevData) => ({
      ...prevData,
      [name]: e.target.checked,
    }));
    clearSubmissionState();
  };

  const handleDateChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      expiry: date,
    }));
    clearSubmissionState();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const {
      title,
      sessionExternalId,
      attendeeExternalId,
      expiry,
      enableProctoring,
      enableSecureBrowser,
      additionalInstructions,
      referenceLinks,
      isIframeSupported,
      showEndProctoring,
      blacklistedSoftwareWindows,
      blacklistedSoftwareMac,
      proctoring_type,
      proview_token,
      allowScreenMinimize,
      allowRecordScreen,
      redirectUrl,
    } = formData;

    const formattedReferenceLinks = formatReferenceLinks(referenceLinks);

    const body: BodyType = {
      session_title: title,
      session_external_id: sessionExternalId,
      attendee_external_id: attendeeExternalId,
      expiry: formData.expiry ? formData.expiry.toISOString() : '',
    };
    if (redirectUrl) {
      body.redirect_url = redirectUrl;
    }

    if (enableProctoring) {
      body.proview_token = proview_token;
      body.type = proctoring_type;
      body.is_iframe_supported = isIframeSupported;

      if (!showEndProctoring) {
        body.show_end_proctoring = false;
      }
      if (additionalInstructions) {
        body.additional_instruction = [additionalInstructions];
      }
      if (formattedReferenceLinks.length > 0) {
        body.reference_links = formattedReferenceLinks;
      }
    }

    if (enableSecureBrowser) {
      body.is_secure_browser = true;
      body.secure_browser = {
        blacklisted_softwares_windows: blacklistedSoftwareWindows
          ? blacklistedSoftwareWindows.split(',')
          : [],
        blacklisted_softwares_mac: blacklistedSoftwareMac ? blacklistedSoftwareMac.split(',') : [],
      };

      if (!allowScreenMinimize) {
        body.secure_browser.is_minimize = false;
      }
      if (!allowRecordScreen) {
        body.secure_browser.is_record_screen = false;
      }
    }

    try {
      await createWrapper(body);
      setHasSubmitted(true);
    } catch (err) {
      console.error('Error creating wrapper:', err);
      setHasSubmitted(true);
    }
  };

  return (
    <div className="h-full overflow-auto pb-8">
      <div className="bg-white rounded-sm shadow-2xl border border-gray-200">
        <div className="px-8 py-6 text-white bg-emerald-900 hover:bg-emerald-700 rounded-2xl">
          <h2 className="text-3xl font-bold mb-2">Create Wrapper Link</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="p-8">
            <div className="space-y-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <Input
                  label="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                  required
                />
                <Input
                  label="Session External Id"
                  name="sessionExternalId"
                  value={formData.sessionExternalId}
                  onChange={handleInputChange}
                  className="focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                  required
                />
                <Input
                  label="Attendee External Id"
                  name="attendeeExternalId"
                  value={formData.attendeeExternalId}
                  onChange={handleInputChange}
                  className="focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                  required
                />
                <Input label="Redirect URL" name="redirectUrl" value={formData.redirectUrl} onChange={handleInputChange} className="focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out" />

                <div className="flex flex-col">
                  <label htmlFor="expiry" className="required text-sm font-medium text-gray-700">
                    Expiry
                  </label>
                  <DateTimePicker
                    id="expiry"
                    className='w-full text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out'
                    initialValue={null}
                    selectedValue={formData.expiry}
                    handleDateChange={handleDateChange}
                    required={true}
                  />
                </div>
              </div>
              <div className="space-y-6">
                <div className="flex items-center justify-between pb-4 border-b border-gray-200">
                  <div className="flex items-center space-x-3">
                    <Checkbox
                      id="enableProctoring"
                      name="enableProctoring"
                      size="sm"
                      label={!hasProviewTokens && !isTokenLoading ? "Enable proctoring (Proctoring cannot be enabled as there are no available tokens)" : "Enable proctoring"}
                      checked={formData.enableProctoring}
                      onChange={handleCheckboxChange}
                      disabled={!hasProviewTokens}
                    />
                  </div>
                </div>
                <div
                  className={`transition-all duration-300 ease-in-out overflow-hidden ${formData.enableProctoring ? 'block' : 'hidden'
                    }`}
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6 bg-gray-50 rounded-lg border border-gray-200 shadow-inner">
                    <div>
                      <label
                        htmlFor="proctoring_type"
                        className="block text-sm font-medium text-gray-700 mb-2 required"
                      >
                        Proctoring Type
                      </label>
                      <select
                        id="proctoring_type"
                        required={formData.enableProctoring}
                        name="proctoring_type"
                        onChange={handleInputChange}
                        value={formData.proctoring_type}
                        data-testid="select-proctoring-type"
                        className="w-full p-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
                      >
                        <option value="" disabled>
                          Select Proctoring Type
                        </option>
                        <option value="ai_proctor">AI Proctoring</option>
                        <option value="record_and_review">Record and Review</option>
                        <option value="live_proctor">Live Proctoring</option>
                        <option value="offline_proctor">Offline Proctoring</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="proview_token"
                        className="block text-sm font-medium text-gray-700 mb-2 required"
                      >
                        Proview Token
                      </label>
                      <select
                        id="proview_token"
                        required={formData.enableProctoring}
                        name="proview_token"
                        onChange={handleInputChange}
                        value={formData.proview_token}
                        data-testid="select-proview_token"
                        className="w-full p-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
                      >
                        <option value="" disabled>
                          Select Proview Token
                        </option>
                        {tokenData &&
                          tokenData.map((token) => (
                            <option key={token?.token} value={token?.token}>
                              {token?.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-span-full">
                      <Input
                        label="Additional Instructions"
                        id="additionalInstructions"
                        name="additionalInstructions"
                        value={formData.additionalInstructions}
                        onChange={handleInputChange}
                        className="w-full focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                      />
                    </div>
                    <div className="col-span-full">
                      <label
                        htmlFor="referenceLinks"
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        Reference Links
                      </label>
                      <textarea
                        id="referenceLinks"
                        name="referenceLinks"
                        value={formData.referenceLinks}
                        onChange={handleInputChange}
                        className="w-full h-32 px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out resize-y"
                        placeholder="Enter valid reference links in the format [caption](url) with each reference link on a separate line. For example: [talview](https://talview.com)"
                      />
                      <p className="text-sm text-gray-500 mt-2">
                        {!isValidReferenceLinks && 'Please enter valid reference links in the format [caption](url) with each reference link on a separate line. For example: [talview](https://talview.com)'}
                      </p>
                    </div>
                    <div className="flex items-center space-x-3">
                      <Checkbox
                        id="isIframeSupported"
                        name="isIframeSupported"
                        size="sm"
                        label="Is Iframe Supported"
                        checked={formData.isIframeSupported}
                        onChange={handleCheckboxChange}
                        disabled={false}
                      />
                    </div>
                    <div className="flex items-center space-x-3">
                      <Checkbox
                        id="showEndProctoring"
                        name="showEndProctoring"
                        size="sm"
                        label="Show End Proctoring"
                        checked={formData.showEndProctoring}
                        onChange={handleCheckboxChange}
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6">
                <div className="flex items-center justify-between pb-4 border-b border-gray-200">
                  <div className="flex items-center space-x-3">
                    <Checkbox
                      id="enableSecureBrowser"
                      name="enableSecureBrowser"
                      size="sm"
                      label="Enable Secure Browser"
                      checked={formData.enableSecureBrowser}
                      onChange={handleCheckboxChange}
                      disabled={false}
                    />
                  </div>
                </div>
                <div
                  className={`transition-all duration-300 ease-in-out overflow-hidden ${formData.enableSecureBrowser ? 'block' : 'hidden'
                    }`}
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6 bg-gray-50 rounded-lg border border-gray-200 shadow-inner">
                    <textarea
                      name="blacklistedSoftwareWindows"
                      value={formData.blacklistedSoftwareWindows}
                      onChange={handleInputChange}
                      placeholder="Blacklisted Software (Windows)"
                      className="w-full p-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 col-span-full transition duration-150 ease-in-out"
                      rows={3}
                    />
                    <textarea
                      name="blacklistedSoftwareMac"
                      value={formData.blacklistedSoftwareMac}
                      onChange={handleInputChange}
                      placeholder="Blacklisted Software (Mac)"
                      className="w-full p-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 col-span-full transition duration-150 ease-in-out"
                      rows={3}
                    />
                    <div className="flex">
                      <div className="flex items-center space-x-3">
                        <Checkbox
                          id="allowScreenMinimize"
                          name="allowScreenMinimize"
                          size="sm"
                          label="Allow Screen Minimize"
                          checked={formData.allowScreenMinimize}
                          onChange={handleCheckboxChange}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex items-center space-x-3">
                        <Checkbox
                          id="allowRecordScreen"
                          name="allowRecordScreen"
                          size="sm"
                          label="Allow Record Screen"
                          checked={formData.allowRecordScreen}
                          onChange={handleCheckboxChange}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {hasSubmitted && error && (
            <div className="mb-4 p-4 text-sm rounded-md bg-red-100 text-red-800">
              Failed to Create wrapper link
            </div>
          )}
          {hasSubmitted && data && (
            <div className="mb-4 p-4 text-sm rounded-md bg-green-100 text-green-800">
              <p data-testid="wrapper-link-success">Wrapper link created successfully</p>
            </div>
          )}
          <div className="px-8 py-6 bg-gray-50 border-t border-gray-200 rounded-b-lg flex justify-center items-center">
            <Button
              isLoading={isLoading}
              type="submit"
              label="Create Link"
              className="bg-emerald-900 hover:bg-emerald-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormComponent;
