import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

const alertApi = createApi({
  reducerPath: 'alertApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    addAlert: builder.mutation({
      query: (payload) => ({
        url: `/v1/admin/alert`,
        method: 'POST',
        body: payload,
      }),
    }),
    dismissAlert: builder.mutation({
      query: (payload) => ({
        url: `v1/admin/alert/${payload.alertId}`,
        method: 'PATCH',
        body: {
          suspend: true,
          description: payload.description,
          session_id: payload.sessionUuid
        },
      }),
    }),
  }),
});

export const { useAddAlertMutation, useDismissAlertMutation } = alertApi;

export default alertApi;
