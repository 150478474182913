export const formatDate = (dateStr: string) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  //@ts-ignore
  return new Date(dateStr).toLocaleDateString(undefined, options);
};
