import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { formatDate } from 'helpers/date';
import { useAppSelector } from 'app/hooks';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { slideAlertIntoView } from 'helpers/playback';
import EnlargeView from './EnlargeView';

interface Grab {
  img_src: string;
  timestamp: string;
}
const MediagrabReel = ({ sessionFiles }) => {
  const [isOpen, setIsOpen] = useState(false);
  const reelContainerRef = useRef<HTMLDivElement>();
  const currentTimeStamp = useAppSelector((state) => state.playbackVideo.currentPlayingTime);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [selectedGrab, setSelectedGrab] = useState<Grab | null>(null);

  useEffect(() => {
    if (currentTimeStamp) {
      slideAlertIntoView(currentTimeStamp, sessionFiles, reelContainerRef, true);
    }
  }, [currentTimeStamp]);

  const handleGrabClick = (fileDetails) => {
    const grab = {
      img_src: fileDetails.file.resource_url,
      timestamp: formatDate(fileDetails.timestamp),
    };
    setSelectedGrab(grab);
    setShowPreview(true);
  };

  return (
    sessionFiles?.length > 0 && (
      <div className="flex-0">
        <div className="flex items-center gap-2">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center gap-2 px-3 py-2 font-medium text-gray-800 rounded-full bg-slate-300"
          >
            <span>Image & Screenshots</span>
            {isOpen ? (
              <MinusCircleIcon className="w-5 h-5" />
            ) : (
              <PlusCircleIcon className="w-5 h-5" />
            )}
          </button>
          <div className="flex-1 h-[2px] bg-slate-300"></div>
        </div>
        <div
          data-testid="image-container"
          className="flex items-center overflow-x-scroll whitespace-nowrap relative border-b-2 border-slate-100"
          ref={reelContainerRef}
        >
          {isOpen &&
            sessionFiles.map((grab, index) => (
              <div
                onClick={() => handleGrabClick(grab)}
                className="flex flex-col items-center py-2 align-middle"
                key={`${grab.timestamp}-image-grab`}
              >
                <img
                  className="inline-block min-w-[100px] max-w-[100px] m-1 transition-all hover:scale-110"
                  src={grab.file.resource_url}
                  alt="screen/camera grab"
                />
                <div className="text-xs">{dayjs(grab.timestamp).format('hh:mm:ss')}</div>
              </div>
            ))}
        </div>
        <EnlargeView
          selectedGrab={selectedGrab}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
        />
      </div>
    )
  );
};

export default MediagrabReel;
