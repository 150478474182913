import React, { useContext } from 'react';
import moment from 'moment';
import RadioButton from 'common/components/RadioButton';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import useDashboardParticipants from 'hooks/useDashboardParticipants';

const Slots: React.FC = () => {
  const { slots, setCurrentSlot, currentSlot, setSessions } =
    useContext<DashboardContextType>(DashboardContext);

  const onClick = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    slots.map((slot) => {
      if (id === slot.id) {
        setCurrentSlot(slot);
      }
    });
  };

  return (
    <div className="flex flex-col">
      {slots.map((slot) => (
        <div
          key={slot.id}
          className="flex flex-row justify-between items-center w-full p-1 hover:cursor-pointer"
        >
          <div className="flex flex-col w-full">
            <div className="text-[12px] font-semibold">{slot.event.name}</div>
            <div className="text-[10px]">
              {moment(slot.starts_at).format('hh:mm A')} - {moment(slot.ends_at).format('hh:mm A')}
            </div>
          </div>
          <RadioButton
            id={slot.id}
            name={slot.event.name}
            size="sm"
            onChange={onClick}
            checked={slot.id === currentSlot.id}
            disabled={false}
          />
        </div>
      ))}
    </div>
  );
};

export default Slots;
