import React from 'react';
import classNames from 'classnames';

type Props = {
  label: string;
  color: string;
  value: number | string;
  percentage: number;
};

const ProgressBar: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-row space-x-2 w-full justify-center items-center">
      <div className="flex text-xs w-1/4">{props.label}</div>
      <div className="flex w-full bg-gray-200 rounded-full dark:bg-gray-700 items-center">
        <div
          className={classNames(
            `flex bg-${props.color}-600 h-1 rounded-full dark:bg-${props.color}-500`
          )}
          style={{ width: `${props.percentage}%` }}
        ></div>
      </div>
      <div className="flex text-xs">{props.value}</div>
    </div>
  );
};

export default ProgressBar;
