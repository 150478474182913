interface Props {
  os: string;
  os_version: string;
  browser: string;
  browser_version: string;
}
const SystemInfo = ({ os, os_version, browser, browser_version }: Props) => {
  const headerStyles = `text-sm text-slate-800 font-medium`;
  return (
    <div className="grid grid-cols-2 gap-4 text-xs text-slate-700 p-2">
      <div>
        <p className={headerStyles}>Operating System</p>
        <p>{os || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Version</p>
        <p>{os_version || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Browser</p>
        <p>{browser || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Version</p>
        <p>{browser_version || '--'}</p>
      </div>
    </div>
  );
};

export default SystemInfo;
