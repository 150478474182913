import { useAppDispatch, useAppSelector } from 'app/hooks';
import { endAudioCall, startAudioCall } from 'common/slice/audioCallSlice';
import { PhoneIcon, PhoneXMarkIcon } from '@heroicons/react/24/outline';
import { getActiveCallDetails } from 'helpers/messages';
import { isSessionEnded } from 'helpers/session';

interface Props {
  session_id: string;
  session_uuid: string;
  attendee_id: number;
  session_status: string;
}

const AudioCall = ({ session_status, session_id, session_uuid, attendee_id }: Props) => {
  const dispatch = useAppDispatch();
  const audioCall = useAppSelector((state) => state.audioCall);
  const activeCallDetails = getActiveCallDetails(audioCall.audioCallStatuses);

  const audioCallStatus = activeCallDetails?.status;
  const isBusy = attendee_id !== activeCallDetails?.attendee_id;

  const handleConnect = () => {
    dispatch(startAudioCall({ session_id, session_uuid }));
  };

  const handleDisconnect = () => {
    dispatch(endAudioCall({ session_id, session_uuid }));
  };

  const isSessionOver = isSessionEnded(session_status);

  return (
    <div className="ml-2">
      {audioCallStatus === 'connected' ? (
        <button
          onClick={handleDisconnect}
          disabled={isBusy || !audioCall.isCallActive}
          className={`text-white w-8 h-8 rounded-full grid place-items-center p-2 ${
            isBusy || !audioCall.isCallActive ? 'bg-slate-500' : 'bg-red-600'
          }`}
        >
          {isBusy ? <PhoneIcon className="h-4 w-4" /> : <PhoneXMarkIcon className="h-4 w-4" />}
        </button>
      ) : (
        <button
          onClick={handleConnect}
          className={`${
            isSessionOver ? 'bg-slate-500' : 'bg-blue-700'
          } text-white w-8 h-8 rounded-full grid place-items-center p-2`}
          disabled={isSessionOver}
        >
          <PhoneIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default AudioCall;
