import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CallData {
  attendee_id: number;
  participant_group_id: number;
  requested_timestamp: string;
  status: string;
  connected_timestamp: string;
}

export interface TwilioAudioState {
  audioCallStatuses: Record<string, CallData>[];
  isCallActive: boolean;
}

const initialState: TwilioAudioState = {
  audioCallStatuses: [],
  isCallActive: false,
};

const twilioAudioCall = createSlice({
  name: 'twilioAudio',
  initialState,
  reducers: {
    updateCallData: (state, { payload }: PayloadAction<any>) => {
      state.audioCallStatuses = payload;
      return state;
    },
    startAudioCall: (state, { payload }) => {
      return { ...state, isCallActive: true };
    },
    endAudioCall: (state, { payload }) => {
      return { ...state, isCallActive: false };
    },
  },
});

export const { startAudioCall, endAudioCall, updateCallData } = twilioAudioCall.actions;

export default twilioAudioCall.reducer;
