import { createApi } from '@reduxjs/toolkit/query/react';
import { Attendee, InstructionsResponse, Slot, SlotDetailQueryParams } from 'common/api/proctorType';
import baseQueryWithReauth from './base';
import { orderBy } from 'lodash';

interface SlotResponse {
  attendees: Attendee[];
}

const proctor = createApi({
  reducerPath: 'procotrApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSlotDetail: builder.query<Slot, SlotDetailQueryParams>({
      query: (params) => ({
        url: `v1/proctor-event/${params.event_id}/attendee?slot_id=${params.slot_id}`,
        method: 'GET',
      }),
      transformResponse: (response: SlotResponse) => {
        if (!response?.attendees?.length) {
          return null;
        }
        const { participant_group_id, slot_starts_at, slot_ends_at } = response.attendees[0];

        const attendeesWithNumber: Attendee[] = orderBy(
          response.attendees,
          ['attendee_id']['asc'],
        )

        const slotObject: Slot = {
          attendees: attendeesWithNumber,
          participant_group_id,
          slot_starts_at,
          slot_ends_at,
        };
        return slotObject;
      },
    }),
    getInstructions: builder.query<InstructionsResponse, SlotDetailQueryParams>({
      query: (params) => ({
        url: `v1/proctor-event/${params.event_id}?slot_id=${params.slot_id}&role=proctor`,
        method: 'GET',
      }),
    }),
    createStream: builder.mutation({
      query: (body) => ({
        url: `v1/stream/participant`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetSlotDetailQuery, useGetInstructionsQuery, useCreateStreamMutation } = proctor;

export default proctor;
