import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/24/outline';
import Sidebar from '../sidebar/Sidebar';
import { Mixpanel } from '../../../Mixpanel';

const Dashboard = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [selectedOption, setSelectedOption] = useState('');
  const [showDate, setShowDate] = useState(true);

  const currentDate = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

  useEffect(() => {
    const pathMapping = {
      '/dashboard/lms': {
        option: 'LMS Dashboard',
        showDateSetting: false,
        trackEvent: 'proview_console_lms_dashboard_opened',
      },
      '/dashboard/wrapper/form': {
        option: 'Wrapper Links Management / Create',
        showDateSetting: false,
        trackEvent: 'proview_console_wrapper_links_management_form_opened',
      },
      '/dashboard/wrapper': {
        option: 'Wrapper Links Management',
        showDateSetting: false,
        trackEvent: 'proview_console_wrapper_links_management_opened',
      },
      default: {
        option: 'Proctoring Dashboard',
        showDateSetting: true,
        trackEvent: 'proview_console_proctoring_dashboard_opened',
      },
    };
    const matchedPath = Object.keys(pathMapping).find((path) => currentPath.includes(path));
    const { option, showDateSetting, trackEvent } = pathMapping[matchedPath] || pathMapping.default;
    setShowDate(showDateSetting);
    Mixpanel.track(trackEvent);
    setSelectedOption(option);
  }, [currentPath]);

  return (
    <div className="flex h-screen text-gray-600">
      <Sidebar />
      <div className="flex flex-1 flex-col px-[20px] py-[18px] bg-gray-100 overflow-hidden">
        <div className="flex space-x-2 items-center">
          <div className="text-base font-medium text-gray-600">{selectedOption}</div>
          {showDate && (
            <>
              <CalendarIcon className="h-4 w-4" />
              <div className="text-xs font-normal">{currentDate}</div>
            </>
          )}
        </div>
        <div className="mt-3 h-full overflow-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
