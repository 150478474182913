import { useAppDispatch } from 'app/hooks';
import { useUpdateStatusMutation, useUpdateStatusReasonMutation } from 'common/api/session';
import { closeModal } from 'common/slice/modalSlice';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { SessionStatus } from 'globals/enums';
import { useState } from 'react';

interface Props {
  sessionId: string;
}

const TerminateModal = ({ sessionId }: Props) => {
  const [terminateReason, setTerminateReason] = useState('');
  const dispatch = useAppDispatch();
  const [updateStatus] = useUpdateStatusMutation();
  const [updateStatusReason] = useUpdateStatusReasonMutation();

  const updateSessionStatus = async () => {
    dispatch(closeModal({}));
    await updateStatus({
      session_id: sessionId,
      status: SessionStatus.Terminated,
    });
    if (terminateReason.trim() !== '') {
      await updateStatusReason({
        session_id: sessionId,
        data: { termination_reason: terminateReason },
      });
    }
  };

  return (
    <div className="flex gap-3">
      <span className="w-10 h-10 bg-red-200 text-red-600 rounded-full flex p-2">
        <XCircleIcon />
      </span>
      <div className="flex-1 flex flex-col mt-1.5">
        <p className="font-bold text-lg">Terminate Candidate?</p>
        <p className="mb-2 text-zinc-500">You are terminating this candidate from this session</p>
        <label htmlFor="terminateComments" className="text-zinc-500">
          Comments <span className="text-sm">(if any - Min 30 characters)</span>
        </label>
        <textarea
          id="terminateComments"
          value={terminateReason}
          onChange={(e) => setTerminateReason(e.target.value)}
          className="p-2 rounded-md border w-full"
          cols={10}
          rows={3}
        ></textarea>
        <div className="mt-3 ml-auto flex gap-1 text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button
            disabled={terminateReason && terminateReason.length < 30}
            className="primary-btn"
            onClick={updateSessionStatus}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TerminateModal;
