import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CurrentFeed = 'primary' | 'secondary' | 'screenshare' | 'auto-rotate' | '';

export interface MediaSetting {
  attendeeId: number;
  currentFeed: CurrentFeed;
}

export interface AutoRotateFeed {
  currentAutoRotateFeed: CurrentFeed;
}

export interface MediaSettings {
  currentFeed: CurrentFeed;
  currentAutoRotateFeed: CurrentFeed;
  mediaSettings: MediaSetting[];
  availableSettings: CurrentFeed[];
}

const initialState: MediaSettings = {
  currentFeed: '',
  currentAutoRotateFeed: '',
  mediaSettings: [],
  availableSettings: [],
};

const mediaSettingsSlice = createSlice({
  name: 'mediaSettings',
  initialState,
  reducers: {
    updateMediaSetting: (state, action: PayloadAction<MediaSetting>) => {
      const { attendeeId, currentFeed } = action.payload;

      const existingSessionIndex = state.mediaSettings.findIndex(
        (s) => s.attendeeId === attendeeId,
      );
      const updatedSession: MediaSetting = {
        attendeeId,
        currentFeed,
      };

      if (existingSessionIndex === -1) {
        state.mediaSettings = [...state.mediaSettings, updatedSession];
      } else {
        state.mediaSettings = state.mediaSettings.map((session, index) => {
          if (index === existingSessionIndex) {
            return { ...session, ...updatedSession };
          }
          return session;
        });
      }
    },
    updateCurrentAutoRotateFeed: (state, action: PayloadAction<AutoRotateFeed>) => {
      const { currentAutoRotateFeed } = action.payload;
      return { ...state, currentAutoRotateFeed: currentAutoRotateFeed };
    },
    updateGlobalMediaSettings: (
      state,
      action: PayloadAction<{
        attendeeIds: 'all' | number[];
        currentFeed: CurrentFeed;
      }>,
    ) => {
      const { attendeeIds, currentFeed } = action.payload;

      if (attendeeIds === 'all') {
        return {
          ...state,
          mediaSettings: [],
          currentFeed: currentFeed,
        };
      } else {
        const updatedSettings = state.mediaSettings.map((existingSetting) => {
          if (attendeeIds.includes(existingSetting.attendeeId)) {
            return {
              ...existingSetting,
              currentFeed: currentFeed,
            };
          }
          return existingSetting;
        });

        attendeeIds.forEach((attendeeId) => {
          if (!updatedSettings.some((setting) => setting.attendeeId === attendeeId)) {
            updatedSettings.push({ attendeeId, currentFeed: currentFeed });
          }
        });

        return { ...state, mediaSettings: updatedSettings };
      }
    },
    setAvailableSettings: (state, action) => {
      state.availableSettings = action.payload;
      state.currentAutoRotateFeed = action.payload[0];
      state.currentFeed = action.payload[0];
    },
  },
});

export const {
  updateMediaSetting,
  updateCurrentAutoRotateFeed,
  updateGlobalMediaSettings,
  setAvailableSettings,
} = mediaSettingsSlice.actions;

export default mediaSettingsSlice.reducer;
