import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useCreateSASTokenMutation } from 'common/api/sasToken';
import { setSessionSASToken } from 'common/slice/SessionSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { handleError } from '../helpers/sentry';
import { useEffect } from 'react';

interface SASTokenResult {
  data?: string;
  error?: FetchBaseQueryError | SerializedError;
}

const useAzureBlob = (sessions) => {
  const dispatch = useAppDispatch();
  const [getSASToken] = useCreateSASTokenMutation();
  const token = useAppSelector((state) => state.session.token);

  const createAzureToken = async (session_id: string, session_uuid: string) => {
    // eslint-disable-next-line no-prototype-builtins
    if (token.hasOwnProperty(session_id)) return;
    try {
      const { data: sasToken }: SASTokenResult = await getSASToken({
        container_name: session_uuid,
        session_id: session_id,
      });

      dispatch(
        setSessionSASToken({
          [session_id]: {
            value: sasToken,
          },
        }),
      );
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    if (sessions) {
      sessions.forEach(
        (session: { [key: string]: unknown; session_id: string; session_uuid: string }) => {
          if (session.session_id && session.session_uuid) {
            createAzureToken(session.session_id, session.session_uuid);
          }
        },
      );
    }
  }, [sessions]);
};

export default useAzureBlob;
