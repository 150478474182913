import { useEffect, useState } from "react";
import { useDatabase, useDatabaseObjectData } from "reactfire";
import { ref } from "firebase/database";

export const useOnlineProctors = (slotId) => {
  const [onlineProctors, setOnlineProctors] = useState({});
  const database = useDatabase();
  const proctorRef = ref(database, `/proctor_presence/${slotId}`);
  const { data } = useDatabaseObjectData(proctorRef);

  useEffect(() => {
    if (data) {
      setOnlineProctors(data);
    }
  }, [data]);

  return onlineProctors;
};
