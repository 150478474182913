import React from 'react';
import Button from '../../../../common/components/Button';
interface BrightSpaceConfigFormProps {
    config: {
        lmsUrl: string;
        proviewAdminUsername: string;
        proviewAdminPassword: string;
        refreshToken: string;
        appId: string;
    };
    setConfig: React.Dispatch<React.SetStateAction<any>>;
    handleUpdateConfiguration: (e: React.FormEvent<HTMLFormElement>) => void;
}

const BrightSpaceConfigForm: React.FC<BrightSpaceConfigFormProps> = ({ config, setConfig, handleUpdateConfiguration }) => (
    <form onSubmit={handleUpdateConfiguration}>
        {Object.keys(config).map(key => (
            <div className="mb-4" key={key}>
                <label className="block mb-2">{key.replace(/([A-Z])/g, ' $1').toUpperCase()}:</label>
                <input
                    type={'text'}
                    value={config[key]}
                    onChange={(e) => setConfig(prev => ({ ...prev, [key]: e.target.value }))}
                    className="p-2 border border-gray-300 rounded w-full"
                />
            </div>
        ))}
        <Button
            type="submit"
            className="bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center"
            label="Update Configuration"
        />
    </form>
);

export default BrightSpaceConfigForm;
