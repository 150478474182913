import { FixedSizeList as List } from 'react-window';
import { useAppSelector } from 'app/hooks';
import { getSignedUrl } from 'helpers/prechecks';
import { Session } from 'globals/interfaces';
import { formatDate } from 'helpers/date';
import { gutterSize, cameraGrabListHeight, listItemSize } from 'common/components/constant';
import { useState } from 'react';
import EnlargeView from './EnlargeView';

interface Props {
  session: Session;
  sessionId: string;
}

const CameraAndScreenGrabsList = ({ sessionId, session }: Props) => {
  const token = useAppSelector((state) => state.session?.token);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [selectedGrab, setSelectedGrab] = useState<any>(null);
  const files = session?.session_files ? Object.values(session.session_files).reverse() : [];

  const calculateWeight = () => {
    return (window.innerWidth * 58) / 100 + 2 * gutterSize;
  };

  const isrenderList = token && token[sessionId]?.value;

  const handleGrabClick = (file) => {
    const grab = {
      img_src: getSignedUrl(session.session_uuid, file.path, token[sessionId]?.value),
      timestamp: formatDate(file.timestamp),
    };
    setSelectedGrab(grab);
    setShowPreview(true);
  };
  return (
    isrenderList && (
      <>
        <List
          itemData={files}
          height={cameraGrabListHeight}
          itemCount={files.length}
          itemSize={listItemSize + gutterSize}
          layout="horizontal"
          width={calculateWeight()}
        >
          {({ data, index, style }) => {
            if (!data[index]) return null;
            const file = data[index];
            return (
              <div
                key={file.timestamp}
                style={{
                  ...style,
                  marginLeft: gutterSize,
                  marginRight: gutterSize,
                  borderBottom: '3px solid #CBD5E1',
                }}
              >
                <div
                  className="flex flex-col items-center align-middle"
                  onClick={() => handleGrabClick(file)}
                >
                  <div className="screen-grab-container">
                    <img
                      src={getSignedUrl(session.session_uuid, file.path, token[sessionId]?.value)}
                      alt="screen or camera grab"
                    />
                  </div>
                  <div className="mt-2">{formatDate(file.timestamp)}</div>
                </div>
              </div>
            );
          }}
        </List>
        <EnlargeView
          selectedGrab={selectedGrab}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
        />
      </>
    )
  );
};

export default CameraAndScreenGrabsList;
