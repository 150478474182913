import React, { useEffect, useState } from 'react';
import jwtDecoder from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { setCurrentUser } from 'common/slice/currentUserSlice';
import DisplayLogo from 'common/components/DisplayLogo';
import Input from 'common/components/Input';
import Button from 'common/components/Button';
import { useLoginMutation } from 'common/api/auth';
import { CustomError } from 'common/api/errorHandler';
import { Mixpanel } from '../../Mixpanel';

enum Role {
  Proctor = 'proctor',
  TestAdmin = 'test-admin',
}

interface Token {
  user: {
    id: number;
    email: string;
    external_id: string;
    organization_id: number;
    username: string;
  };
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': Role[];
  };
}

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [login, { data: loginResponse, isLoading, isError, error }] = useLoginMutation();

  const loginRequest = () => {
    Mixpanel.track("proview_console_login_initated");
    login({ username, password });
  };

  useEffect(() => {
    if (loginResponse) {
      const decodedToken = jwtDecoder<Token>(loginResponse.access_token);
      dispatch(
        setCurrentUser({
          id: decodedToken.user.id,
          email: decodedToken.user.email,
          external_id: decodedToken.user.organization_id,
          username: decodedToken.user.username,
          roles: decodedToken['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
        }),
      );
      setIsAuthenticated(true);
    }
  }, [loginResponse, dispatch]);

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="flex flex-col lg:flex-row h-screen w-screen">
      <div className="lg:flex w-full lg:w-1/2 hidden justify-center items-center">
        <DisplayLogo type="long" />
      </div>
      <div className="flex flex-col w-full h-full lg:w-1/2 justify-center items-center space-y-14 p-10">
        <DisplayLogo type="long" className="lg:hidden" />

        <div className="flex flex-col w-full md:w-3/4 space-y-4 justify-center items-center">
          {isError && (
            <div className="text-sm font-semibold text-red-800">
              {(error as CustomError).message}
            </div>
          )}
          <Input type="text" label="Email" onChange={(event) => setUsername(event.target.value)} />
          <Input
            type="password"
            label="Password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            label="Login"
            className="bg-blue-500 p-2 rounded w-full text-slate-100"
            isLoading={isLoading}
            onClick={loginRequest}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
