import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ActiveAttendee {
  attendee_id: number;
  attendee_external_id: string;
  attendee_first_name: string;
  attendee_last_name: string;
  attendee_number: string;
  attendee_email: string;
  slot_id: number;
  participant_group_id: number;
  isDetailView: boolean;
  selectedAttendees: number[];
}

export const initialState: ActiveAttendee = {
  attendee_id: null,
  attendee_external_id: '',
  attendee_first_name: '',
  attendee_last_name: '',
  attendee_number: '',
  attendee_email: '',
  slot_id: null,
  participant_group_id: null,
  isDetailView: false,
  selectedAttendees: [],
};

const activeAttendeeSlice = createSlice({
  name: 'activeAttendee',
  initialState,
  reducers: {
    setActiveAttendee: (state, { payload }) => {
      const attendee = [payload].reduce((acc, attendee) => {
        acc = {
          ...acc,
          attendee_id: attendee.attendee_id,
          attendee_external_id: attendee.attendee_external_id,
          attendee_first_name: attendee.attendee_first_name,
          attendee_last_name: attendee.attendee_last_name,
          attendee_number: attendee.attendee_number,
          attendee_email: attendee.attendee_email,
          slot_id: attendee.slot_id,
          participant_group_id: attendee.participant_group_id,
          isDetailView: attendee.isDetailView,
        };
        return acc;
      }, {});

      return { ...state, ...attendee };
    },
    setDetailView: (state, { payload }: PayloadAction<{ isDetailView: boolean }>) => {
      return { ...state, isDetailView: payload.isDetailView };
    },
    setSelectedAttendees: (state, { payload }: PayloadAction<number[]>) => {
      return { ...state, selectedAttendees: payload };
    },
  },
});

export const { setActiveAttendee, setDetailView, setSelectedAttendees } =
  activeAttendeeSlice.actions;

export default activeAttendeeSlice.reducer;
