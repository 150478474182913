import React, { useState } from 'react';
import Button from '../../../../common/components/Button';

interface BrightSpaceOAuthModalProps {
  clientId: string;
  clientSecret: string;
  isModalOpen: boolean;
  closeModal: () => void;
}

export const BrightSpaceOAuthModal: React.FC<BrightSpaceOAuthModalProps> = ({
  clientId,
  clientSecret,
  isModalOpen,
  closeModal,
}) => {
  if (!isModalOpen) return null;

  const [oAuthData, setOAuthData] = useState({
    clientId,
    clientSecret,
    redirectUri: window.location.origin + '/dashboard/lms/configure/brightspace',
    scope: 'enrollment:orgunit:read quizzing:attempts:read',
  });

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!oAuthData.clientId || !oAuthData.clientSecret) {
      alert('Please fill in all the fields');
    };
    sessionStorage.setItem('client_id', oAuthData.clientId);
    sessionStorage.setItem('client_secret', oAuthData.clientSecret);
    const authUrl = `${process.env.BRIGHTSPACE_AUTH_URL}/oauth2/auth?response_type=code&client_id=${oAuthData.clientId}&redirect_uri=${window.location.origin}/dashboard/lms/configure/brightspace&scope=${oAuthData.scope}`;
    window.location.href = authUrl;
  };

  return (
    <div data-testId="brightspace-oauth-modal" className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded shadow-lg w-1/2">
        <div className="flex justify-end">
          <button data-testId="close-modal" onClick={closeModal}>&times;</button>
        </div>
        <h3 className="text-lg font-bold mb-4">Generate Refresh Token</h3>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label htmlFor="clientId" className="block mb-2">Client ID:</label>
            <input
              id='clientId'
              type="text"
              value={oAuthData.clientId}
              onChange={(e) => setOAuthData({ ...oAuthData, clientId: e.target.value })}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="clientSecret" className="block mb-2">Client Secret:</label>
            <input
              id='clientSecret'
              type="password"
              value={oAuthData.clientSecret}
              onChange={(e) => setOAuthData({ ...oAuthData, clientSecret: e.target.value })}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="redirectUri" className="block mb-2">Redirect URI:</label>
            <input
              id='redirectUri'
              type="text"
              value={oAuthData.redirectUri}
              onChange={(e) => setOAuthData({ ...oAuthData, redirectUri: e.target.value })}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="scope" className="block mb-2">Scope:</label>
            <input
              id='scope'
              type="text"
              value={oAuthData.scope}
              onChange={(e) => setOAuthData({ ...oAuthData, scope: e.target.value })}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <Button
            data-testId="submit-button"
            type="submit"
            className="bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center mr-4"
            label="Generate Refresh Token"
          />
        </form>
      </div>
    </div>
  );
};
