import React, {useState} from 'react';
import {LMS_TYPE, PROVIEW_ADMIN_EVENT_URL, PROVIEW_API_URL} from '../../../common/components/constant';
import {EyeIcon} from '@heroicons/react/24/outline';
import {PaperAirplaneIcon} from '@heroicons/react/20/solid';
import Modal from '../../../common/components/Modal';
import {
  useAddCandidatesMutation,
  useCreateSlotsMutation,
  useScheduleEventMutation,
  useUpdateEventMutation,
} from '../../../common/api/eventScheduler';
import Spinner from './Spinner';
import {UPDATE_PROVIEW_EVENT_ID} from '../queries.graphql';
import {client} from "../../../common/utils/apollo";
import {convertUTCDateToLocalDate} from "./dateTimehelper";
import {attendeeCsvGenerator} from "./attendeeCsvGenerator";
import { Mixpanel } from '../../../Mixpanel';

const QuizAttendeeList = ({selectedAttendees}) => (
  <div className="overflow-y-auto max-h-60">
    <table className="table-auto w-full text-sm font-medium mb-1">
      <thead>
      <tr>
        <th className="px-4 py-2">Name</th>
        <th className="px-4 py-2">Email</th>
      </tr>
      </thead>
      <tbody>
      {selectedAttendees.map((quizAttendee, index) => (
        <tr key={index}>
          <td className="border px-4 py-2">
            {quizAttendee.quiz_attendee_attendee.first_name}{' '}
            {quizAttendee.quiz_attendee_attendee.last_name}
          </td>
          <td className="border px-4 py-2">{quizAttendee.quiz_attendee_attendee.email}</td>
        </tr>
      ))}
      </tbody>
    </table>
  </div>
);

const QuizHeaderView = ({quiz, isScheduleButtonDisabled, selectedAttendees}) => {
  const [isSchedulingSuccessful, setIsSchedulingSuccessful] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventTitle, setEventTitle] = useState(quiz.quiz_title);
  const [referenceNo, setReferenceNo] = useState(
    `${quiz.external_id}-${quiz.course_id}-${quiz.course_module_id}-${
      quiz.course_module_item_id ? quiz.course_module_item_id : 0
    }`,
  );
  const [startDate, setStartDate] = useState(convertUTCDateToLocalDate(quiz.timeopen));
  const [endDate, setEndDate] = useState(convertUTCDateToLocalDate(quiz.timeclose));
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [eventScheduler] = useScheduleEventMutation({});
  const [createSlots] = useCreateSlotsMutation({});
  const [updateEvent] = useUpdateEventMutation({});
  const [addCandidates] = useAddCandidatesMutation({});
  const [isLoading, setIsLoading] = useState(false);
  const handleStartDateChange = (e) => {
    setStartDateError('');
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (e) => {
    setEndDateError('');
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEventTitleChange = (e) => {
    setEventTitle(e.target.value);
  };

  const handleReferenceNoChange = (e) => {
    setReferenceNo(e.target.value);
  };

  const handleViewClick = () => {
    window.open(`${PROVIEW_ADMIN_EVENT_URL}/${quiz.proview_event_id}`, '_blank');
  };

  const handleScheduleClick = () => {
    openModal();
  };

  const handleScheduleConfirm = async () => {
    try {
      setIsLoading(true);
      const currentDateTimeUTC = new Date().toISOString();
      const selectedStartDateTimeUTC = new Date(startDate).toISOString();
      const selectedEndDateTimeUTC = new Date(endDate).toISOString();
      if (selectedStartDateTimeUTC < currentDateTimeUTC) {
        setStartDateError('Start Date-Time should not be in the past.');
        return;
      }
      if (selectedEndDateTimeUTC < currentDateTimeUTC) {
        setEndDateError('End Date-Time should not be in the past.');
        return;
      }
      setStartDateError('');
      Mixpanel.track("proview_console_lms_dashboard_schedule_click");
      const eventData = await eventScheduler({
        name: eventTitle,
        external_event_id: referenceNo,
      }).unwrap();
      if (!eventData.id) {
        alert('Scheduling failed Please Try again');
        return;
      }
      const slotData = await createSlots({
        event_id: eventData.id,
        starts_at: new Date(startDate),
        ends_at: new Date(endDate),
        start_buffer_time: 0,
        end_buffer_time: 0,
        candidate_proctor_ratio: 40,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).unwrap();
      console.log('Slot Data:', slotData);
      if (!slotData.id) {
        alert('Scheduling failed Please Try again');
        return;
      }

      const updateEventData = await updateEvent({
        eventId: eventData.id,
        body: {
          candidate_instruction: quiz.candidate_instructions || 'NA',
          proctor_instruction: quiz.proctor_instructions || 'NA',
        },
      }).unwrap();
      console.log('Update Event Data:', updateEventData);
      if (!updateEventData.proctor_event_id) {
        alert('Scheduling failed Please Try again');
        return;
      }
      const attendeeData = await attendeeCsvGenerator(quiz, selectedAttendees);

      await addCandidates({
        eventId: eventData.id,
        slotId: slotData.id,
        body: attendeeData,
      }).unwrap();

      await client.mutate({
        mutation: UPDATE_PROVIEW_EVENT_ID,
        variables: {
          eventId: eventData.id,
          quizId: quiz.id,
        },
      });
      setIsSchedulingSuccessful(true);
      window.open(`${PROVIEW_ADMIN_EVENT_URL}/${eventData.id}`, '_blank');
      window.location.reload();
    } catch (error) {
      alert('Scheduling failed Please Try again');
      console.error('Scheduling failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-between p-4">
      <h2 className="text-xl font-semibold">{quiz.quiz_title}</h2>
      <div className="flex items-center space-x-2">
        <button
          onClick={handleViewClick}
          className={`bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center ${
            !quiz.proview_event_id ? 'opacity-50 cursor-not-allowed ' : ''
          }`}
          disabled={!quiz.proview_event_id}
        >
          <EyeIcon className="h-5 w-5 mr-2"/>
          <span>View</span>
        </button>

        <button
          onClick={handleScheduleClick}
          className={`bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded-full inline-flex items-center ${
            isScheduleButtonDisabled || quiz.proview_event_id || isSchedulingSuccessful
              ? 'opacity-50 cursor-not-allowed '
              : ''
          }`}
          disabled={isScheduleButtonDisabled || quiz.proview_event_id || isSchedulingSuccessful}
        >
          <PaperAirplaneIcon className="h-5 w-5 mr-2"/>
          <span>Schedule</span>
        </button>
      </div>

      <Modal show={isModalOpen} onCancel={closeModal}>
        <div className="p-4">
          <h2 className="text-lg font-semibold mb-4">Schedule Event</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Event Title</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={eventTitle}
              onChange={handleEventTitleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Reference No</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={referenceNo}
              onChange={handleReferenceNoChange}
            />
            <div className="mb-4 mt-2">
              <p className="block text-sm font-medium mb-1">
                Attendees: {selectedAttendees.length}
              </p>
              <QuizAttendeeList selectedAttendees={selectedAttendees}/>
            </div>
          </div>

          <div className="mb-4 flex justify-between">
            <div className="w-48">
              <label className="block text-sm font-medium mb-1">Start Date Time</label>
              <input
                type="datetime-local"
                className="w-full px-3 py-2 border rounded-md"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <p className="text-red-500 text-xs mt-1">{startDateError}</p>
            </div>
            <div className="w-48">
              <label className="block text-sm font-medium mb-1">End Date Time</label>
              <input
                type="datetime-local"
                className="w-full px-3 py-2 border rounded-md"
                value={endDate}
                onChange={handleEndDateChange}
              />
              <p className="text-red-500 text-xs mt-1">{endDateError}</p>
            </div>
          </div>
          <p className="text-xs text-gray-500 mb-4">
            Note: The change in the start and end date will not affect the actual time set in LMS
          </p>

          <div className="text-right">
            <button
              className={`bg-emerald-900 hover:bg-emerald-700 text-gray-200 font-bold py-2 px-4 rounded ${
                startDateError != '' || endDateError != ''
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer'
              }`}
              onClick={handleScheduleConfirm}
              disabled={startDateError != '' || endDateError != ''}
            >
              Confirm
            </button>
          </div>
        </div>
        {isLoading && <Spinner/>}
      </Modal>
    </div>
  );
};

export default QuizHeaderView;
