import { useRef, useEffect, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'app/hooks';
import { handleError } from 'helpers/sentry';
import { createNewMessage, markCandidateMessagesAsReadInFirebase } from 'helpers/messages';
import { isSessionEnded } from 'helpers/session';
import { Message, Messages } from 'globals/interfaces';
import ChatMessages from './ChatMessages';
import BundledEditor from 'components/editor/Editor';
import { TINYMCE_LICENSE_KEY } from 'common/components/constant';

interface Props {
  messages: Messages;
  session_id: string;
  session_status: string;
  searchQuery?: string;
  searchedMessages?: Message[];
}

const ChatWindow = memo(({ messages, session_id, session_status }: Props) => {
  const { id } = useAppSelector((state) => state.currentUser);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const isSessionOver = isSessionEnded(session_status);
  const chatMessages = messages ? Object.values(messages) : [];

  const handleSendMessage = async () => {
    const inputText = editorRef.current?.getContent();
    if (inputText?.trim() !== '') {
      try {
        const messageData = {
          created_at: new Date().toISOString(),
          id: uuidv4(),
          user_id: id,
          role: 'proctor',
          text: inputText,
        };
        editorRef.current.setContent('');
        await createNewMessage(session_id, messageData);
      } catch (error) {
        handleError(error);
      }
    }
  };

  const messagesUpdate = () => {
    if (messages && messagesEndRef.current) {
      markCandidateMessagesAsReadInFirebase(session_id, messages);
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 0);
    }
  };

  useEffect(messagesUpdate, [chatMessages?.length]);

  const editorRef = useRef(null);

  return (
    <>
      <div className="flex flex-col flex-grow overflow-y-auto" style={{ maxHeight: 'calc(100%)' }}>
        <ChatMessages chatMessages={chatMessages} />
        <div data-testid="messages-end-ref" ref={messagesEndRef}></div>
      </div>
      <div className="flex items-center justify-center gap-2 ">
        <BundledEditor
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            height: 300,
            menubar: false,
            plugins: ['link', 'lists', 'autolink'],
            toolbar:
              'bold italic underline| \
            bullist numlist',
            license_key: TINYMCE_LICENSE_KEY
          }}
          key={session_id}
          content_style="text-gray-600"
          handleEnter={handleSendMessage}
          disabled={isSessionOver}
        />
        {isSessionOver ? (
          <ArrowRightCircleIcon data-testid="send-message" className="w-8 h-8 fill-gray-300" />
        ) : (
          <ArrowRightCircleIcon
            data-testid="send-message"
            className="w-8 h-8 cursor-pointer fill-green-300"
            onClick={handleSendMessage}
          />
        )}
      </div>
    </>
  );
});

export default ChatWindow;
