import { useAppDispatch } from 'app/hooks';
import dayjs from 'dayjs';
import { setCurrentPlayingTimestamp } from 'common/slice/playbackVideoSlice';
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { AlertSeverity } from 'globals/enums';

interface Props {
  position: string;
  severity: string;
  alert_type_id: number;
  timestamp: string;
}

const AlertFlag = ({ position, severity, alert_type_id, timestamp }: Props) => {
  const dispatch = useAppDispatch();
  const isRecordingStartFlag = [198, 200, 203].includes(alert_type_id);
  const isRecordingStopFlag = [199, 201, 204].includes(alert_type_id);

  const renderFlag = () => {
    let styles = '';
    switch (severity) {
      case AlertSeverity.Critical:
      case AlertSeverity.High:
        styles = 'bg-red-500 border border-red-400';
        break;
      case AlertSeverity.Medium:
        styles = 'bg-amber-500 border border-amber-400';
        break;
      case AlertSeverity.Low:
        styles = 'bg-yellow-500 border border-yellow-400';
        break;
      case AlertSeverity.None:
      default:
        styles = 'bg-slate-500 border border-slate-400 5';
        break;
    }
    return (
      <div
        title={dayjs(timestamp).format('hh:mm:ss A')}
        className={`rounded-full w-5 h-5 ${styles}`}
      ></div>
    );
  };

  const renderRecordingFlag = () => {
    let icon;
    switch (alert_type_id) {
      case 198:
      case 199:
        icon = <VideoCameraIcon data-testid="primary-camera-icon" className="w-3 h-3" />;
        break;
      case 200:
      case 201:
        icon = <DevicePhoneMobileIcon data-testid="secondary-camera-icon" className="w-3 h-3" />;
        break;
      case 203:
      case 204:
        icon = <ComputerDesktopIcon data-testid="screenshare-camera-icon" className="w-3 h-3" />;
        break;
    }
    return (
      <div
        title={dayjs(timestamp).format('hh:mm:ss A')}
        className={`p-1 rounded-full bg-white text-green-500 border  ${
          isRecordingStartFlag ? 'border-green-500 text-green-500' : 'border-red-500 text-red-500'
        }`}
      >
        {icon}
      </div>
    );
  };

  const handleMoveToAlert = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(setCurrentPlayingTimestamp({ requestedTimestamp: timestamp }));
  };

  return (
    <div
      data-testid="alertFlag"
      onClick={(e) => handleMoveToAlert(e)}
      style={{ left: position }}
      className="absolute top-1/2 -translate-y-2/4 hover:scale-[1.2] transition-all hover:z-50"
    >
      {isRecordingStartFlag || isRecordingStopFlag ? renderRecordingFlag() : renderFlag()}
    </div>
  );
};

export default AlertFlag;
