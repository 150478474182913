import React from 'react';
import { query, ref } from 'firebase/database';
import { ReactFireOptions, useDatabase, useDatabaseListData } from 'reactfire';
import moment from 'moment/moment';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { AUDIO_CALL_STATUS, FIREBASE_RELATIONS } from 'common/components/constant';

interface Props {
  session_id: string;
}

function getIcon(timestamp) {
  const timestampMoment = moment(timestamp);
  if (moment().diff(timestampMoment, 'minutes') < 1) {
    return <PhoneIcon type="solid" className="h-5 w-5 fill-orange-500 stroke-orange-500" />;
  } else if (moment().diff(moment(timestamp), 'minutes') >= 1) {
    return <PhoneIcon type="solid" className="h-5 w-5 fill-red-700 stroke-red-700" />;
  }
}

export const CallStatus: React.FC<Props> = (props) => {
  let icon;
  const database = useDatabase();
  const options: ReactFireOptions = {
    idField: 'session_id',
  };
  const deviceQuery = query(ref(database, FIREBASE_RELATIONS.AUDIO_CALL));
  const { data, error, status } = useDatabaseListData(deviceQuery, options);
  if (data) {
    const audioCallData = data.find((d: any) => d.session_id === props.session_id);
    switch (audioCallData?.status) {
      case AUDIO_CALL_STATUS.REQUESTED:
        icon = getIcon(audioCallData.requested_timestamp);
        break;
      case AUDIO_CALL_STATUS.INITIATED:
      case AUDIO_CALL_STATUS.CONNECTED:
      case AUDIO_CALL_STATUS.DISCONNECTED: {
        icon = <PhoneIcon type="solid" className="h-5 w-5 fill-green-700 stroke-green-700" />;
        break;
      }
      case AUDIO_CALL_STATUS.STALE: {
        if (
          moment(audioCallData?.disconnected_timestamp) > moment(audioCallData?.initiated_timestamp)
        ) {
          icon = <PhoneIcon type="solid" className="h-5 w-5 fill-green-700 stroke-green-700" />;
        }
        break;
      }
      default:
        icon = null;
    }
  }
  return icon || null;
};
