import { orderBy } from 'lodash';
import moment from 'moment';
import getStepStatus, { Alert, validAlerts } from './getStepStatus';
import { UnreadMessage } from './UnreadMessage';
import { CallStatus } from './CallStatus';
import { countAlerts } from "../notConnected/getDisconnectedCount";

export const processAlerts = (session) => {
  const alerts = [];
  if (!session?.alerts) return alerts;
  const keys = Object.keys(session?.alerts);
  keys.forEach((key, i) => {
    alerts.push({
      alert_type_id: session.alerts[key].alert_type_id,
      timestamp: session.alerts[key].updated_at,
      session_id: session.session_id,
    });
  });
  return orderBy(
    alerts,
    (o) => {
      return moment(o.timestamp);
    },
    ['desc'],
  );
};

export const getLastOnlineAlerts = (alerts) => {
  if (!alerts.length) return { lastOnlineAlerts: [], audioTestPassedTimestamp: null, screenShareTimestamp: null };

  const result = alerts.reduce((acc, currentAlert) => {
    if (!acc.alertMap[currentAlert.alert_type_id]) {
      switch(currentAlert.alert_type_id) {
        case Alert.SessionJoined:
          acc.lastOnlineAlerts.push(currentAlert);
          break;
        case Alert.AudioTestPassed:
          acc.audioTestPassedTimestamp = currentAlert.timestamp;
          break;
        case Alert.ScreenCaptureEnabled:
          acc.screenShareTimestamp = currentAlert.timestamp;
          break;
        default: break;
      }
      if ( currentAlert.alert_type_id!==Alert.SessionJoined && validAlerts.includes(currentAlert.alert_type_id)) {
        acc.lastOnlineAlerts.push(currentAlert);
      }
      acc.alertMap[currentAlert.alert_type_id] = true;
    }
    return acc;
  }, { lastOnlineAlerts: [], audioTestPassedTimestamp: null, screenShareTimestamp: null, alertMap: {} });

  return ({
    lastOnlineAlerts: result.lastOnlineAlerts,
    audioTestPassedTimestamp: result.audioTestPassedTimestamp,
    screenShareTimestamp: result.screenShareTimestamp,
  });
};


export const getFilteredSessions = (sessions, proctors, selectedProctors) => {
  return sessions.filter(session => {
    const proctorData = proctors.find(proctor => proctor.participant_group_id === session.participant_group_id);
    return proctorData && selectedProctors.includes(proctorData.id);
  });
};

export const processSession = (session, proctors, candidates) => {
  const secondaryCameraEnabled = session?.configuration?.secondary_camera;
  const alerts = processAlerts(session);
  if (!alerts.length) return null;
  const { lastOnlineAlerts, audioTestPassedTimestamp, screenShareTimestamp } = getLastOnlineAlerts(alerts);
  const alertIds = lastOnlineAlerts.map((alert) => alert.alert_type_id);
  const stepStatus = getStepStatus(
    alertIds,
    audioTestPassedTimestamp,
    screenShareTimestamp,
    session.pre_check,
    lastOnlineAlerts[0].timestamp,
    secondaryCameraEnabled,
  );
  const connectionCount = countAlerts(alerts);
  const isPrimaryCameraConnected = connectionCount[Alert.PrimaryCameraConnected] > connectionCount[Alert.PrimaryCameraDisconnected];
  const isSecondaryCameraConnected = connectionCount[Alert.SecondaryCameraConnected] > connectionCount[Alert.SecondaryCameraDisconnected];
  const proctorData = proctors.find(
    (proctor) => proctor.participant_group_id === session.participant_group_id,
  );
  const candidateData = candidates.find((candidate) => candidate.id === session.attendee_id);
  if (proctorData && candidateData) {
    return {
      ...stepStatus,
      id: session.session_id,
      slotId: session.slot_id,
      eventId: session.event_id,
      status: session.session_status,
      proctor: proctorData.email || null,
      proctorUserId: proctorData.id,
      attendee: candidateData.external_attendee_id,
      attendeeId: candidateData.id,
      participantGroupId: session.participant_group_id,
      primaryCamera: (
        <div
          className={`${
            isPrimaryCameraConnected
              ? 'text-emerald-700 font-bold'
              : 'text-red-700 font-bold'
          }`}
        >
          { isPrimaryCameraConnected ? 'ON' : 'OFF' }
        </div>
      ),
      secondaryCamera: (
        <div
          className={`${
            isSecondaryCameraConnected
              ? 'text-emerald-700 font-bold'
              : 'text-red-700 font-bold'
          }`}
        >
          {isSecondaryCameraConnected ? 'ON' : 'OFF'}
        </div>
      ),
      chat: <UnreadMessage message={session.messages} />,
      call: <CallStatus session_id={session.session_id} />,
      attempts: alerts.filter((alert) => alert.alert_type_id === Alert.SessionJoined).length,
    };
  }
};

export const processAttendeeStepData = (sessions, proctors, currentProctors, candidates) => {
  if (!sessions || !proctors || !candidates) return [];

  const selectedProctors = currentProctors.map((currentProctor) => currentProctor.id);
  const filteredSessions = getFilteredSessions(sessions, proctors, selectedProctors);

  const result = filteredSessions
    .map(session => processSession(session, proctors, candidates))
    .filter(Boolean);

  result.sort((result1, result2) => {
    const stepComparison = result1.currentStep - result2.currentStep;
    return stepComparison
      ? stepComparison
      : moment(result2.lastOnlineAlertTimestamp).diff(moment(result1.lastOnlineAlertTimestamp));
  });

  return result;
};
