import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeModal } from 'common/slice/modalSlice';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { useUpdateProviewIndexMutation } from 'common/api/post-session-embed';
import { AlertSeverity } from 'globals/enums';
import { setUpdateproviewRatingStale } from 'common/slice/playbackVideoSlice';

const EditProviewIndex = () => {
  const dispatch = useAppDispatch();
  const [newRating, setNewRating] = useState('');
  const [overrideReason, setOverrideReason] = useState('');
  const { sessionId, attendeeId } = useAppSelector((state) => state.playbackVideo);
  const [updateProviewIndex] = useUpdateProviewIndexMutation({});

  const handleProviewIndexUpdate = async () => {
    if (attendeeId && sessionId) {
      await updateProviewIndex({
        attendeeId,
        newRating,
        overrideReason,
        sessionId,
      });
      dispatch(setUpdateproviewRatingStale(false));
    }
    dispatch(closeModal({}));
  };

  return (
    <div className="flex gap-3">
      <span className="flex w-10 h-10 p-2 text-blue-900 bg-blue-200 rounded-full">
        <PencilSquareIcon className="text-blue-900" />
      </span>
      <div className="flex-1 flex flex-col mt-1.5 w-full">
        <p className="text-lg font-bold">Edit Proctor Index</p>
        <div className="grid grid-cols-5 gap-3 mt-4">
          <label className="col-span-1 text-right">Index:</label>
          <select
            onChange={(e) => setNewRating(e.target.value)}
            className="w-full col-span-4 p-2 border rounded-md"
            defaultValue=""
          >
            <option value="" disabled>
              Select an option
            </option>
            <option value={AlertSeverity.High}>High</option>
            <option value={AlertSeverity.Medium}>Medium</option>
            <option value={AlertSeverity.Low}>Low</option>
          </select>
          <label className="col-span-1 text-right">Reason:</label>
          <textarea
            placeholder="Please state reason for change"
            id="terminateComments"
            value={overrideReason}
            onChange={(e) => setOverrideReason(e.target.value)}
            className="w-full col-span-4 p-2 border rounded-md"
            cols={10}
            rows={3}
          ></textarea>
        </div>
        <div className="flex gap-1 mt-4 ml-auto text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button
            disabled={!newRating || !overrideReason}
            className="p-2 text-white bg-blue-500 rounded enabled:hover:opacity-80 disabled:cursor-not-allowed disabled:opacity-70"
            onClick={handleProviewIndexUpdate}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProviewIndex;
