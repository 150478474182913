import Video from 'twilio-video';
import { Room } from 'livekit-client';
import { StreamingProvider } from 'globals/enums';
import { livekitWSUrl } from 'common/components/constant';

const getRoom = async (stremObject: { token: string; video_provider: string }) => {
  if (stremObject.video_provider === StreamingProvider.Twilio) {
    return await Video.connect(stremObject.token, {
      audio: false,
      video: false,
    });
  }
  const room = new Room();
  await room.connect(livekitWSUrl, stremObject.token);
  return room;
};

export default getRoom;
