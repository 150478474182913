import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import AudioCall from './AudioCall';
import ChatWindow from './ChatWindow';
import { Session } from 'globals/interfaces';
import { useState } from 'react';
import { SessionStatus } from 'globals/enums';

type Props = {
  session: Session;
};

const ChatTab = ({ session }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  return (
    session?.session_id && session.session_status !== SessionStatus.NoShow && (
      <div className="flex flex-col w-full h-full gap-2 p-2 overflow-auto">
        <div className="flex items-center">
          <div className="relative flex flex-grow">
            <input
              type="text"
              name="search"
              id="search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="block w-full py-2 pl-2 text-gray-900 border-0 rounded-md shadow-sm pr-14 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon className="w-6 h-6" />
            </div>
          </div>
          <AudioCall
            session_status={session.session_status}
            session_id={session.session_id}
            session_uuid={session.session_uuid}
            attendee_id={session.attendee_id}
          />
        </div>
        <ChatWindow
          searchQuery={searchQuery}
          session_id={session.session_id}
          messages={session.messages}
          session_status={session.session_status}
        />
      </div>
    )
  );
};

export default ChatTab;
