import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CurrentFeed = 'primary' | 'secondary' | 'screen-recording' | 'auto-rotate';

interface AudioSetting {
  attendeeId: number;
  isMuted: boolean;
}

export interface AudioSettings {
  isMuted: boolean;
  previousMuteSelection: boolean;
  audioSettings: AudioSetting[];
}

const initialState: AudioSettings = {
  isMuted: false,
  previousMuteSelection: false,
  audioSettings: [],
};

const audioSettingsSlice = createSlice({
  name: 'audioSettings',
  initialState,
  reducers: {
    updateAudioSetting: (state, action: PayloadAction<AudioSetting>) => {
      const { attendeeId, isMuted } = action.payload;

      const existingSettingIndex = state.audioSettings.findIndex(
        (s) => s.attendeeId === attendeeId,
      );
      const updatedSetting: AudioSetting = {
        attendeeId,
        isMuted,
      };

      if (existingSettingIndex === -1) {
        state.audioSettings = [...state.audioSettings, updatedSetting];
      } else {
        state.audioSettings = state.audioSettings.map((session, index) => {
          if (index === existingSettingIndex) {
            return { ...session, ...updatedSetting };
          }
          return session;
        });
      }
    },
    updateGloablAudioSettings: (
      state,
      action: PayloadAction<{
        attendeeIds: number[] | 'all';
        audioStatus: boolean;
      }>,
    ) => {
      const { attendeeIds, audioStatus } = action.payload;

      if (attendeeIds === 'all') {
        return {
          ...state,
          audioSettings: [],
          isMuted: audioStatus,
          previousMuteSelection: audioStatus,
        };
      } else {
        const updatedSettings = state.audioSettings.map((existingSetting) => {
          if (attendeeIds.includes(existingSetting.attendeeId)) {
            return {
              ...existingSetting,
              isMuted: audioStatus,
            };
          }
          return existingSetting;
        });

        attendeeIds.forEach((attendeeId) => {
          if (!updatedSettings.some((setting) => setting.attendeeId === attendeeId)) {
            updatedSettings.push({ attendeeId, isMuted: audioStatus });
          }
        });

        return { ...state, audioSettings: updatedSettings, previousMuteSelection: audioStatus };
      }
    },
  },
});

export const { updateAudioSetting, updateGloablAudioSettings } = audioSettingsSlice.actions;
export default audioSettingsSlice.reducer;
