import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import 'react-datepicker/dist/react-datepicker.css';
import { ProctoringWrapperInvite } from './types';
import { format, parseISO } from 'date-fns';

export function getWrapperColumns() {
  const columnHelper = createColumnHelper<ProctoringWrapperInvite>();
  const columns = [
    columnHelper.accessor((row) => row.attendee_external_id, {
      id: 'attendee_external_id',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span> Attendee External ID</span>,
    }),
    columnHelper.accessor((row) => row.session_external_id, {
      id: 'session_external_id',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Session External ID</span>,
    }),
    columnHelper.accessor((row) => row.expiry, {
      id: 'expiry',
      cell: (info) => {
        const value = info.getValue();
        if (!value) return <span>N/A</span>;
        
        const date = parseISO(value);
        const formattedDate = format(date, "dd- MMMM- yy | hh:mm a (xxx)");
        return <span>{formattedDate}</span>;
      },
      header: () => <span>Link Expiry</span>,
    }),
    columnHelper.accessor((row) => row.type, {
      id: 'type',
      cell: (info) => {
        const value = info.getValue();
        const proctoringTypes = {
          ai_proctor: 'AI Proctoring',
          record_and_review: 'Record and Review',
          live_proctor: 'Live Proctoring',
          offline_proctor: 'Offline Proctoring'
        };
        return <span>{proctoringTypes[value] || value}</span>;
      },
      header: () => <span>Proctoring Type</span>,
    }),
    columnHelper.accessor((row) => row.configurations?.is_secure_browser, {
      id: 'is_secure_browser',
      cell: (info) => {
        const value = info.getValue();
        return <span>{value === true ? 'Yes' : value === false ? 'No' : 'N/A'}</span>;
      },
      header: () => <span>Secure Browser</span>,
    }),
    columnHelper.accessor((row) => row.wrapper_invite_proview_token?.name, {
      id: 'proview_token',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Proview Token</span>,
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: (info) => {
        const value = info.getValue();
        return <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>;
      },
      header: () => <span>Status</span>,
    }),
  ];
  return columns;
}
