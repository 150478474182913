import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  isOpen: false,
  attendeeId: null,
  sessionUuid: '',
  sessionId: '',
  alertId: '',
  activeSessions: [],
  slotStartDate: '',
  slotEndDate: '',
  sessionJoinedAt: '',
  sessionCompletedAt: '',
};

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        attendeeId?: number;
        type: string;
        sessionUuid?: string;
        sessionId?: string;
        alertId?: string;
        activeSessions?: string[];
        slotStartDate?: string;
        slotEndDate?: string;
        sessionJoinedAt?: string;
        sessionCompletedAt?: string;
      }>,
    ) => {
      const {
        attendeeId,
        type,
        sessionUuid,
        sessionId,
        alertId,
        activeSessions,
        slotStartDate,
        slotEndDate,
        sessionJoinedAt,
        sessionCompletedAt,
      } = action.payload;
      return {
        ...state,
        attendeeId,
        type,
        isOpen: true,
        sessionUuid,
        sessionId,
        alertId,
        activeSessions,
        slotEndDate: slotEndDate,
        slotStartDate: slotStartDate,
        sessionJoinedAt: sessionJoinedAt,
        sessionCompletedAt: sessionCompletedAt,
      };
    },
    closeModal: (state, action) => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
