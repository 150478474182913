import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import httpStatus from 'http-status';

enum LoginErrorMessage {
  InvalidUser = 'Invalid username or password',
  ServerError = 'Something went wrong. Please contact the administrator',
}

export interface CustomError {
  message: string;
  status: number | 'FETCH_ERROR' | 'PARSING_ERROR' | 'TIMEOUT_ERROR' | 'CUSTOM_ERROR';
}

const generateCustomError = (message: string, status: CustomError['status']): CustomError => {
  return { message, status };
};

export const loginErrorHandler = (error: FetchBaseQueryError): CustomError => {
  if (error.status === httpStatus.NOT_FOUND)
    return generateCustomError(LoginErrorMessage.InvalidUser, error.status);
  if (error.status === httpStatus.UNAUTHORIZED)
    return generateCustomError(LoginErrorMessage.InvalidUser, error.status);
  return generateCustomError(LoginErrorMessage.ServerError, error.status);
};
