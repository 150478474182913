import React, { useContext, useEffect, useState } from 'react';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import { useQuery } from '@apollo/client';
import { GET_PARTICIPANT_DETAILS } from 'components/dashboard/queries.graphql';
import moment from 'moment/moment';
import { USER_ROLES } from 'common/components/constant';

interface User {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  user_id: number;
}
export interface Candidate extends User {
  external_attendee_id: string;
}

export interface Proctor extends User {
  username: string;
  participant_group_id: number;
}

interface ParticipantResponse {
  attendee: Candidate[];
  views_user: Proctor[];
}

interface ParticipantDetailsRequest {
  proctorIds: number[];
  attendeeIds: number[];
}

const useDashboardParticipants = () => {
  const { currentSlot, setCandidates, setProctors } =
    useContext<DashboardContextType>(DashboardContext);
  const attendeeIds = !currentSlot
    ? []
    : currentSlot.participant
        .filter((p) => p.role === USER_ROLES.CANDIDATE)
        .map((user) => user.user_id);
  const proctorIds = !currentSlot
    ? []
    : currentSlot.participant
        .filter((p) => p.role === USER_ROLES.PROCTOR)
        .map((user) => user.user_id);
  const { loading, error, data } = useQuery<ParticipantResponse, ParticipantDetailsRequest>(
    GET_PARTICIPANT_DETAILS,
    {
      variables: {
        proctorIds,
        attendeeIds,
      },
    },
  );
  useEffect(() => {
    if (data && data.attendee) {
      setCandidates(data.attendee);
    }
    if (data && data.views_user && currentSlot && currentSlot.participant) {
      const proctors = [];
      currentSlot.participant
        .filter((p) => p.role === USER_ROLES.PROCTOR)
        .forEach((participant) => {
          const p = data.views_user.find((user) => user.id === participant.user_id);
          proctors.push({ ...p, participant_group_id: participant.participant_group_id });
        });
      setProctors(proctors);
    }
  }, [data]);
  return { loading, error };
};

export default useDashboardParticipants;
