import React from 'react';
import DatePicker from "react-datepicker";
import {createColumnHelper} from '@tanstack/react-table';
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from '@heroicons/react/24/outline';
import {Attendee, Quiz, QuizTableProps} from './types';
import {convertUTCDateToLocalDate} from "./dateTimehelper";

export function getQuizColumns({
  pagination,
  handleSelectAll,
  handleDeselectAll,
  checkboxSelectedQuizzes,
  handleToggleSelect,
  totalQuizzesCount,
  timeopen,
  setTimeopen,
  timeclose,
  setTimeclose,
}: QuizTableProps) {
  const columnHelper = createColumnHelper<Quiz>();
  const columns = [
    {
      id: 'select',
      header: () => (
        <input
          type="checkbox"
          onChange={(e) => e.target.checked ? handleSelectAll() : handleDeselectAll()}
          checked={checkboxSelectedQuizzes.length === totalQuizzesCount }
        />
      ),
      cell: (info) => (
        <input
          type="checkbox"
          onChange={() => handleToggleSelect(info.row.original)}
          checked={checkboxSelectedQuizzes.includes(info.row.original)}
        />
      ),
      disableSortBy: true,
    },
    columnHelper.accessor(
      (row, rowIndex) => rowIndex + 1 + pagination.pageIndex * pagination.pageSize,
      {
        id: 'id',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>ID</span>,
      }
    ),
    columnHelper.accessor((row) => row.quiz_title, {
      id: 'quiz_title',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Quiz Title</span>,
    }),
    columnHelper.accessor((row) => row.course_id, {
      id: 'course_id',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Course ID</span>,
    }),
    columnHelper.accessor((row) => row.course_module_id, {
      id: 'course_module_id',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Course Module ID</span>,
    }),
    columnHelper.accessor((row) => row.proctoring_type, {
      id: 'proctoring_type',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Proctoring Type</span>,
    }),
    {
      id: 'timeopen',
      header: () => (
        <div className="flex flex-col items-center">
          <span className="mb-1 text-sm">Time Open</span>
          <DatePicker
            selected={timeopen ? new Date(timeopen) : null}
            onChange={(date) => setTimeopen(date?.toISOString() || '')}
            dateFormat="yyyy-MM-dd"
            className="border border-gray-400 rounded-lg p-1 text-xs"
            showTimeSelect={false}
            placeholderText="Start Date"
          />
        </div>
      ),
      cell: (info) => <span>{convertUTCDateToLocalDate(info.row.original.timeopen)}</span>,
    },
    {
      id: 'timeclose',
      header: () => (
        <div className="flex flex-col items-center">
          <span className="mb-1 text-sm">Time Close</span>
          <DatePicker
            selected={timeclose ? new Date(timeclose) : null}
            onChange={(date) => setTimeclose(date?.toISOString() || '')}
            dateFormat="yyyy-MM-dd"
            className="border border-gray-400 rounded-lg p-1 text-xs"
            showTimeSelect={false}
            placeholderText="End Date"
          />
        </div>
      ),
      cell: (info) => <span>{convertUTCDateToLocalDate(info.row.original.timeclose)}</span>,
    },
  
    columnHelper.accessor((row) => row.quiz_attendee_aggregate.aggregate.count, {
      id: 'attendee_count',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Attendee Count</span>,
    }),
  ];
  return columns;
}

export function getAttendeeColumns(
  handleSelectAll: () => void,
  handleDeselectAll: () => void,
  selectedAttendees,
  tableData: any[],
  handleRowClick: (attendee) => void,
) {
  const columnHelper = createColumnHelper<Attendee>();
  const columns = [
    {
      id: 'select',
      header: () => (
        <input
          type="checkbox"
          onChange={(e) => (e.target.checked ? handleSelectAll() : handleDeselectAll())}
          checked={selectedAttendees.length === tableData.length}
        />
      ),
      cell: (info) => (
        <input
          type="checkbox"
          onChange={() => handleRowClick(info.row.original)}
          checked={selectedAttendees.includes(info.row.original)}
        />
      ),
      disableSortBy: true,
    },
    columnHelper.accessor((row, rowIndex) => rowIndex + 1, {
      id: 'id',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>ID</span>,
    }),
    columnHelper.accessor(
      (row) =>
        row?.quiz_attendee_attendee?.first_name + ' ' + row?.quiz_attendee_attendee?.last_name,
      {
        id: 'name',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>Name</span>,
      },
    ),
    columnHelper.accessor((row) => row?.quiz_attendee_attendee?.email, {
      id: 'email',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Email</span>,
    }),
  ];
  return columns;
}
