import { ProctoringWrapperInvite } from './types';
import { format, parseISO } from 'date-fns';

const proctoringTypeMap: { [key: string]: string } = {
  ai_proctor: 'AI Proctoring',
  record_and_review: 'Record and Review',
  live_proctor: 'Live Proctoring',
  offline_proctor: 'Offline Proctoring',
};

export const generateCardData = (assessments: ProctoringWrapperInvite) => {
  const formatExpiryDate = (date: string | undefined) => {
    if (!date) return '--';
    const parsedDate = parseISO(date);
    return format(parsedDate, 'dd- MMM- yy | hh:mm aa (xxx)');
  };

  return [
    {
      id: 'basic-info',
      title: 'Basic Information',
      fields: [
        {
          id: 'attendee-id',
          label: 'Attendee External ID',
          value: assessments?.attendee_external_id,
        },
        { id: 'session-id', label: 'Session External ID', value: assessments?.session_external_id },
        {
          id: 'generated-on',
          label: 'Generated On',
          value: formatExpiryDate(assessments?.created_at),
        },
        { id: 'updated-on', label: 'Updated On', value: formatExpiryDate(assessments?.updated_at) },
        { id: 'expiry', label: 'Expiry', value: formatExpiryDate(assessments?.expiry) },
        {
          id: 'status',
          label: 'Status',
          value: assessments?.status?.charAt(0).toUpperCase() + assessments?.status?.slice(1),
        },
      ],
    },
    {
      id: 'proctoring-info',
      title: 'Proctoring Information',
      fields: [
        {
          id: 'enable-proctoring',
          label: 'Enable Proctoring',
          value: !!assessments?.proview_token_id,
        },
        {
          id: 'proview-token',
          label: 'Proview Token',
          value: assessments?.wrapper_invite_proview_token?.name || '--',
        },
        {
          id: 'proctoring-type',
          label: 'Proctoring Type',
          value: assessments?.type ? proctoringTypeMap[assessments.type] || assessments.type : '--',
        },
        {
          id: 'iframe-support',
          label: 'iFrame Support',
          value: assessments?.configurations?.is_iframe_supported,
        },
        {
          id: 'show-end-proctoring',
          label: 'Show End Proctoring',
          value: assessments?.configurations?.show_end_proctoring,
        },
      ],
    },
    {
      id: 'access-info',
      title: 'Access Information',
      fields: [
        { id: 'url', label: 'Signed URL', value: assessments?.signed_url },
        {
          id: 'short-url',
          label: 'Signed Short URL',
          value: assessments?.signed_short_url,
        },
        {
          id: 'verification-code',
          label: 'Verification Code',
          value: assessments?.verification_code,
        },
        {
          id: 'redirect-url',
          label: 'Redirect URL',
          value: assessments?.configurations?.redirect_url,
        }
      ],
    },
    {
      id: 'security-settings',
      title: 'Security Settings',
      fields: [
        {
          id: 'secure-browser',
          label: 'Enable Secure Browser',
          value: assessments?.configurations?.is_secure_browser,
        },
        {
          id: 'screen-minimize',
          label: 'Screen Minimize',
          value: assessments?.configurations?.secure_browser?.is_minimize
            ? 'Allowed'
            : 'Not Allowed',
        },
        {
          id: 'screen-record',
          label: 'Screen Record',
          value: assessments?.configurations?.secure_browser?.is_record_screen
            ? 'Enabled'
            : 'Disabled',
        },
      ],
    },
  ];
};
