import React, { useContext, useEffect, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import EmptyState from 'common/components/EmptyState';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { columns, Data } from './TableAttributes';
import { processAttendeeStepData } from './processAttendeeStepData';

const AttendeeStepTable: React.FC = () => {
  const { sessions, proctors, currentProctors, candidates, attendeeStepData, setAttendeeStepData } =
    useContext<DashboardContextType>(DashboardContext);
  const [tableData, setTableData] = useState<Data[]>(attendeeStepData);
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  useEffect(() => {
    if (sessions) {
      const result = processAttendeeStepData(sessions, proctors, currentProctors, candidates);
      setTableData(result);
    }
  }, [sessions, currentProctors]);
  if (!sessions) return <EmptyState description="No data yet" icon={<CalendarDaysIcon />} />;
  return (
    <div>
      <table className="min-w-max text-xs h-full">
        <thead className="border-b border-slate-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="[&>*:nth-child(1)]:border-r [&>*:nth-child(1)]:border-slate-200 [&>*:nth-child(1)]:w-1/6 [&>*:nth-child(2)]:w-1/6"
            >
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="text-start p-2 px-4">
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="[&>*:nth-child(1)]:border-r [&>*:nth-child(1)]:border-slate-200 [&>*:nth-child(1)]:w-1/6 [&>*:nth-child(2)]:w-1/6"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2 px-4 break-normal">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttendeeStepTable;
