import { useAppDispatch } from 'app/hooks';
import { CurrentFeed, updateMediaSetting } from 'common/slice/mediaSettingSlice';
import { mediaSettingsIcons } from './constant';

const MediaSettings = ({ attendeeId, currentFeed, availableSettings }) => {
  const dispatch = useAppDispatch();

  const handleChange = (value: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const payload = {
      attendeeId: attendeeId,
      currentFeed: value as CurrentFeed,
    };
    dispatch(updateMediaSetting(payload));
  };

  return (
    <div className="flex flex-row absolute items-center h-6 top-0 left-2">
      <div className="flex items-center relative w-8 gap-3 h-6 p-2 rounded-r-2xl flex-grow cursor-pointer bg-black z-10 overflow-hidden hover:w-fit">
        {mediaSettingsIcons.map(
          (icon, index) =>
            (availableSettings.includes(icon.value) || icon.value === 'auto-rotate') && (
              <icon.Icon
                key={index}
                title={icon.title}
                className={`h-4 w-4 min-w-max hover:stroke-white ${
                  currentFeed === icon.value ? 'stroke-white order-1' : 'stroke-slate-500 order-4'
                }`}
                onClick={(event) => handleChange(icon.value, event)}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default MediaSettings;
