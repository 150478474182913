import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { AuthProvider, DatabaseProvider, useFirebaseApp } from 'reactfire';
import  {
   useFirebaseCustomTokenQuery
} from '../api/firebase-custom-token';
import { handleError } from '../../helpers/sentry';

function FirebaseProvider({ children }) {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const database = getDatabase(app);
  const { data, isSuccess,isError, error } =  useFirebaseCustomTokenQuery({});
  if(isSuccess){
      signInWithCustomToken(auth,data)
      .catch((error) => {
        handleError(error);
      });
    }

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={database}>{children}</DatabaseProvider>
    </AuthProvider>
  );
}

export default FirebaseProvider;
