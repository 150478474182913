import { createSlice } from '@reduxjs/toolkit';

interface SessionState {
  token: {
    [key: string]: {
      value: string;
    } | null;
  };
}

const initialState: SessionState = {
  token: {},
};

const sessions = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    setSessionSASToken: (state, action) => {
      state.token = { ...state.token, ...action.payload };
    },
  },
});

export const { setSessionSASToken } = sessions.actions;

export default sessions.reducer;
