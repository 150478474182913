import { createApi } from '@reduxjs/toolkit/query/react';
import { LMS_API_URL } from '../components/constant';
import baseQueryWithReauth from './base';

const lmsApi = createApi({
  reducerPath: 'lmsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    syncAttendees: builder.mutation({
      query: (body) => ({
        url: `${LMS_API_URL}/attendee-sync`,
        method: 'POST',
        body,
      }),
    }),
    fetchOrganizationConfig: builder.query<any, string>({
      query: (organizationId) => ({
        url: `${LMS_API_URL}/psd/organization/${organizationId}`,
        method: 'GET',
      }),
    }),
    updateOrganizationConfig: builder.mutation<any, { organizationId: string; data: any }>({
      query: ({ organizationId, data }) => ({
        url: `${LMS_API_URL}/psd/organization/${organizationId}`,
        method: 'PUT',
        body: data,
      }),
    }),
    createOrganizationConfig: builder.mutation<any, any>({
      query: (data) => ({
        url: `${LMS_API_URL}/psd/organization`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useSyncAttendeesMutation,
  useFetchOrganizationConfigQuery,
  useUpdateOrganizationConfigMutation,
  useCreateOrganizationConfigMutation,
} = lmsApi;

export default lmsApi;
