import { useAppDispatch } from 'app/hooks';
import { useDismissAlertMutation } from 'common/api/alert';
import { closeModal } from 'common/slice/modalSlice';
import { useState } from 'react';
import { XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  sessionUuid: string;
  alertId: string;
}

const DismissFlag = ({ sessionUuid, alertId }: Props) => {
  const dispatch = useAppDispatch();
  const [dismissAlert] = useDismissAlertMutation();
  const [suspendReason, setSuspendReason] = useState('');
  const [agreeDismissFlag, setAgreeDismissFlag] = useState(false);

  const handleAddFlagClick = async () => {
    dispatch(closeModal({}));
    await dismissAlert({
      alertId,
      description: suspendReason,
      sessionUuid,
    });
  };

  const cancelModal = () => {
    dispatch(closeModal({}));
  };

  const isButtonDisabled = (suspendReason: string, agreeDismissFlag: boolean) =>
    suspendReason.length < 30 || !agreeDismissFlag;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center">
        <XCircleIcon className="h-12 w-12  text-white p-1 fill-red-300" />
        <p className="font-bold">Dismiss Flag ?</p>
      </div>
      <div className="pl-12">
        <p className="text-gray-500 text-xs">Min 30 characters</p>
        <div>
          <textarea
            onChange={(e) => setSuspendReason(e.target.value.trim())}
            rows={4}
            className="w-full border"
          />
        </div>
        <div className="flex gap-1 my-2">
          <ExclamationCircleIcon className="h-6 w-6 text-white fill-blue-300" />
          <p className="text-blue-300 mt-0.5 text-sm">
            Removing a critical flag will impact the Proview Index. Index recalculation will be
            required.
          </p>
        </div>
        <div className="pt-2">
          <input
            id="agreeDismiss"
            type="checkbox"
            onClick={() => setAgreeDismissFlag(!agreeDismissFlag)}
          />
          <label htmlFor="agreeDismiss" className="pl-2">
            I agree the suspended flag was false show
          </label>
        </div>
        <div className="flex justify-end gap-3 mt-10">
          <button className="secondary-btn" onClick={cancelModal}>
            Cancel
          </button>
          <button
            className="primary-btn"
            disabled={isButtonDisabled(suspendReason, agreeDismissFlag)}
            onClick={handleAddFlagClick}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DismissFlag;
