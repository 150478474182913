import { CUSTOM_FLAG_ALERT_TYPE_ID, defaultAlertTypes } from 'common/components/constant';
import { orderBy } from 'lodash';
import { Alert, Alerts } from 'globals/interfaces';
import { getDatabase, ref, update } from 'firebase/database';
import { AlertSeverity, FilterBy } from 'globals/enums';

export interface SortedAlerts {
  alert_type_id: number;
  alerts: Alert[];
  description: string;
  max_updated_time: string;
  severity: string;
  name: string;
}

const getGroupedAlerts = (alerts: Alert[], viewBy: string, searchTerm: string) => {
  const groupedData = {};

  const severityOrder = [
    AlertSeverity.Critical,
    AlertSeverity.High,
    AlertSeverity.Medium,
    AlertSeverity.Low,
    AlertSeverity.None,
  ];

  for (const key in alerts) {
    if (alerts.hasOwnProperty(key)) {
      const item = alerts[key];
      const { alert_type_id, updated_at, description, severity, deleted, name } = item;

      let groupKey;
      if (viewBy === 'type') {
        groupKey = alert_type_id;
      } else {
        groupKey = severity;
      }

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = {
          max_updated_time: updated_at,
          items: [item],
          description: description,
          severity: severity,
          deleted: deleted,
          name: name
        };
      } else {
        if (updated_at > groupedData[groupKey].max_updated_time) {
          groupedData[groupKey].max_updated_time = updated_at;
        }

        groupedData[groupKey].items.push(item);
      }
    }
  }

  const groupedArray = Object.keys(groupedData).map((groupKey) => ({
    alert_type_id: viewBy === 'type' ? parseInt(groupKey, 10) : undefined,
    severity: groupedData[groupKey].severity,
    max_updated_time: groupedData[groupKey].max_updated_time,
    description:
      groupKey === CUSTOM_FLAG_ALERT_TYPE_ID ? 'Custom flag' : groupedData[groupKey].description,
    alerts: groupedData[groupKey].items,
    name: groupedData[groupKey].name
  }));

  const sortedData = orderBy(
    groupedArray,
    (item) => (viewBy === 'type' ? -item.max_updated_time : severityOrder.indexOf(item.severity)),
    viewBy === 'type' ? 'desc' : 'asc',
  );

  return sortedData;
};

export default getGroupedAlerts;

export const hasUnreadCriticalAlerts = (alerts) => {
  return alerts.some((item) => !item.read_at && item.severity === AlertSeverity.Critical);
};

export const markCriticalAlertsAsReadInFirebase = (sessionId: string, alerts: Alerts) => {
  if (!alerts) {
    return;
  }
  const database = getDatabase();
  Object.keys(alerts).forEach((alertKey: string) => {
    const alert = alerts[alertKey];
    if (alert.severity === AlertSeverity.Critical && !alert.read_at) {
      const alertRef = ref(database, `session_v2/${sessionId}/alerts/${alertKey}`);
      update(alertRef, { read_at: new Date().toISOString() });
    }
  });
};

export const getFilteredAlerts = (alerts: Alert[], filterBy: string[]) => {
  if (!alerts?.length) return [];
  if (filterBy.includes(FilterBy.Critical)) {
    return alerts.filter(
      (a: Alert) =>
        [
          AlertSeverity.Critical,
          AlertSeverity.High,
          AlertSeverity.Medium,
          AlertSeverity.Low,
        ].includes(a.severity) || defaultAlertTypes.includes(a.alert_type_id),
    );
  } else {
    return filterBy.length === 1 && alerts.length
      ? filterBy.includes(FilterBy.Debug)
        ? alerts.filter((a) => a.classification === FilterBy.Debug)
        : alerts.filter((a) => a.classification !== FilterBy.Debug)
      : alerts;
  }
};
