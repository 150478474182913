import { useSyncExternalStore } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import {
  setCurrentPlayingTimestamp,
  toggleTimelineActive,
  updateVideoControl,
} from 'common/slice/playbackVideoSlice';
import VolumeBar from './VolumeBar';
import PlaybackSpeedSetting from './PlaybackSpeedSetting';
import RewindIcon from 'assets/rewind-icon.svg';
import ForwardIcon from 'assets/forward-icon.svg';
import PreviousAlertIcon from 'assets/previous-alert.svg';
import NextAlertIcon from 'assets/next-alert.svg';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  EllipsisVerticalIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/outline';
import {
  getGroupedAlertsBySessionJoined,
  getIsFullscreen,
  getNextAlertByTimestamp,
  getPreviousAlertByTimestamp,
  subscribeToFullscreen,
} from 'helpers/playback';
import { StreamingProvider } from 'globals/enums';
import { PLAYBACK_SPEEDS_TWILIO, PLAYBACK_SPEEDS_LIVEKIT } from 'common/components/constant';

interface Props {
  alerts: any;
  handleFullscreenMode: () => void;
  streamingProvider: StreamingProvider
}

const VideoControls = ({ alerts, streamingProvider, handleFullscreenMode }: Props) => {
  const dispatch = useAppDispatch();
  const isFullscreen = useSyncExternalStore(subscribeToFullscreen, getIsFullscreen);
  const { timelineActive, currentPlayingTime } = useAppSelector((state) => state.playbackVideo);
  const availableSpeeds = streamingProvider === StreamingProvider.Twilio ? PLAYBACK_SPEEDS_TWILIO : PLAYBACK_SPEEDS_LIVEKIT;

  const getRequestedTimestampOnskip = (seconds: number) => {
    let requestedTimestamp = '';
    const newPlayingTimeStamp = dayjs(currentPlayingTime).add(seconds, 'second').toISOString();
    if (newPlayingTimeStamp <= alerts[0].timestamp) {
      requestedTimestamp = alerts[0].timestamp;
    } else if (newPlayingTimeStamp >= alerts[alerts.length - 1].timestamp) {
      requestedTimestamp = alerts[alerts.length - 1].timestamp;
    } else {
      requestedTimestamp = newPlayingTimeStamp;
    }
    return requestedTimestamp;
  };

  const handleVideoPlayPause = () => {
    dispatch(toggleTimelineActive());
    dispatch(updateVideoControl(false));
  };

  const handleAlertForwardBySeconds = () => {
    dispatch(
      setCurrentPlayingTimestamp({
        requestedTimestamp: getRequestedTimestampOnskip(10),
      }),
    );
  };

  const handleAlertRewindBySeconds = () => {
    dispatch(
      setCurrentPlayingTimestamp({
        requestedTimestamp: getRequestedTimestampOnskip(-10),
      }),
    );
  };

  const handleAlertSkipForward = () => {
    const nextAlertTimestamp = getNextAlertByTimestamp(alerts, currentPlayingTime);
    if (nextAlertTimestamp)
      dispatch(setCurrentPlayingTimestamp({ requestedTimestamp: nextAlertTimestamp }));
  };

  const handleAlertSkipPrevious = () => {
    const previousAlertTimestamp = getPreviousAlertByTimestamp(alerts, currentPlayingTime);
    if (previousAlertTimestamp)
      dispatch(setCurrentPlayingTimestamp({ requestedTimestamp: previousAlertTimestamp }));
  };

  return (
    <div className="flex items-center justify-between my-4 ml-2 mr-4">
      <div className="flex items-center justify-start flex-1">
        <button
          className="p-2 transition-all rounded-full active:bg-slate-100/25"
          data-testid="play-pause-btn"
          onClick={handleVideoPlayPause}
        >
          {timelineActive ? (
            <PauseCircleIcon data-testid="pauseIcon" className="w-8 h-8" />
          ) : (
            <PlayCircleIcon data-testid="playIcon" className="w-8 h-8" />
          )}
        </button>
        <button
          className="p-2 transition-all rounded-full active:bg-slate-100/25"
          data-testid="rewindTenSeconds-btn"
          onClick={handleAlertRewindBySeconds}
        >
          <img src={RewindIcon} alt="rewind icon" />
        </button>
        <button
          className="p-2 transition-all rounded-full active:bg-slate-100/25"
          data-testid="forwardTenSeconds-btn"
          onClick={handleAlertForwardBySeconds}
        >
          <img src={ForwardIcon} alt="forward icon" />
        </button>
        <button
          className="p-2 transition-all rounded-full active:bg-slate-100/25"
          data-testid="nextAlert-btn"
          onClick={handleAlertSkipPrevious}
        >
          <img src={NextAlertIcon} alt="next alert icon" />
        </button>
        <button
          className="p-2 transition-all rounded-full active:bg-slate-100/25"
          data-testid="previousAlert-btn"
          onClick={handleAlertSkipForward}
        >
          <img src={PreviousAlertIcon} alt="previous alert icon" />
        </button>
      </div>
      <div className="flex items-center justify-center flex-1 gap-4"></div>
      <div className="flex items-center justify-end flex-1 gap-4">
        <VolumeBar />
        <PlaybackSpeedSetting availableSpeeds={availableSpeeds} />
        <span data-testid="fullscreen-btn" onClick={handleFullscreenMode}>
          {isFullscreen ? (
            <ArrowsPointingInIcon data-testid="closeFullscreenIcon" className="w-6 h-6" />
          ) : (
            <ArrowsPointingOutIcon data-testid="openFullscreenIcon" className="w-6 h-6" />
          )}
        </span>
        <EllipsisVerticalIcon className="w-6 h-6" />
      </div>
    </div>
  );
};

export default VideoControls;
